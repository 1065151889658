@font-face {
  font-family: 'Open Sans';
  src: url('./Open_Sans/static/OpenSans-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Nunito';
  src: url('./Nunito/static/Nunito-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('./Roboto/Roboto-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Overpass';
  src: url('./Overpass/static/Overpass-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}