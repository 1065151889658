@import "theming.scss";

@mixin task($theme) {
  .task-table {
    tr {
      cursor: pointer;
    }
  }

  .tasks-badges {
    margin: 20px 0px 0px 0px;
  }

  .tasks-badges-wrapper {
    margin-top: 15px;
    display: flex;
    flex-wrap: wrap;
  }

  .tasks-badges-edit-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 15px;
  }

  .tasks-badge {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: theme-color($theme, "borders");
    margin: 10px 10px 10px 0px;
    padding: 5px 15px;
    box-shadow: 3px 2px 8px #83838346;
    transition: box-shadow 0.2s linear;

    &:hover {
      box-shadow: none;
      cursor: pointer;
    }
  }

  .icon-add:hover {
    cursor: pointer;
  }

  .tasks-table-badge {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: theme-color($theme, "taskTableBdge");
    margin: 0px 0px 10px 0px;
    padding: 1px 15px;
    border: 1px solid theme-color($theme, "uniksystemColor");
    color: theme-color($theme, "uniksystemColor");
  }

  .tasks-badge-edit {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-radius: theme-color($theme, "inputBorder");
    margin: 10px 10px 10px 0px;
    padding: 5px 15px;
  }

  .tasks-colorpicker-edit {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
  }

  .tasks-badge-content {
    display: flex;
    justify-content: center;
    align-content: center;
    height: 22px;
    font-size: 14px;
    width: fit-content;
  }

  .tasks-badge-input {
    display: flex;
    justify-content: center;
    align-content: center;
    height: 22px;
    font-size: 14px;
    background: transparent;
    border: none;
    width: fit-content;
  }

  .tasks-badge-input:focus-visible {
    outline: none;
  }

  .tasks-badge-header {
    font-size: 1rem;
    font-weight: 500;
    margin-bottom: 6px;
  }

  .tasks-color-wrapper {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 15px;
  }

  .tasks-color-item {
    height: 32px;
    width: 20%;
    cursor: pointer;
  }

  .tasks-color-item-inner {
    height: 90%;
    border-radius: 7px;
    margin: 0px 4px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .tasks-text-bold {
    font-weight: 500;
  }

  .tasks_id {
    cursor: pointer;
  }

  .tasks-edit-activity-folder {
    position: absolute;
    left: 100px;
    top: -250px;
    padding: 2rem;
    width: 30vw;
    border-radius: 23px;
    background-color: #fff;
    z-index: 2;
    box-shadow: 0px 4px 30px #9e9696;
    border: 1px solid rgba(154, 157, 160, 0.14);
  }

  .icon-left:hover {
    cursor: pointer;
  }

  .tasks-pnumber-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .color-control {
    width: 40px;
    height: 40px;
    border-radius: 7px;
    background-color: #fff;
    padding: 5px;
    border: 1px solid #ced4da;
    cursor: pointer;
  }

  .tasks-clear-selection {
    margin-left: 15px;
    color: theme-color($theme, "iconTableColor");
    cursor: pointer;
  }

  .tasks-clear-selection:hover {
    color: #0056b3;
    text-decoration: underline;
  }

  .tasks-edit-annotation {
    overflow-y: auto;
    max-height: 70vh;
    position: absolute;
    top: -250px;
    right: 30px;
    padding: 0 2rem 2rem 2rem;
    width: 40vw;
    border-radius: 23px;
    background-color: #fff;
    z-index: 10;
    box-shadow: 0px 4px 30px #9e9696;
    border: 1px solid rgba(154, 157, 160, 0.14);
  }

  /*Processes annotation icons*/
  .urgent-label {
    color: theme-color($theme, "buttonDanger");
  }

  .important-label {
    color: theme-color($theme, "buttonWarning");
  }

  .normal-label {
    color: theme-color($theme, "buttonSuccess");
  }

  .tasks-annotation-iconname {
    padding: 0px;
  }

  .tasks-annotation-wrapper {
    position: relative;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
  }

  .tasks-annotation-close {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    position: sticky;
    top: 5px;
    background-color: #fff;
    z-index: 10;
  }

  .tasks-annotation-inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    textarea {
      resize: none !important;
    }

    .react-calendar {
      min-width: 100%;
    }
  }

  .tasks-annotation-icons-wrapper {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: flex-start;
    width: 100%;
  }

  .tasks-popover {
    z-index: 5;
  }

  .column-icon {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .tasks-deadline-date {
    font-size: 14px;
    margin-top: 5px;
    color: theme-color($theme, "buttonDanger");
  }

  .annotation-preview-row {
    display: flex;
    width: 100%;
  }

  .annotation-preview-content {
    padding: 0 15px;
    margin-bottom: 6px;
    text-align: start;
    flex-grow: 1;
    word-break: break-word;
  }

  .annotation-preview-header {
    font-weight: 500;
    margin-bottom: 6px;
    text-align: start;
    min-width: 10vw;
  }

  .task-filter-category-toggle-wrapper {
    display: flex;
    align-items: center;
  }

  .task-filter-category-toggle-wrapper-active {
    // border-bottom: 2px solid theme-color($theme, "uniksystemColor") !important;
    color: theme-color($theme, "uniksystemColorActive");
  }

  .task-filter-category-toggle {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 6px;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .task-preview-table {
    margin: 0 -8px;

    .react-bootstrap-table {
      .table thead th {
        border: none;
        overflow-wrap: break-word;
        vertical-align: baseline;
        font-size: 1rem;
        font-weight: 500;
        padding: 0.75rem 0.5rem 0.5rem 0.5rem;
      }

      .table tbody td {
        padding: 0.75rem 0.5rem 0.5rem 0.5rem;
      }

      .table-bordered td,
      .table-bordered th {
        border: none;
      }

      table {
        border: none !important;
      }
    }
  }
}