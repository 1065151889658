@import "liftworldVariables.scss";

.liftworld-activity-content-wrapper{
    padding-left: 30px;
    display: 100% !important;
}

.liftworld-activity-date-inputs-wrapper{
    padding-left: 26px;
    padding-top: 10px;
    padding-bottom: 20px;
    align-items: center;
    display: flex;
}

.liftworld-button-wrapper{
    display: flex;
    width: 100%;
    justify-content: center;
}

.liftworld-date-wrapper{
    max-height: 30px;
}

.liftworld-button-option-wrapper{
    display: flex;
    width: 100%;
}

.liftworld-input-date-name-wrapper{
    align-items: center;
    display: flex;
    min-width: 500px;
}

.liftworld-label-wrapper{
    padding-left: 10px;
    padding-right: 5px;
    color: $liftworld-color-font-primary;
    align-items: center;
    display: flex;
    @include weight("bold");
    font-size: 16px;
}

