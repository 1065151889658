@import "liftworldVariables.scss";

.liftworld-workplans-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 10px;
}

.liftworld-workplan-row {
  display: flex;
  padding-left: 15px;
  padding-right: 15px;
  align-items: center;
  justify-content: space-between;
  padding-top: 10px;
  padding-bottom: 10px;
  &.normal {
    background: $liftworld-color-neutral-0;
  }
  &.grey {
    background: $liftworld-color-neutral-2;
  }
}

.liftworld-link-style {
  width: fit-content;
  color: $liftworld-link-color-font ;
  background-color: transparent;
  text-decoration: underline;
  @include weight("semi-bold");
  font-size: 16px;
  cursor: pointer;
  &:focus {
    outline: none;
  }
  &:hover {
    color: $liftworld-color-link-hover !important;
  }
}

.liftworld-link-style-disabled {
  width: fit-content;
  height: 45px;
  border: none;
  color: $liftworld-color-neutral-4;
  background-color: transparent;
  @include weight("semi-bold");
  font-size: 16px;
  cursor: none;
  &:focus {
    outline: none;
  }
}

.liftworld-row-item-wrapper{
    width: 16.66%;
    color: $liftworld-color-neutral-6;
    @include weight("normal");
    font-size: 14px;
    display: flex;
    &.id{
        text-decoration-line: underline;
        color: $liftworld-color-font-primary;
        cursor: pointer;
    }
}


.liftworld-row-header-wrapper{
    width: 16.66%;
    color: $liftworld-color-font-primary;
    @include weight("normal");
    font-size: 14px;
    display: flex;
}

.lift-modal-body-wrapper{
  width: 100%;
  display: flex;
  justify-content: center;
}

.lift-modal-body-wrapper-30{
  width: 100%;
  min-width: 30vh;
  display: flex;
  justify-content: center;
}