.fade {
  &.modal {
    &.show {
      padding-left: 0px !important;
    }
  }
}

.custom-modal {
  .modal-content {
    padding: 0 !important;

    .modal-header {
      background-color: #e9ecef;
      padding: 10px;
    }

    .modal-footer {
      background-color: #e9ecef;
    }

    .main-card-v2 {
      padding: 0 !important;

      .card-flat {
        padding: 0 !important;
      }
      .car-body {
        padding: 1.4rem 2rem !important;

      }
    }
  }
}

.fade.modal.show {
  background-color: rgba(0, 0, 0, 0.3);
}


.modal-tarefa-80w {
  min-width: 80vw;
}

.modal-tarefa-97w {
  min-width: 97vw;
}

.modal-tarefa-97w {
  min-width: 97vw;
}

.modal-tarefa-90w {
  min-width: 90vw;
}

.modal-tarefa-50w {
  min-width: 50vw;
}

.modal-tarefa-40w {
  min-width: 40vw;
}

.modal-tarefa-30w {
  min-width: 30vw;
}

.modal-tarefa-65w {
  min-width: 65vw;
}

.modal-tarefa-60w {
  min-width: 60vw;
}

.modal-tarefa-100w {
  min-width: 100vw;
  min-height: 100vh !important;
  margin-top: 0px !important;
  margin-bottom: 0px !important;
  //position: fixed;/*not working with Edge*/
}

.modal-tarefa-97w {
  min-width: 97vw;
  min-height: 100vh !important;
  margin-top: 0px !important;
  margin-bottom: 0px !important;
  //position: fixed;/*not working with Edge*/
}

.modal-tarefa-full-screen {
  width: 100vw;
  height: 100vh;
  margin: 0px !important;
}

.modal-tarefa-full-screen>.modal-content {
  height: auto;
  min-height: 100vh;
  min-width: 100vw;
}

.iframe-tarefa-80w {
  border: 0px;
  width: 100%;
  height: 100%;
  min-height: 80vh;
  border: none;
  display: flex;
  overflow-y: scroll;
  margin: 0px;
}

.iframe-tarefa-100w {
  border: 0px;
  width: 100%;
  height: 100%;
  min-height: 90vh;
  border: none;
  display: flex;
  overflow-y: scroll;
  margin: 0px;
}

.scrollBar-visibleFlowModal {
  overflow-y: auto;
  max-height: max-content;

  @media (max-width: 1080px) {
    max-height: 500px;
  }

  @media (max-width: 768px) {
    max-height: max-content;
  }
}

.scrollBar-visibleFlowModal::-webkit-scrollbar {
  width: 6px;
}

.scrollBar-visibleFlowModal::-webkit-scrollbar-track {
  background: #e2e2f1;
}

.scrollBar-visibleFlowModal::-webkit-scrollbar-thumb {
  background-color: #c1c1c1;
  border-radius: 6px;
}

.scrollBar-visible::-webkit-scrollbar {
  width: 6px;
}

.scrollBar-visible::-webkit-scrollbar-track {
  background: #e2e2f1;
}

.scrollBar-visible::-webkit-scrollbar-thumb {
  background-color: #c1c1c1;
  border-radius: 6px;
}

.scrollBar-visible-dropdown::-webkit-scrollbar {
  width: 5px;
}

.scrollBar-visible-dropdown::-webkit-scrollbar-track {
  background: #dadce0;
}

.scrollBar-visible-dropdown::-webkit-scrollbar-thumb {
  background-color: #c1c1c1;
  border-radius: 6px;
  height: 10px;
}