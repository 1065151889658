.reportContainer {
  background: #EAEAEA;
  font: Lighter 40px Segoe UI;    
  text-align: center;
  width: 100%;
  height: 368px;
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.reportContainer-fullscreen{
  background: #EAEAEA;
  font: Lighter 40px Segoe UI;    
  text-align: center;
  width: 100%;
  height: 95vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

iframe {
  border: none;
}

.modal-powerbi-100w {
  min-width: 100vw;
  min-height: 100vh !important;
  margin-top: 0px !important;
  margin-bottom: 0px !important;
  position: fixed;
}