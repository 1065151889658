$liftworld-color-primary-disabled: #0d6ff956;
$liftworld-color-input-yellow : #ffe699;
$liftworld-color-secondary: #F92DD3;
$liftworld-color-light-input-yellow :#fff2cc;
$liftworld-color-success: #048956;
$liftworld-color-success-light: #def2ea;
$liftworld-color-warning: #f78d3f;
$liftworld-color-warning-light: #f7e9df;
$liftworld-color-error: #f90d0d;
$liftworld-color-error-light: #ffdbdb;
$liftworld-color-neutral-6: #252529;
$liftworld-color-neutral-5: #545460;
$liftworld-color-neutral-4: #aeaaaa;
$liftworld-color-neutral-3: #b9b9b9;
$liftworld-color-neutral-2: #e7e6e6;
$liftworld-color-neutral-1: #f6f6f6;
$liftworld-color-neutral-0: #ffffff;
$liftworld-color-shadow-primary: #0d71f940;
$liftworld-color-shadow-secondary: #6d6d6d40;
$liftworld-color-shadow-disabled: #e8e8e840;
$liftworld-color-shadow-error: #f90d1540;
$liftworld-color-shadow-success: #04895640;
$liftworld-color-shadow-add: #f92dd340;
$liftworld-color-link-hover: #90befc;
$liftworld-color-background-light:#fafafa;
$liftworld-color-font-primary : #3a6cc2;
$liftworld-link-color-font : #0d71f9;


@mixin weight($value) {
  letter-spacing: 0px;
  opacity: 1;

  @if ($value == "light") {
    font-style: normal;
    font-variant: normal;
    font-weight: 300;
  }

  @if ($value == "regular") {
    font-style: normal;
    font-variant: normal;
    font-weight: normal;
  }

  @if ($value == "semi-bold") {
    font-style: normal;
    font-variant: normal;
    font-weight: 600;
  }

  @if ($value == "bold") {
    font-style: normal;
    font-variant: normal;
    font-weight: bold;
  }
}
