@import "variables.scss";

.easypay-backoffice-business-section-subheader-wrapper {
  display: flex;
  flex-direction: row;
  padding-bottom: 42px;
}

.easypay-backoffice-business-section-header {
  color: $color-primary;
  @include weight("bold");
  font-size: 33px;
}

.easypay-backoffice-business-section-subheader-text {
  color: $color-neutral-4;
}

.easypay-backoffice-business-section-payment-type-header {
  padding-top: 40px;
  @include weight("bold");
}

.easypay-backoffice-business-section-main-checkbox-wrapper {
  display: flex;
  flex-direction: row;
  width: 380px;
  justify-content: space-between;
}

.easypay-backoffice-business-section-main-wrapper {
  max-width: 1210px;
  width: 100%;
}

.easypay-business-text-title {
  font-size: 17px;
  padding-bottom: 5px;
  color: $color-neutral-6;
  @include weight("bold");
}

.easypay-business-text-light {
  font-size: 16px;
  color: $color-neutral-4;
  @include weight("regular");
  padding-bottom: 30px;
}
