@import "variables.scss";

.easypay-prova-de-vida-wrapper {
  padding-bottom: 20px;
}

.easypay-prova-de-vida-text {
  display: flex;
  justify-content: center;
}

.easypay-prova-de-vida-icon {
  display: flex;
  justify-content: center;
  padding-bottom: 10px;
}
