.custom-element-file-input {
  display: flex;
  flex-direction: row;
  align-items: center;

  input {
    display: none;
    visibility: collapse;
  }

  > *:not(input) {
    //margin: 0px 5px;
  }

  > span:first-of-type {
    border: 2px solid #4484f4;
    border-radius: 10px;
    background-color: transparent;
    color: #3a6cc2;
    cursor: pointer;
    padding: 2px 5px;
  }
}

.custom-element-file-input-fileList {
  display: contents;
  flex-direction: row;
  align-items: center;

  input {
    display: none;
    visibility: collapse;
  }

  > *:not(input) {
    //margin: 0px 5px;
  }

  > span:first-of-type {
    border: 2px solid #4484f4;
    border-radius: 10px;
    background-color: transparent;
    color: #3a6cc2;
    cursor: pointer;
    padding: 2px 5px;
  }

}

.custom-element-file-input-disabled {
  display: flex;
  flex-direction: row;
  align-items: center;

  input {
    display: none;
    visibility: collapse;
  }

  > *:not(input) {
    //margin: 0px 5px;
  }

  > span:first-of-type {
    border: 2px solid #4484f4;
    pointer-events: none;
    border-radius: 10px;
    background-color: #e9ecef;
    color: #3a6cc2;
    cursor: pointer;
    padding: 2px 5px;
  }
}
