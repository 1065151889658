@import "variables.scss";

.easypay-backoffice-document-preview-section {
  padding-top: 100px;
  width: 100%;
  height: 100%;
  background: $color-neutral-0;
}

.easypay-backoffice-document-preview-section-left-content {
  width: 50%;
}

.easypay-backoffice-document-preview-header {
  width: 100%;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 140px;
  padding-right: 140px;
  background: $color-background-light;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.easypay-backoffice-text-icon-wrapper {
  display: flex;
  flex-direction: row;
  padding-top: 8px;
  @include weight("bold");
  color: $color-success;
}

.easypay-backoffice-document-preview-section-content-wrapper {
  padding-left: 140px;
  padding-right: 140px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.easypay-backoffice-button-wrapper{
  padding-left: 20px;
}

.easypay-backoffice-document-preview-section-left-content {
  width: 30%;
}

.easypay-backoffice-header {
  @include weight("bold");
  color: $color-primary;
  font-size: 20px;
  padding-bottom: 20px;
}

.easypay-backoffice-document-preview-input-row{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.easypay-backoffice-small-input-wrapper{
    width: 48%;
}

.easypay-backoffice-document-preview-buttons-wrapper{
    display: flex;
    flex-direction: row;
    padding-top: 50px;
    justify-content: flex-end;
    align-items: center;
}