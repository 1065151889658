@import "variables.scss";

.easypay-vertical-nav-wrapper {
  display: flex;
  width: fit-content;
  justify-content: center;
}

.easypay-backoffice-vertical {
  position: fixed;
  margin-top: 343px;
  z-index: 5;
}

.easypay-vertical-nav {
  margin-left: 20px;
  &.vertical-nav {
    margin-left: 80px;
  }
}

.easypay-vertical-nav-content {
  width: 700px;
}

.easypay-vertical-nav-item {
  display: flex;
  justify-content: space-between;
  padding: 0px 20px;
  align-items: center;
  width: 355px;
  height: 60px;
  background-color: $color-neutral-0;
  border: none;
  color: $color-primary;
  @include weight("semi-bold");
  font-size: 22px;
  &.easypay-active,
  &:hover {
    background-color: $color-primary;
    color: $color-neutral-0;
  }
  &:focus {
    outline: none;
  }
  .easypay-nav-badge {
    height: 24px;
    width: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: $color-secondary 0% 0% no-repeat padding-box;
    border-radius: 30px;
    color: $color-neutral-0;
    @include weight("semi-bold");
    font-size: 14px;
  }
}

.easypay-vertical-nav-content {
  margin-left: 20px;
}
