@import "theming.scss";

@mixin modal($theme) {
  & .modal-content {
    padding: 20px 20px 20px 20px;
    border-color: theme-color($theme, "backgroundColor");
    border-radius: theme-color($theme, "borders");
    color: theme-color($theme, "textColor");
    box-shadow: 0 9px 46px 8px rgba(0, 0, 0, 0.14),
      0 11px 15px - 7px rgba(0, 0, 0, 0.14), 0 24px 38px 3px rgba(0, 0, 0, 0.14);

    .modal-header {
      border: none;
      border-bottom: theme-color($theme, "border-bottom");
      border-radius: theme-color($theme, "border-header");
      color: theme-color($theme, "textColor");

      @if $theme ==dark {
        border: 1px solid black;
      }
    }

    & .modal-body {
      background-color: theme-color($theme, "backgroundColor");
      border-radius: 20px;

      .modal-submit-button {
        background-color: theme-color($theme,
            "modalSubmitButtonButtonBackgroundColor"
          );
        border-radius: 23px;
      }
    }

    & .modal-footer {
      border: none;
      border-bottom: theme-color($theme, "border-top");
      border-radius: theme-color($theme, "border-footer");
      padding: theme-color($theme, "border-padding") !important;
      color: rgb(203, 203, 203);

      @if $theme ==dark {
        border: 1px solid black;
      }

      .modal-submit-button {
        background-color: theme-color($theme,
            "modalSubmitButtonButtonBackgroundColor"
          );
        border-radius: 23px;
      }
    }

    & * .btn {
      //border: 0px;
      border-radius: theme-color($theme, "inputBorder") !important;
      padding: 6px 18px;
      min-height: 35px!important;
    }

    .card-button-red {
      background-color: #dc3545;
      border: 0px;
      border-radius: theme-color($theme, "inputBorder") !important;
      padding: 6px 18px;
      min-height: 35px;
      height: 35px !important;
    }

    .card-button-red:hover {
      background-color: brown !important;
      border: 0px;
    }

    .card-button-outlined {
      background-color: transparent;
      border: 1px solid theme-color($theme, "cardButtonBackgroundColor");
      border-radius: theme-color($theme, "inputBorder") !important;
      padding: 6px 18px;
      min-height: 35px;
      color: theme-color($theme, "cardButtonBackgroundColor");
    }

    .card-button-outlined:hover {
      background-color: #d7d7d7 !important;
    }

    .card-button {
      width: auto;
      background-color: theme-color($theme, "cardButtonBackgroundColor");
      border: 0px;
      border-radius: theme-color($theme, "inputBorder") !important;
      padding: 6px 18px;
      min-height: 35px;
      height: 35px !important;
    }

    .card-button-expenses {
      margin-bottom: 1rem;
      width: 100%;
      background-color: #4c4b4b;
      padding: 6px 18px;
      min-height: 35px;
      border: 0px;
      border-radius: theme-color($theme, "inputBorder") !important;
      height: 35px !important;
    }

    .card-button-expenses:focus {
      background-color: #4c4b4b !important;
    }

    .card-button:hover {
      background-color: theme-color($theme,
          "cardButtonBackgroundColorHover"
        ) !important;
      border: 0px;
    }

    .card-button-10 {
      background-color: theme-color($theme, "cardButtonBackgroundColor");
      border: 0px;
      border-radius: 10px !important;
      padding: 6px 18px;
      min-height: 35px;
      width: auto;
      height: 35px !important;
    }

    .card-button-10:hover {
      background-color: theme-color($theme,
          "cardButtonBackgroundColorHover"
        ) !important;
      border: 0px;
    }

    .card-button-10NoHeight {
      background-color: theme-color($theme, "cardButtonBackgroundColor");
      border: 0px;
      border-radius: 10px !important;
      padding: 6px 18px;
      width: auto;
    }

    .card-button-10NoHeight:hover {
      background-color: theme-color($theme,
          "cardButtonBackgroundColorHover"
        ) !important;
      border: 0px;
    }

    .card-outlined-button-10 {
      border-radius: 15px !important;
      padding: 6px 18px;
      min-height: 42px;
      background-color: theme-color($theme, "backgroundColor");
      border: 2px solid theme-color($theme, "cardButtonBackgroundColor");
      color: theme-color($theme, "cardButtonBackgroundColor");
    }

    .card-outlined-button-10:hover {
      background-color: theme-color($theme, "cardButtonBackgroundColor");
      color: #fff;
    }

    .btn-danger {
      background-color: theme-color($theme, "buttonDanger");
      border-color: theme-color($theme, "buttonDanger");
    }

    .btn-danger:hover {
      background-color: theme-color($theme, "buttonDangerHover");
      border-color: theme-color($theme, "buttonDangerHover");
    }

    .modal-error-message {
      color: theme-color($theme, "buttonDanger");
      white-space: pre-line;
      word-break: break-all;
    }

    .alert-danger {
      color: theme-color($theme, "alertColor");
      background-color: theme-color($theme, "alertBackgroundColor");
      border-color: theme-color($theme, "alertBackgroundColor");
    }

    .alert-info {
      color: theme-color($theme, "alertInfoColor");
      background-color: theme-color($theme, "alertInfoBackgroundColor");
      border-color: theme-color($theme, "alertInfoBorder");
    }

    .form-control {
      color: theme-color($theme, "textColor");
    }

    @include table($theme);
    @include form($theme);
  }

  .form-control {
    border-radius: theme-color($theme, "inputBorder") !important;
    padding: 0.25rem 1rem !important;

    &.form-suffix {
      border-radius: 23px 0px 0px 23px !important;
    }

    &.form-table {
      border-radius: 0.25rem !important;
    }

    &.react-datetime-picker--disabled {
      background-color: #e9ecef !important;
      height: 35px !important;
    }
  }

  .react-calendar {
    box-shadow: 0px 3px 10px #6d6d6d40;
    border-radius: theme-color($theme, "inputBorder") !important;
    border: none !important;
  }

  .badge-danger {
    background-color: theme-color($theme, "buttonDanger");
    border-color: theme-color($theme, "buttonDanger");
    color: theme-color($theme, "badgeSuccessColor");
    border-radius: theme-color($theme, "inputBorder") !important;
  }

  .badge-success {
    background-color: theme-color($theme, "buttonSuccess");
    border-color: theme-color($theme, "buttonSuccess");
    color: theme-color($theme, "badgeSuccessColor");
    border-radius: theme-color($theme, "inputBorder") !important;
  }

  .text-primary-color {
    color: theme-color($theme, "uniksystemColor");
  }
}