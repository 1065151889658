@import "variables.scss";

.easypay-pepcheckbox-wrapper{
    padding: 10px;
    background-color: $color-neutral-0;
    border-radius: 10px;
    width: 505px;
}

.easypay-pepcheckbox-link-wrapper{
    padding-top: 7px;
}
.easypay-pepcheckbox-top{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}