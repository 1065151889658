/*ICON FONT-----------------------------------------------------------------------------------------------------------------------------------------------------*/
@font-face {
  font-family: 'icomoon';
  src:  url(../img/icons/icomoon.eot);
  src:  url(../img/icons/icomoon.eot) format('embedded-opentype'),
    url(../img/icons/icomoon.ttf) format('truetype'),
    url(../img/icons/icomoon.woff) format('woff'),
    url(../img/icons/icomoon.svg) format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-restore:before {
  content: "\e93b";
}
.icon-minimize:before {
  content: "\e93c";
}
.icon-maximize:before {
  content: "\e93d";
}
.icon-download:before {
  content: "\e93a";
}
.icon-detail:before {
  content: "\e938";
}
.icon-refresh:before {
  content: "\e939";
}
.icon-remove:before {
  content: "\e937";
}
.icon-submit:before {
  content: "\e936";
}
.icon-onboarding:before {
  content: "\e933";
}
.icon-invoice:before {
  content: "\e934";
}
.icon-data-capture:before {
  content: "\e935";
}
.icon-global-solver:before {
  content: "\e92e";
}
.icon-gai-buy:before {
  content: "\e92c";
}
.icon-compliance-buy:before {
  content: "\e92d";
}
.icon-gdpr-buy:before {
  content: "\e92f";
}
.icon-finance-buy:before {
  content: "\e930";
}
.icon-hr-buy:before {
  content: "\e931";
}
.icon-bpm-buy:before {
  content: "\e932";
}
.icon-gai:before {
  content: "\e92b";
}
.icon-edit:before {
  content: "\e92a";
}
.icon-left:before {
  content: "\e925";
}
.icon-right:before {
  content: "\e926";
}
.icon-down:before {
  content: "\e927";
}
.icon-up:before {
  content: "\e928";
}
.icon-ellipsis:before {
  content: "\e929";
}
.icon-globe:before {
  content: "\e924";
}
.icon-unik3:before {
  content: "\e921";
}
.icon-unik2:before {
  content: "\e922";
}
.icon-unik:before {
  content: "\e923";
}
.icon-compliance:before {
  content: "\e91f";
}
.icon-pin-active:before {
  content: "\e920";
}
.icon-pin:before {
  content: "\e920";
}
.icon-user-sheet:before {
  content: "\e91d";
}
.icon-cart:before {
  content: "\e91e";
}
.icon-edit-user:before {
  content: "\e91b";
}
.icon-trash:before {
  content: "\e91c";
}
.icon-mail:before {
  content: "\e919";
}
.icon-phone:before {
  content: "\e91a";
}
.icon-attach:before {
  content: "\e913";
}
.icon-check:before {
  content: "\e914";
}
.icon-tarefas2:before {
  content: "\e915";
}
.icon-rounded-square:before {
  content: "\e916";
}
.icon-circle:before {
  content: "\e917";
}
.icon-external-link:before {
  content: "\e918";
}
.icon-generic:before {
  content: "\e911";
}
.icon-search:before {
  content: "\e912";
}
.icon-history:before {
  content: "\e900";
}
.icon-processos:before {
  content: "\e901";
}
.icon-reports:before {
  content: "\e902";
}
.icon-admin:before {
  content: "\e903";
}
.icon-tarefas:before {
  content: "\e904";
}
.icon-favorites:before {
  content: "\e905";
}
.icon-gdpr:before {
  content: "\e906";
}
.icon-finance:before {
  content: "\e907";
}
.icon-hr:before {
  content: "\e908";
}
.icon-bpm:before {
  content: "\e909";
}
.icon-add:before {
  content: "\e90a";
}
.icon-bell:before {
  content: "\e90b";
}
.icon-dashboard:before {
  content: "\e90c";
}
.icon-gear:before {
  content: "\e90d";
}
.icon-logout:before {
  content: "\e90e";
}
.icon-moon:before {
  content: "\e90f";
}
.icon-trophy:before {
  content: "\e910";
}

.iconExpandMoreIcon{
  font-size: 1.5rem!important;
}