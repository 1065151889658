@import "variables.scss";

.easypay-document-navigation {
  display: flex;
  flex-direction: column;
  position: relative;
}

.easypay-document-navigation-header {
  display: flex;
  flex-direction: row;
  align-content: center;
}

.easypay-left-img-container {
  width: 32px;
  display: flex;
  padding-top: 8px;
  margin-top: 6.5px;
  height: 32px;
  background: $color-primary;
  justify-content: center;
  align-content: center;
  border-radius: 20px;
  box-shadow: 0px 10px 18px rgba(13, 113, 249, 0.25);
  &.disabled {
    box-shadow: none;
    background: $color-neutral-2;
  }
}

.easypay-left-img-container:hover {
  box-shadow: none;
}

.easypay-left-doc-arrow {
  height: fit-content;
  width: fit-content;
  margin-right: 3px;
  margin-top: 1px;
}

.easypay-right-img-container {
  width: 32px;
  margin-left: 20px;
  margin-top: 6.5px;
  padding-top: 8px;
  display: flex;
  height: 32px;
  background: $color-primary;
  justify-content: center;
  align-content: center;
  border-radius: 20px;
  box-shadow: 0px 10px 18px rgba(13, 113, 249, 0.25);
  &.disabled {
    box-shadow: none;
    background: $color-neutral-2;
  }
}

.easypay-right-doc-arrow {
  height: fit-content;
  width: fit-content;
  transform: rotate(180deg);
  margin-left: 3px;
  margin-top: 1px;
}

.easypay-right-img-container:hover {
  box-shadow: none;
}
.easypay-document-dropdown-btn {
  display: flex;
  margin-left: 20px;
  position: relative;
  flex-direction: row;
  justify-content: space-between;
  width: 320px;
  height: 45px;
  padding-right: 20px;
  background: $color-neutral-0 0% 0% no-repeat padding-box;
  border: 2px solid $color-neutral-5;
  border-radius: 23px;
  opacity: 1;
  color: $color-primary;
  padding-left: 20px;
  padding-top: 8px;
  @include weight("semi-bold");
  font-size: 16px;
  color: #000103;
  opacity: 1;
  &.disabled {
    background: $color-neutral-2 0% 0% no-repeat padding-box;
    border: 2px solid $color-neutral-3;
    color: $color-neutral-4;
    box-shadow: none;
  }
  &.active {
    border: 2px solid $color-primary;
  }

  &.not-selected {
    border: 2px solid $color-neutral-3;
  }
}

.easypay-document-btn-text {
  display: flex;
  flex-direction: column;
}

.easypay-document-dropdown-content::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.easypay-document-dropdown-content {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.easypay-document-dropdown-content {
  position: absolute;
  top: 50px;
  width: 320px;
  margin-left: 51.5px;
  min-height: fit-content;
  max-height: max($numbers: 165px);
  background: #fff;
  box-shadow: 0px 0px 5px 5px rgba(0, 0, 0, 0.06);
  overflow-y: scroll;
  border-radius: 4px;
  @include weight("semi-bold");
  font-size: 16px;
  display: block;
}

.easypay-document-dropdown-item {
  display: flex;
  width: 320px;
  flex-direction: column;
  height: 50px;
  justify-content: space-evenly;
  border-bottom: 0.2px solid $color-neutral-2;
  background: #ffffff;
  &.last {
    border: none;
  }
}

.easypay-document-dropdown-content .easypay-document-dropdown-item {
  padding: 15px;
  width: 320px;
  cursor: pointer;
  transition: all 0.2s;
}

.easypay-document-dropdown-content .easypay-document-dropdown-item:hover {
  background: #f4f4f4;
  color: $color-primary;
}

.easypay-dropdown-img {
  margin-top: 10px;
  &.rotate {
    transform: rotate(180deg);
  }
}

.easypay-document-dropdown-item-name {
  @include weight("semi-bold");
  font-size: 11px;
}

.easypay-document-dropdown-item-holder-name {
  font-size: 9.5px;
  font-weight: 390;
  font-variant: normal;
  color: $color-neutral-4;
  @include weight("regular");
}

.easypay-main-document-dropdown-item-name {
  @include weight("semi-bold");
  font-size: 13px;
}

.easypay-main-document-dropdown-item-holder-name {
  font-size: 10.5px;
  color: $color-neutral-4;
  @include weight("regular");
}

.easypay-document-dropdown-item
  .easypay-document-dropdown-item-holder-name:hover {
  color: $color-primary;
}
