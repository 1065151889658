.fade {
    &.modal {
        &.show {
            padding-left: 0px !important;
            background-color: rgba(0,0,0,0.3);

        } 
    }
}

.modal-datacapture-80w {
    min-width: 80vw;
}
  
.modal-datacapture-100w {
    min-width: 100vw;
    min-height: 100vh !important;
    margin-top: 0px !important;
    margin-bottom: 0px !important;
    position: fixed;
}
  
.iframe-datacapture-80w {
    border: 0px;
    width: 100%;
    height: 100%;
    min-height: 80vh;
    border: none;
    display: flex;
    overflow-y: scroll;
    margin: 0px;
}
  
.iframe-datacapture-100w {
    border: 0px;
    width: 100%;
    height: 100%;
    min-height: 90vh;
    border: none;
    display: flex;
    overflow-y: scroll;
    margin: 0px;
}

.adatacapture-row:hover{
    font-weight: 700;
}
  
