@media only screen and (max-width: 600px) {
  .unik-logo {
    width: 15rem;
    -webkit-filter: drop-shadow(5px 5px 5px rgba(90, 90, 90, 0.3));
    filter: drop-shadow(5px 5px 5px rgba(90, 90, 90, 0.3));
  }

  .card-login {
    width: 22rem;
  }
}

@media only screen and (min-width: 610px) {
  .unik-logo {
    width: 20rem;
    -webkit-filter: drop-shadow(5px 5px 5px rgba(90, 90, 90, 0.3));
    filter: drop-shadow(5px 5px 5px rgba(90, 90, 90, 0.3));
  }

  .card-login {
    width: 30rem;
  }
}

.unik-logo-500 {
  width: 5rem;
  opacity: 0.2;
}

.label-welcome {
  font-size: 2rem;
  line-height: 1;
  font-weight: 300;
}

.label-credentials {
  font-size: 1rem;
  font-weight: 500;
}

.overlay {
  position: fixed;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: radial-gradient(#ffffff, #d6d6d6);
  align-items: center;
  justify-content: center;
  overflow: scroll;
}

.header-login {
  font-weight: bold;
  color: white;
  text-align: center;
  border: 0px;
  border-radius: 24px 24px 0px 0px !important;
  background-color: #4484f4 !important;
  padding: 0.5rem 0 0.2rem 0 !important;
}

.card-login {
  padding: 2.5rem !important;
  border: 0px;
  border-radius: 20px !important;
  -webkit-box-shadow: 0px 7px 30px -2px rgba(179, 179, 179, 1);
  -moz-box-shadow: 0px 7px 30px -2px rgba(179, 179, 179, 1);
  box-shadow: 0px 7px 30px -2px rgba(179, 179, 179, 1);
}

.label {
  font-weight: bold;
  color: #5b5b5b;
}

.warning {
  color: #dc3545;
}

.checkbox {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
}

.footer {
  display: flex;
  justify-content: space-between;
  border-radius: 0px 0px 24px 24px !important;
}

.create-acc-label {
  color: #4484f4;
}
.buzzStyle {
  background-color: #F48120!important;
  border-radius: 23px !important;
  color: white !important;
  height: 44px;
}

.buzzStyle:hover {
  background-color: #dd6c0f!important; 
}
.edenredStyle {
  background-color: #FF220F!important; 
  border-radius: 23px !important;
  color: white !important;
  height: 44px;
}

.edenredStyle:hover {
  background-color: #c51e0e!important; 
}
.sparkStyle {
  background-color: #E31A12!important; 
  border-radius: 23px !important;
  color: white !important;
  height: 44px;
}
.sparkStyle:hover {
  background-color: #ac1a0d!important; 
}

.liftStyle {
  background-color: #000000!important; 
  border-radius: 23px !important;
  color: white !important;
  height: 44px;
}

.liftStyle:hover {
  background-color: #363636!important; 
}

.futureStyle {
  background-color: #CE202E!important; 
  border-radius: 23px !important;
  color: white !important;
  height: 44px;
}

.futureStyle:hover {
  background-color: #a3202b!important; 
}

.mapeiStyle {
  background-color: #4285F4!important; 
  border-radius: 23px !important;
  color: white !important;
  height: 44px;
}

.mapeiStyle:hover {
  background-color: #3a6cbd!important; 
}


.login-button {
  border-radius: 23px !important;
  background-color: #313088;
  color: white !important;
  height: 44px;
}

.login-button:hover {
  background-color: #3a6cc2 !important;
}

// LOGIN NOVOBANCO
.nblpStyle {
  border-radius: 23px !important;
  background-color: var(--defaultColorButtonHover) !important;
  color: white !important;
  height: 44px;
  -webkit-transition: background-color 150ms ease-out !important;
  -moz-transition: background-color 150ms ease-out !important;
  -o-transition: background-color 150ms ease-out !important;
  transition: background-color 150ms ease-out !important;
}

.iselStyle {
  border-radius: 23px !important;
  background-color: #A32516!important;
  color: white !important;
  height: 44px;
  
  -webkit-transition: background-color 150ms ease-out !important;
  -moz-transition: background-color 150ms ease-out !important;
  -o-transition: background-color 150ms ease-out !important;
  transition: background-color 150ms ease-out !important;
}


.nblpStyle:hover {
  background-color: var(--defaultWarningColor)!important;
}

.iselStyle:hover {
  background-color: #c42d1c !important;
}

/*ADFS button start*/
.x-icon {
  margin-right: 12px;
}

.btn-adfs {
  font-family: Segoe UI Regular 15px;
  font-weight: 600;
  color: #5e5e5e;
  background: #ffffff;
  border: 1px solid #8c8c8c;
  border-radius: 23px;
  padding-left: 24px;
  padding-right: 24px;
  height: 50px;
  width: 200px;
  margin-top: 15px;
}
/*ADFS button end*/

.btn-windows {
  font-family: Segoe UI Regular 15px;
  font-weight: 600;
  color: #5e5e5e;
  background: #ffffff;
  border: 1px solid #8c8c8c;
  border-radius: 23px;
  padding-left: 24px;
  padding-right: 24px;
  height: 50px;
  width: 200px;
  margin-top: 15px;
}

.btn-windows:hover {
  background: #5e5e5e;
  color: #ffffff;
}

.btn-keycloak:hover {
  background: #5e5e5e;
  color: #ffffff;
}

.btn-keycloak {
  font-family: Segoe UI Regular 15px;
  font-weight: 600;
  color: #5e5e5e;
  background: #ffffff;
  border: 1px solid #8c8c8c;
  border-radius: 23px;
  padding-left: 24px;
  padding-right: 24px;
  height: 50px;
  width: 200px;
  margin-top: 15px;
}

.logo-windows {
  height: 30px;
  width: 30px;
}

.logo-keycloak {
  height: 30px;
  width: 30px;
}

// FIM LOGIN NOVOBANCO

.buttons {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

//Third-party sign in
.span-or {
  display: block;
  position: absolute;
  left: 50%;
  top: -2px;
  margin-left: -50px;
  background-color: #fff;
  width: 100px;
  text-align: center;
}

.login-or {
  position: relative;
  color: #aaa;
  margin-top: 10px;
  margin-bottom: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.form-control {
  border-radius: 23px !important;
  padding: 0.25rem 1rem !important;
  &.form-suffix {
    border-radius: 23px 0px 0px 23px !important;
  }

  &.form-table {
    border-radius: 0.25rem !important;
  }

  &.react-datetime-picker--disabled {
    background-color: #e9ecef !important;
    height: 35px !important;
  }
  &:focus{
    box-shadow: 0 0 0 0.2rem #ced4da !important;
    border-color: #ced4da!important
  }
}

.react-calendar {
  box-shadow: 0px 3px 10px #6d6d6d40;
  border-radius: 23px;
  border: none !important;
}

input.form-control {
  height: 35px!important;
}
input.form-control.ultracompactForm{
height: 35px!important;
}
.react-datetime-picker {
  &.form-control {
    height: 35px !important;
    padding: 0.375rem 0.75rem !important;
  }
}

select.form-control {
  height: 44px !important;
}

textarea.form-control {
  padding: 0.5rem 1rem !important;
}

.form-group {
  h1 {
    padding-left: 15px;
  }

  h4 {
    //font-size: 1.2rem;
  }
}

.card-login {
  .card-body {
    padding: 0 !important;
  }
}

.hr-or {
  height: 1px;
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}

#signInSection {
  padding-top: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

/*Google button*/
#third-auth-button {
  button {
    width: 200px;
    border: 2px solid #4285f4 !important;
    margin-bottom: 15px;
    border-radius: 23px !important;

    div {
      margin-right: 0px !important;
      background-color: #fff !important;
      padding: 10px 15px 10px 15px !important;
      border-radius: 23px !important;
    }

    span {
      padding: 9px 0px 8px 0px !important;
      border-radius: 0px 23px 23px 0px !important;
      font-family: "Roboto", sans-serif;
      width: inherit;
      color: #fff;
      font-size: 16px;
      font-weight: 600 !important;
      background-color: #4285f4 !important;
    }
  }
}

/*Microsoft button start*/
.x-icon {
  margin-right: 12px;
}

.btn-microsoft {
  font-family: Segoe UI Regular 15px;
  font-weight: 600;
  color: #5e5e5e;
  background: #ffffff;
  border: 1px solid #8c8c8c;
  border-radius: 23px;
  padding-left: 24px;
  padding-right: 24px;
  height: 44px;
  width: 200px;
}
/*Microsoft button end*/
