@import "variables.scss";

.easypay-main-password-input-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
}

.easypay-input-password-label {
  padding-left: 22px;
  padding-bottom: 2px;
  @include weight("semi-bold");
  font-size: 13px;
}
.easypay-password-input-wrapper {
  width: 100%;
  display: flex;
  position: relative;
  flex-direction: row;
  justify-content: space-between;
  height: 45px;
  padding-right: 20px;
  background: $color-neutral-0 0% 0% no-repeat padding-box;
  border: 2px solid $color-neutral-5;
  border-radius: 23px;
  color: $color-primary;
  padding-left: 20px;
  padding-top: 8px;
  @include weight("semi-bold");
  font-size: 16px;
  color: $color-neutral-6;
  margin-bottom: 10px;
  &.disabled {
    background: $color-neutral-2 0% 0% no-repeat padding-box;
    border: 2px solid $color-neutral-3;
    color: $color-neutral-4;
    box-shadow: none;
  }
  &.active {
    border: 2px solid $color-primary;
  }

  &.not-selected {
    border: 2px solid $color-neutral-3;
  }
}

.easypay-password-input:-webkit-autofill,
.easypay-password-input:-webkit-autofill:hover,
.easypay-password-input:-webkit-autofill:focus,
.easypay-password-input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px $color-neutral-0 inset !important;
}

.easypay-password-input {
  width: 320px;
  outline: none;
  border: none;
  background: none;
  margin-bottom: 7px;
  &.password {
    -webkit-text-security: disc;
  }
}

.easypay-password-img {
  width: 20px;
  height: 17px;
  margin-top: 4.5px;
}
