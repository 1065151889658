
.card-sub{
  font-size: 0.8em;
  color:  #007bff !important;
}
.card-sub:hover{
  color: #007bff !important;
  text-decoration: underline;
}

.task-description{
  font-size: 0.9em;
  line-height: 1.5em;
}

.tasks-row-wrapper{
  display: flex; 
  flex-direction: column; 
  margin: 0;
  height: 100%;
}

.tasks-row{
  width: 100%; 
  display: flex; 
  flex-direction: row; 
  height: 50%;
}

