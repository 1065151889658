@import "variables.scss";

.easypay-backoffice-registry-section-column-content {
  max-width: 580px;
  width: 48%;
}

.easypay-backoffice-sections-main-wrapper{
  width: 100%;
}

.easypay-backoffice-registry-section-main-wrapper{
  width: 100%;
  height: 100vh;
}
.easypay-backoffcie-registry-section-inputs-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.easypay-backoffcie-registry-section-bottom-inputs-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.easypay-backoffice-registry-section-header {
  color: $color-primary;
  @include weight("bold");
  font-size: 33px;
  padding-bottom: 30px;
}

.easypay-backoffice-registry-section-small-input-wrapper {
  max-width: 280px;
  width: 48%;
}


.easypay-backoffice-registry-section-content-wrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  max-height: 330px;
}

.easypay-backoffice-registry-radio-button-header{
    padding-bottom: 10px;
    @include weight("bold");
    font-size: 18px;
}

.easypay-backoffice-registry-radio-button-wrapper{
    margin-bottom: 40px;
    height: fit-content;
}

.easypay-backoffice-registry-section-name-input-wrapper{
    padding-top: 30px;
}