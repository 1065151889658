@media only screen and (max-width: 800px) {
  .card-login {
    &.card {
      &.drpa {
        .card-body {
          .drpa-login-1 {
            max-width: 200px !important;
          }
          .drpa-login-2 {
            padding-left: 20px;
            max-width: 200px !important;
            font-size: 12px;
          }
          .pesca-logo {
            max-width: 200px !important;
            padding-bottom: 20px !important;
          }
          .isel-logo {
            max-width: 150px !important;
            padding-bottom: 20px !important;
            padding-left: 15px !important;
            padding-right: 15px !important;
          }
          .lift-logo {
            max-width: 100px !important;
            padding-bottom: 20px !important;
            padding-left: 15px !important;
            padding-right: 15px !important;
          }
          .edenred-logo {
            max-width: 120px !important;
            padding-bottom: 20px !important;
            padding-left: 20px !important;
            padding-right: 20px !important;
          }
          .unikpeople-logo {
            max-width: 160px !important;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 550px) {
  .card-login {
    &.card {
      &.drpa {
        width: 90% !important;
        .card-body {
          align-items: center !important;
          flex-direction: column !important;

          .drpa-login-1 {
            min-width: 100% !important;
            padding-bottom: 20px;
            .pesca-logo-wrapper {
              display: flex;
              justify-content: center;
            }
          }
          .drpa-login-2 {
            max-width: unset !important;
            padding-left: 0 !important;
            font-size: 12px;
          }
          .pesca-logo {
            max-width: 270px !important;
          }
          .isel-logo {
            max-width: 200px !important;
          }
          .lift-logo {
            max-width: 110px !important;
          }
          .unikpeople-logo {
            max-width: 200px !important;
          }
          .edenred-logo {
            max-width: 160px !important;
            padding-left: 20px !important;
            padding-right: 20px !important;
            padding-bottom: 20px !important;
          }
        }
      }
    }
  }
}

.card-login {
  &.card {
    &.drpa {
      width: fit-content;
      margin-bottom: 80px;
      .card-body {
        display: flex;

        .drpa-login-1 {
          max-width: 324px;
        }
        .drpa-login-2 {
          text-align: justify;
          text-justify: inter-word;
          padding-left: 40px;
          max-width: 324px;
          a {
            text-decoration: underline;
          }
        }

        .pesca-logo {
          max-width: 324px;
          padding-bottom: 20px;
        }
        .isel-logo {
          max-width: 200px !important;
          padding-bottom: 20px !important;
        }
        .lift-logo {
          max-width: 120px !important;
          padding-bottom: 20px !important;
        }
        .unikpeople-logo {
          max-width: 200px !important;
        }
        .edenred-logo {
          max-width: 230px;
          padding-left: 40px;
          padding-bottom: 40px;
          padding-right: 40px;
        }
      }
    }
  }
}

.drpa-footer-wrapper {
  display: flex;
  position: fixed;
  bottom: 0;
  background-color: #333333;
  width: 100%;
  align-items: center;
  justify-content: center;
  padding: 20px;
  .drpa-footer {
    display: flex;
    padding-right: 20px;

    .logo-img-wrapper {
      display: flex;
      align-items: center;
      .logo-img {
        height: 20px;
        margin-right: 5px;
      }
    }
    .logo-text {
      max-width: 80px;
      color: white;
      font-size: 12px;
      line-height: 0.9;
      text-transform: uppercase;
    }
  }
  .subtext {
    font-size: 12px;
    color: white;
    line-height: 0.9;
    p {
      margin-bottom: 0 !important;
    }

    .subtext-link {
      text-decoration: underline;
      color: white;
    }
  }
}
