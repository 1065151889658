/* .loading-page {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
  
  .loading-page img {
    animation: fade 0.8s ease-in-out infinite;
  }
  
  @keyframes fade {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  } */
  .loading-page {
    height: 100vh;
    place-content: center;
  }