@import "variables.scss";

.easypay-line-break-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px 0px;
}

.easypay-line-break-line {
  display: block;
  height: 1px;
  width: 100%;
  border: 0;
  border-top: 1px solid $color-neutral-3;
  margin: 1em 0;
}
