.liftworld-element-input-date-wrapper {
    display:flex;
    align-items: center;
}

.liftworld-element-input-date-label{
    color: black;
    align-items: center;
    display: flex;
    font-style: normal;
    font-variant: normal;
    font-weight: bold;
    font-size: 17px;
}

.liftworld-element-input-field-date {
    border: none;
    text-align: center;
    &.left {
        margin-left: 0px;
    }

    &:focus {
        outline: none;
    }
}

.liftworld-element-date-separator-wrap {
    height: 20px;
    width: 1px;
    background-color: gray;
}

.liftworld-element-input-wrapper-date {
  justify-content: center;
  overflow: hidden;
  align-items: center;
  flex-wrap: wrap;
  padding-bottom: 10px;
  margin: 0;
  height: 30px;
  width: 100%;
  background-color: white;
  border: 1px solid #ced4da;
  z-index: 5;
  text-align: center;
  transition: all 0.2s linear;
  &.start {
    border-bottom-left-radius: 12px;
  }

  input[type="date"]::-webkit-inner-spin-button, input[type="date"]::-webkit-calendar-picker-indicator {
    display: none;
    -webkit-appearance: none;
  }
  
  &.active, &:focus-within {
    border-color: #80bdff;
    outline: 0;
    height: fit-content;
    border-radius: 23px;
    width: fit-content;
    position: absolute;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  }

  > .element-input-calendar-icon-wrapper {
    flex: 0 100px;
    cursor: pointer;
  }

  &[disabled] {
    background-color: transparent;
  }
}