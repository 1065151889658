@import "variables.scss";

.easypay-table {
  >*{
    font-family: Overpass;
  }
  .react-bootstrap-table {
    .table thead th {
      border: none;
      //white-space: nowrap;
      overflow-x: visible;
    }
    .table-bordered td,
    .table-bordered th {
      border: none;
      padding: 17px 20px;
      overflow: scroll;
    }
    table {
      border: none;
      table-layout: fixed;
    }
  }
  .react-bootstrap-table-pagination {
    margin-right: 60px;
    margin-left: 60px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: right;

    .col-md-6,
    .col-xs-6,
    .col-sm-6,
    .col-lg-6 {
      min-width: none;
      max-width: none;
      flex: 1;
      font: normal normal normal 14px/22px Overpass;
      font-size: 14px;
      .btn-group > * {
        display: inline-block;
        background: none;
        border: none;
        text-align: left;
        letter-spacing: 0px;
        opacity: 1;
        outline: 0;
      }
      .btn-group {
        .prefix-text {
          font-size: 1rem;
          padding: 6px 12px;
          color: $color-neutral-4;
        }
        .btn-primary {
          color: $color-primary;
          text-decoration: underline;
        }
        .btn-link {
          color: $color-neutral-4;
        }
      }
      /*  } */
      .react-bootstrap-table-pagination-total {
        margin-top: 10px;
        float: right;
      }
    }
    .react-bootstrap-table-pagination-list {
      max-width: fit-content;
      min-width: fit-content;
      flex: none;
      .react-bootstrap-table-page-btns-ul {
        .page-item.active {
          .page-link {
            background-color: $color-primary;
            border-color: $color-primary;
            border-radius: 16px;
            color: $color-neutral-0;
            width: 32px;
            height: 32px;
            font-size: 14px;
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
        .page-item {
          padding: 4px;
          .page-link {
            &:hover {
              background-color: $color-primary;
              border-color: $color-primary;
              color: $color-neutral-0;
            }
            background-color: $color-neutral-0;
            border-color: $color-neutral-2;
            color: $color-neutral-4;
            border-radius: 16px;
            width: 32px;
            height: 32px;
            font-size: 14px;
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
      }
    }
  }
}
