.divAI {
  overflow-y: auto;
  flex-direction: column;
  display: flex;
  flex: 1 1 0%;
  position: relative;
  height: 60vh;
  border-radius: 0px 10px 10px 0px;
  border-bottom: 1px solid #ced4da;
  border-top: 1px solid #ced4da;
  border-right: 1px solid #ced4da;
  padding: 0.5em;
}
.divAIMobile {
  overflow-y: auto;
  flex-direction: column;
  display: flex;
  flex: 1 1 0%;
  position: relative;
  height: 60vh;
  border-radius: 10px 10px 10px 10px;
  border: 1px solid #ced4da;
  padding: 0.5em;
}

.scrollbar-ListMessages {
  overflow-y: auto;
  height: 60vh;
  padding: 1em;
}
.scrollbar-ListMessages::-webkit-scrollbar {
  width: 6px;
  border-radius: 20px;
}
.scrollbar-ListMessages::-webkit-scrollbar-track {
  background: #dadce0;
  border-radius: 20px;
}
.scrollbar-ListMessages::-webkit-scrollbar-thumb {
  background-color: #c1c1c1;
  border-radius: 6px;
  height: 10px;
  border-radius: 20px;
}

.scrollBar-ListChats {
  overflow-y: auto;
  height: 50vh;
}
.scrollBar-ListChats::-webkit-scrollbar {
  width: 6px;
}

.scrollBar-ListChats::-webkit-scrollbar-track {
  background: #dadce0;
}

.scrollBar-ListChats::-webkit-scrollbar-thumb {
  background-color: #c1c1c1;
  border-radius: 6px;
  height: 10px;
}

.MuiOutlinedInput-root {
  border-radius: 10px !important;
  padding: 0px 12px !important;
}

.datePickerExpenses
  .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-formControl.MuiInputBase-adornedEnd {
  border-radius: 23px !important;
  border: 1px solid #ced4da;
  height: 44px !important;
}
@media (max-width: 768px) {
  .datePickerExpenses
    .MuiInputBase-input.MuiOutlinedInput-input.MuiInputBase-inputAdornedEnd {
    font-size: 12px !important;
    font-weight: 400 !important;
  }
}

.listChats {
  width: 25%;
  padding: 1rem 0px;
  height: 60vh;
  border-radius: 10px 0px 0px 10px;
  border-bottom: 1px solid #ced4da;
  border-top: 1px solid #ced4da;
  border-left: 1px solid #ced4da;
  border-right: 1px solid #ced4da;
  background-color: whitesmoke;
}
.my-flex-container {
  display: flex;
  place-content: space-between;
  padding: 10px 0px 0px 10px;
}

.divToStartChat {
  text-align-last: center;
  padding: 10em;
  height: 100%;
}

.divToStartChatMobile {
  text-align-last: center;
  height: 100%;
  padding-top: 10em;
}
