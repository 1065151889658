.register-form-wrapper {
  display: flex;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: radial-gradient(#ffffff, #d6d6d6) !important;
  z-index: 2;
  align-items: flex-start;
  justify-content: center;
  overflow-y: scroll;
  position: fixed;
}

.register-center {
  align-items: center !important;
}

.register-form {
  border: 0px;
  padding: 0px !important;
  border-radius: 23px !important;
  -webkit-box-shadow: 0px 7px 30px -2px rgba(179, 179, 179, 1);
  -moz-box-shadow: 0px 7px 30px -2px rgba(179, 179, 179, 1);
  box-shadow: 0px 7px 30px -2px rgba(179, 179, 179, 1);
}

.register-header {
  background-color: #4484f4 !important;
  display: block !important;
  padding: 0.75rem 1.25rem !important;
  color: white;
  text-align: center;
  border: 0px;
  border-radius: 23px 23px 0px 0px !important;
  width: 100%;
}

.register-body {
  padding: 2rem !important;
}

.register-form-footer {
  display: flex;
  justify-content: flex-end;
  background-color: transparent !important;
  border: none !important;
  border-radius: 23px !important;
  padding-bottom: 30px !important;
}

.register-confirm-footer {
  display: flex;
  justify-content: center;
  border: none !important;
  background-color: transparent !important;
  border-radius: 23px !important;
  padding-bottom: 30px !important;
}

.label-register {
  font-size: 2rem;
  line-height: 1;
  font-weight: 300;
  color: white;
}
.reg-confirmation-txt > {
  margin-bottom: 1rem;
}

.register-form-label {
  font-weight: bold;
}

.list-group-item:first-child {
  border-top-left-radius: 23px !important;
  border-top-right-radius: 23px !important;
}

.list-group-item:last-child {
  border-bottom-right-radius: 23px !important;
  border-bottom-left-radius: 23px !important;
}

::-webkit-scrollbar {
  height: 0px;
  background: transparent; /* make scrollbar transparent */
}
