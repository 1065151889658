@mixin searchableDropdown($theme) {
  .searchable-input-search-wrapper {
    padding: 0;
    margin: 0px;
    height: 43px;
  }



  .searchable-input-search-icon {
    width: 20px;
    height: 19.39px;
  }
  .searchable-input-search-icon-wrapper {
    display: flex;
    padding-right: 16px;
    align-items: center;
  }

  .searchable-input-search-field {
    background-color: transparent;
    color: inherit;
    border: none;
    height: 100%;
    width: 100%;
    border-radius: 23px;
    padding-left: 20px;
    &:focus {
      outline: none;
    }
  }
  .searchable-input-search-field-wrapper.ultracompactForm {
    height: 35px!important;
  }

  .searchable-input-search-field-wrapper {
    display: flex;
    width: 100%;
    height: 36px;
    background: #fff 0% 0% no-repeat padding-box;
    border: 1px solid #ced4da;
    border-radius: theme-color($theme, "inputBorder");
    &.table-border-radius {
      border-radius: 0.25rem !important;
    }
    flex: 1;
    text-align: left;
    //color: #252529;
    color: inherit;
    font-size: 16px;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    &:focus-within {
      //color: #495057;
      background-color: #fff;
      border-color: #80bdff;
      outline: 0;
      box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
    }

    &.input-searchable-disabled {
      background-color: #e9ecef;
      color: #b9b9b9;
    }
  }

  .searchable-dropdown {
    display: none;
    position: fixed;
    z-index: 100;
    width: 100%;
    height: fit-content;
    background: #fff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 10px #6d6d6d40;
    border-radius: 4px;
    margin-top: 5px;
    padding-top: 4px;
    max-height: 215px;
    overflow: hidden;
    overflow-y: scroll;
    z-index: 101;
     
    &::-webkit-scrollbar {
      width: 6px;
    }
    
    &::-webkit-scrollbar-track {
      background: #e2e2f1;
    }
    
    &::-webkit-scrollbar-thumb {
      background-color: #c1c1c1;
      border-radius: 6px;
    }
  }



  .searchable-dropdown-option {
    display: flex;
    justify-content: left;
    align-items: center;
    padding-left: 20px;
    font-size: 14px;
    //color: #252529;
    color: inherit;
    height: 40px;
    &.currentHover {
      background: #f6f6f6 0% 0% no-repeat padding-box;
      color: #0d71f9;
    }
    &.searchable-no-border {
      border: none;
    }
    width: 100%;
    opacity: 1;

    &:hover {
      background: #f6f6f6 0% 0% no-repeat padding-box;
      color: #0d71f9;
    }
    & > * {
      cursor: default;
    }
  }
  .ultracompactDropdownOpt{
    height: 25px!important;
  }

  .searchable-dropdown-option-wrapper {
    display: flex;
    border: 2px solid #f6f6f6;
    border-width: 0px 0px 2px 0px;
    &:hover {
      background: #f6f6f6 0% 0% no-repeat padding-box;
      & > * {
        color: #0d71f9;
      }
    }
    & > * {
      cursor: default;
    }
  }

  .searchable-country-flag-option {
    width: 30px;
    height: 20px;
    margin: 10px 0px 10px 10px;
  }

  .searchable-option-selected {
    background: #f6f6f6 0% 0% no-repeat padding-box;
    color: #0d71f9;
  }

  .searchable-country-flag-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 20px;
  }

  .searchable-rotate-180 {
    transform: rotate(180deg);
  }

  /* Show the dropdown menu (use JS to add this class to the .dropdown-content container when the user clicks on the dropdown button) */
  .searchable-show {
    display: block;
  }

  #searchable-dropdown-no-result {
    display: flex;
    justify-content: left;
    align-items: center;
    padding-left: 20px;
    font-weight: 600;
    font-size: 14px;
    height: 40px;
    border: 2px solid #f6f6f6;
    border-width: 0px 0px 2px 0px;
    background: #f6f6f6 0% 0% no-repeat padding-box;
    color: #0d71f9;
  }
}
