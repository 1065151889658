@media (max-width: 415px) {
  #demo-process {
    // background-color: green;

    .form-group.row {
      flex-direction: column;
    }

    .iflow-form-label-fix {
      flex-basis: 0 !important;
    }
    .searchable-input-search-field {
      font-size: 12px;
    }
  }
}
