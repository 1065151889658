@import "liftworldVariables.scss";

.liftworld-option-text-wrapper {
  color: $liftworld-color-font-primary;
  @include weight("bold");
  font-size: 14px;
  align-items: center;
  display: flex;
}

.liftworld-option-content-wrapper {
  min-width: 100% !important;
}

.liftworld-option-main-inputs-wrapper{
  padding-left: 25px;
}

.liftworld-option-main-wrapper{
  min-width: 100% !important;
  width: 100% imor !important;
}

.liftworld-objective-img-text-wrapper {
  display: flex;
  margin-top: 15px;
  margin-bottom: 15px;
}

.liftworld-plus-icon {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding-top: 30px;
  padding-right: 9px;
}

.liftwolrd-option-input-wrapper{
  max-width: 318px;
}

.liftworld-option-inputs-row{
  padding-bottom: 40px;
  display: flex;
}

.liftworld-objective-textarea-wrapper{
  width: 100%;
  display: flex;
  padding-top: 15px;
  padding-bottom: 15px;
}

.liftworld-checkbox-wrapper{
  padding-left: 20px;
  margin-bottom: 10px;
}

.liftworld-dropdown-checkbox-wrapper{
  padding-left: 20px;
  padding-right: 20px;
}

.liftworld-checkbox-input{
    /* Double-sized Checkboxes */
    -ms-transform: scale(2); /* IE */
    -moz-transform: scale(2); /* FF */
    -webkit-transform: scale(2); /* Safari and Chrome */
    -o-transform: scale(2); /* Opera */
    transform: scale(2);
}

.liftworld-proposal-tables-wrapper{
  padding:30px;
  width: 100% !important;
  min-width: 100% !important;
}

.liftworld-checkbox-wrapper{
  padding-top: 7px;
}

.liftworld-delete-button-wrapper{
  padding-left: 25px;
  cursor: pointer;
}