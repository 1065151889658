@media (max-width: 1400px) {
  .easypay-header {
    padding: 0px 200px;
  }

  .easypay-card-text-title {
    padding-bottom: 0px;
    margin-bottom: 0px;
  }

  .easypay-tab-button-content {
    #formlabel-textlabel {
      .easypay-button-wrapper {
        .easypay-button-primary {
          margin-right: 15px;
        }
      }
    }
  }

  .easypay-button-wrapper {
    .easypay-button-add {
      padding-left: 0px;
    }
  }

  .easypay-card-text-blue {
    padding-top: 10px;
  }

  .easypay-single-accordion {
    width: 1200px;
    transform: translateX(-25%);
    .easypay-single-accordion-header {
      width: 90%;
      height: 30px;
    }
    .easypay-single-accordion-content-card {
      width: 90%;
    }
  }

  .row {
    margin-left: 0px;
    margin-right: 0px;
  }
}

@media (max-width: 1100px) {
  .easypay-header {
    padding: 0px 100px;
  }

  /*Vertical Nav start*/
  .easypay-vertical-nav-wrapper {
    flex-direction: column;
    width: 800px;
    justify-content: center;
    align-items: center;
    //margin-top: -110px;
    margin-top: 10px;
  }

  .easypay-vertical-nav {
    margin-left: 0px;
    &.vertical-nav {
      margin-left: 0px;
    }
    width: 80%;
  }

  .easypay-vertical-nav-item {
    width: 100%;
  }
  /*Vertical Nav end*/

  .easypay-card-text-title {
    width: 800px;
    padding-bottom: 0px;
    text-align: center;
    justify-content: center;
    align-items: center;
    margin-top: 0px;
    margin-bottom: 10px;
  }

  /*Nav content start*/
  .easypay-vertical-nav-content {
    min-width: 650px;
    width: 650px;
    margin: 0px;
  }

  .easypay-tab {
    max-width: 160px;
  }
  /*Nav content end*/

  .easypay-card {
    .easypay-card-text-title {
      width: auto;
      margin: 0px;
    }
  }
  .easypay-single-accordion {
    width: 100%;
    transform: translateX(0%);
    .easypay-single-accordion-header {
      width: 100%;
      height: 50px;
    }
    .easypay-single-accordion-content-card {
      width: 100%;
    }
  }
}

@media (max-width: 800px) {
  .easypay-header {
    padding: 0px 70px;
  }
  .easypay-header-links {
    flex-direction: column;
  }
  /*Vertical Nav start*/
  .easypay-vertical-nav-wrapper {
    flex-direction: column;
    width: 800px;
    justify-content: center;
    align-items: center;
    //margin-top: 10px;
  }

  .easypay-vertical-nav {
    margin-left: 0px;
    &.vertical-nav {
      margin-left: 0px;
    }
    width: 80%;
  }

  .easypay-vertical-nav-item {
    width: 100%;
  }
  /*Vertical Nav end*/

  /*Nav content start*/
  .easypay-tab {
    max-width: 160px;
  }
  /*Nav content end*/

  .easypay-single-accordion {
    // width: 100%;
    //transform: translateX(0%);
    .easypay-single-accordion-header {
      width: 435px;
      //height: 50px;
    }
    .easypay-single-accordion-content-card {
      width: 435px;
    }
  }
}

@media (max-width: 650px) {
  .easypay-header {
    padding: 0px 20px;
  }
  .easypay-card {
    padding: 40px;
    min-width: 0;
  }
  .easypay-card-wrapper {
    max-width: 540px;
  }
  .easypay-tab-button {
    width: 255px;
  }
  .easypay-body-xl {
    font-size: 16px;
  }

  .easypay-tab-button.easypay-separator {
    width: 165px;
  }

  /*Modal start*/
  .easypay-modal-wrapper {
    max-width: 420px;
  }
  .easypay-card-text-light {
    width: 370px;
  }
  /*Modal end*/

  /*Vertical Nav start*/
  .easypay-vertical-nav-wrapper {
    flex-direction: column;
    width: 650px;
    justify-content: center;
    align-items: center;
    //margin-top: 10px;
  }

  .easypay-vertical-nav {
    margin-left: 0px;
    &.vertical-nav {
      margin-left: 0px;
    }
  }
  /*Vertical Nav end*/

  .easypay-card-text-title {
    width: 650px;
  }

  /*Nav content start*/
  .easypay-vertical-nav-content {
    min-width: 600px;
    width: 600px;

    .easypay-card {
      .easypay-accordion > div {
        width: 540px;

        .easypay-accordion-content-card {
          width: 540px;
          padding-left: 0px;
          padding-right: 0px;

          .easypay-pepcheckbox-wrapper {
            width: 540px;
          }
        }
      }
    }
  }

  .easypay-tab {
    max-width: 160px;
  }
  /*Nav content end*/

  .easypay-benef-modal-body-wrapper > div {
    width: 420px !important;
    display: flex;
    flex-direction: column !important;
    justify-content: start;

    .easypay-input-wrapper-wrapper {
      width: 355px;
    }
    .easypay-input-field-wrapper {
      width: 80%;
    }
  }

  .easypay-repleg-modal-button-wrapper {
    padding-left: 0px !important;
    padding-top: 10px;
    .easypay-button-wrapper {
      justify-content: left !important;
    }
  }

  .easypay-repleg-modal-body-wrapper {
    .easypay-input-field-wrapper {
      width: 80%;
    }
  }

  .easypay-repleg-modal-link-wrapper {
    padding-top: 18px !important;
    padding-right: 10px;
  }
}

@media (max-width: 600px) {
  .easypay-card-wrapper {
    padding-top: 30px;
  }

  .easypay-header > * {
    transform: scale(0.9);
  }

  /*Vertical Nav start*/
  .easypay-vertical-nav-wrapper {
    flex-direction: column;
    width: 600px;
    justify-content: center;
    align-items: center;
    //margin-top: 10px;
  }

  .easypay-vertical-nav {
    margin-left: 0px;
    &.vertical-nav {
      margin-left: 0px;
    }
  }
  /*Vertical Nav end*/

  .easypay-card-text-title {
    width: 600px;
  }

  /*Nav content start*/
  .easypay-vertical-nav-content {
    min-width: 560px;
    width: 560px;

    .easypay-card {
      .easypay-accordion > div {
        width: 500px;

        .easypay-accordion-content-card {
          width: 500px;
          padding-left: 0px;
          padding-right: 0px;

          .easypay-pepcheckbox-wrapper {
            width: 500px;
          }
        }
      }
    }
  }

  /*Nav content end*/
}

@media (max-width: 560px) {
  /*Vertical Nav start*/
  .easypay-vertical-nav-wrapper {
    flex-direction: column;
    width: 560px;
    justify-content: center;
    align-items: center;
    //margin-top: 10px;
  }

  .easypay-vertical-nav {
    margin-left: 0px;
    &.vertical-nav {
      margin-left: 0px;
    }
  }
  /*Vertical Nav end*/

  .easypay-card-text-title {
    width: 560px;
  }

  /*Nav content start*/
  .easypay-vertical-nav-content {
    min-width: 540px;
    width: 540px;

    .easypay-card {
      .easypay-accordion > div {
        width: 500px;

        .easypay-accordion-content-card {
          width: 500px;
          padding-left: 0px;
          padding-right: 0px;

          .easypay-pepcheckbox-wrapper {
            width: 500px;
          }
        }
      }
    }
  }
  .easypay-tab {
    max-width: 150px;
  }
  /*Nav content end*/
}

@media (max-width: 540px) {
  .easypay-card-wrapper > * {
    padding-top: 30px;
  }
  .easypay-card-wrapper {
    max-width: 480px;
  }

  .easypay-tab-button-wrapper {
    width: 100%;
    justify-content: space-around;
    padding: 10px 0px;
    div {
      width: 30%;
    }

    .easypay-tab-button.easypay-separator {
      width: 100%;
    }
  }

  .easypay-content {
    margin-top: 50px;
  }
  .easypay-body-xl {
    font-size: 15px;
  }
  .easypay-header > * {
    transform: scale(0.8);
  }

  /*Modal start*/
  .easypay-modal-wrapper {
    max-width: 435px;
  }
  .easypay-card-text-light {
    width: 355px;
  }
  /*Modal end*/

  /*Vertical Nav start*/
  .easypay-vertical-nav-wrapper {
    flex-direction: column;
    width: 540px;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
  }

  .easypay-vertical-nav {
    margin-left: 0px;
    &.vertical-nav {
      margin-left: 0px;
    }
  }
  /*Vertical Nav end*/

  /*Nav content start*/
  .easypay-vertical-nav-content {
    min-width: 450px;
    width: 450px;

    .easypay-card {
      .easypay-accordion > div {
        width: 380px;

        .easypay-accordion-content-card {
          width: 380px;
          padding-left: 0px;
          padding-right: 0px;

          .easypay-pepcheckbox-wrapper {
            width: 380px;
          }
        }
      }
    }
  }

  .easypay-tab-wrapper {
    padding: 0px;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  /*Nav content end*/

  .easypay-tab-button-content {
    #formlabel-textlabel {
      flex-direction: column;

      .easypay-button-wrapper {
        .easypay-button-primary {
          margin-left: 45px;
          margin-right: 0px;
        }
        .easypay-button-secondary-icon {
          margin-left: 30px;
          margin-right: 0px;
        }
      }
    }
  }
  .easypay-radio-button {
    height: auto;
    .easypay-radio-button-label {
      min-width: auto;
    }
  }

  .easypay-tab-button-wrapper > div {
    justify-content: center;

    flex-grow: 0.9;
    .easypay-tab-button {
      width: 100%;
    }
  }

  .easypay-toggle-accordion-pda-input-wrapper {
    flex-direction: column;

    & > div {
      width: 100% !important;
      padding-right: 20px;
    }
  }

  .easypay-toggle-accordion-text-wrapper {
    padding-top: 15px;
  }
}

@media (max-width: 435px) {
  .easypay-content {
    margin-top: 100px;
  }

  /*Modal start*/
  .easypay-modal-wrapper {
    max-width: 400px;
  }
  .easypay-card-text-light {
    width: 360px;
  }
  /*Modal end*/

  /*Vertical Nav start*/
  .easypay-vertical-nav-wrapper {
    flex-direction: column;

    justify-content: center;
    align-items: center;
  }

  .easypay-vertical-nav {
    margin-left: 0px;
    &.vertical-nav {
      margin-left: 0px;
    }
    transform: scale(0.9);
  }
  /*Vertical Nav end*/

  /*Nav content start*/
  .easypay-vertical-nav-content {
    min-width: 420px;
    width: 420px;

    .easypay-card {
      .easypay-accordion > div {
        width: 300px;

        .easypay-accordion-content-card {
          width: 300px;
          padding-left: 0px;
          padding-right: 0px;

          .easypay-pepcheckbox-wrapper {
            width: 300px;
          }
        }
      }
    }
  }
  /*Nav content end*/
  .alice-onboarding-wrapper {
    margin-top: 100px;
  }

  .easypay-single-accordion {
    .easypay-single-accordion-header {
      width: 350px;
      //height: 50px;
    }
    .easypay-single-accordion-content-card {
      width: 350px;
    }
  }

  .easypay-document-info-wrapper {
    width: auto;
  }
}

@media (max-width: 420px) {
  .easypay-card-wrapper {
    padding-top: 0;
    height: 100vh;
    width: 100vw;
  }

  /*Modal start*/
  .easypay-modal-wrapper {
    max-width: 350px;
  }
  .easypay-card-text-light {
    width: 310px;
  }
  /*Modal end*/

  /*Vertical Nav start*/
  .easypay-vertical-nav-wrapper {
    flex-direction: column;
    width: 420px;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
  }

  .easypay-vertical-nav {
    margin-left: 0px;
    &.vertical-nav {
      margin-left: 0px;
    }
    transform: scale(0.9);
  }
  /*Vertical Nav end*/

  .easypay-card-text-title {
    width: 420px;
    //margin-top: 60px;
    margin-bottom: -20px;
  }

  /*Nav content start*/
  .easypay-vertical-nav-content {
    min-width: 400px;
    width: 400px;
  }
  /*Nav content end*/

  .easypay-document-item-wrapper {
    flex-direction: column;
  }

  .easypay-benef-modal-body-wrapper > div {
    width: 420px !important;
  }

  .easypay-repleg-modal-body-wrapper {
    .easypay-input-field-wrapper {
      width: 70%;
    }
  }

  .easypay-accordion-radio-button-wrapper {
    .easypay-radio-button-row {
      flex-direction: column;
    }
  }

  .easypay-wizard-wrapper {
    transform: scale(0.7);
  }

  .easypay-button-primary-large {
    width: 340px;
  }
}

/*TODO delete comment Iphone specific*/
@media (max-width: 380px) {
  /*Nav content start*/
  .easypay-vertical-nav-content {
    min-width: 355px;
    width: 355px;
  }
  /*Nav content end*/
}

@media (max-width: 355px) {
  /*Modal start*/
  .easypay-modal-wrapper {
    max-width: 320px;
  }
  .easypay-card-text-light {
    width: 280px;
  }
  /*Modal end*/

  /*Vertical Nav start*/
  .easypay-vertical-nav-wrapper {
    flex-direction: column;
    width: 355px;
  }

  .easypay-vertical-nav {
    margin-left: 0px;
    &.vertical-nav {
      margin-left: 0px;
    }
    transform: scale(0.8);
    width: 100%;
  }
  /*Vertical Nav end*/

  .easypay-card-text-title {
    width: 355px;
    //margin-top: 110px;
    margin-bottom: -50px;
  }

  /*Nav content start*/
  .easypay-vertical-nav-content {
    min-width: 355px;
    width: 355px;
  }
  /*Nav content end*/

  .easypay-benef-modal-body-wrapper > div {
    width: 355px !important;

    .easypay-input-field-wrapper {
      width: 60%;
    }
  }

  .easypay-repleg-modal-body-wrapper {
    .easypay-input-field-wrapper {
      width: 60%;
    }
  }

  .easypay-card-text-blue {
    //width: 340px;
  }

  .easypay-single-accordion {
    .easypay-single-accordion-header {
      width: 310px;
    }
    .easypay-single-accordion-content-card {
      width: 310px;
    }
  }

  .easypay-button-primary-large {
    width: 315px;
  }

  .easypay-radio-card-image img {
    margin-top: 38px;
  }
}

@media (max-width: 315px) {
  /*Modal start*/
  .easypay-modal-wrapper {
    max-width: 300px;
  }
  .easypay-card-text-light {
    width: 220px;
  }
  /*Modal end*/

  /*Vertical Nav start*/
  .easypay-vertical-nav-wrapper {
    flex-direction: column;
    width: 315px;
    margin-top: 55px;
  }

  .easypay-vertical-nav {
    margin-left: 0px;
    margin-top: 5px;
    &.vertical-nav {
      margin-left: 0px;
    }
    transform: scale(0.8);
  }
  /*Vertical Nav end*/

  .easypay-card-text-title {
    width: 315px;
    margin-bottom: -70px;
  }

  /*Nav content start*/
  .easypay-vertical-nav-content {
    min-width: 315px;
    width: 315px;

    .easypay-card {
      .easypay-accordion > div {
        width: 260px;

        .easypay-accordion-content-card {
          width: 260px;
          padding-left: 0px;
          padding-right: 0px;

          .easypay-pepcheckbox-wrapper {
            width: 260px;
          }
        }
      }
    }
  }
  /*Nav content end*/

  .easypay-benef-modal-body-wrapper > div {
    width: 315px !important;
    .easypay-input-wrapper-wrapper {
      width: 315px;
    }
  }

  .easypay-input-size-20 {
    height: 100px;
    .easypay-input-label {
      height: 60px;
    }
  }

  .easypay-card-text-blue {
    //width: 300px;
  }

  .easypay-header-3 {
    padding-left: 15px;
    padding-right: 15px;
  }

  .easypay-button-primary-large {
    width: 200px;
  }
}
