@import "variables.scss";

.easypay-link-wrappe {
  display: flex;
}

.easypay-link-primary {
  @include weight("semi-bold");
  cursor: pointer;
  text-decoration: underline !important;
  color: $color-primary !important;
  border: none;
  width: fit-content;
  background-color: transparent;
  &:focus {
    outline: none;
  }
  &:hover {
    color: $color-link-hover !important;
  }
  &[disabled] {
    pointer-events: none;
    color: $color-neutral-4 !important;
  }
  &.easypay-remove {
    text-decoration: none !important;
    font-weight: bold;
  }
  &.easypay-edit {
    text-decoration: none !important;
    font-weight: bold;
  }
  &.easypay-link-disabledColored {
    color: $color-primary-disabled !important;
  }
}

.easypay-button-link-primary {
  @include weight("semi-bold");
  cursor: pointer;
  text-decoration: underline !important;
  color: $color-primary !important;
  border: none;
  background-color: transparent;
  &:focus {
    outline: none;
  }
  &:hover {
    color: $color-link-hover !important;
  }
  &[disabled] {
    pointer-events: none;
    color: $color-neutral-4 !important;
  }
  &.easypay-remove {
    text-decoration: none !important;
    font-weight: bold;
  }

  &.easypay-link-disabledColored {
    color: $color-primary-disabled !important;
  }
}

.easypay-icon-less {
  margin-right: 10px;
  height: 24px;
  width: 24px;
  margin-bottom: 3px;
}
