.transparent-card {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 20px;
  /* color: black; */
  background-color: rgba(255,255,255, 0);
  padding: 40px;
}

.transparent-card:hover {
  background-color: white;
}

.transparent-element {
  opacity: 1;
}

/* .transparent-card:hover > .transparent-element{
  opacity: 1;
  cursor: pointer;
} */