@import "variables.scss";

.easypay-toggle-accordion-wrapper{
    border-bottom: 0.5px solid $color-neutral-2;
}

.easypay-toggle-accordion-header{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-bottom: 20px;
    padding-top: 20px;
}

.easypay-toggle-accordion-name{
    font-size: 16px;
    padding-top: 4px;
    color: $color-neutral-6;
    justify-content: center;
    align-items: center;
    @include weight("semi-bold");
}

.easypay-toggle-accordion-pda-input-wrapper{
    display: flex;
    flex-direction: row;
}

.easypay-toggle-accordion-text-wrapper{
    display: flex;
    flex-direction: row;
}

.easypay-repleg-toggle-button-wrapper{
padding-bottom: 15px;
}
