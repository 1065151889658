$color-primary: #0d71f9;
$color-primary-disabled: #0d6ff956;
$color-secondary: #F92DD3;//#ff6a00; For standard version
$color-primary-light: #d9e9ff;
$color-chip-hover: #d9e9ff80;
$color-success: #048956;
$color-success-light: #def2ea;
$color-warning: #f78d3f;
$color-warning-light: #f7e9df;
$color-error: #f90d0d;
$color-error-light: #ffdbdb;
$color-neutral-6: #252529;
$color-neutral-5: #545460;
$color-neutral-4: #6d6d6d;
$color-neutral-4-disabled: #6d6d6d50;
$color-neutral-3: #b9b9b9;
$color-neutral-2: #e8e8e8;
$color-neutral-1: #f6f6f6;
$color-neutral-0: #ffffff;
$color-shadow-primary: #0d71f940;
$color-shadow-secondary: #6d6d6d40;
$color-shadow-disabled: #e8e8e840;
$color-shadow-error: #f90d1540;
$color-shadow-success: #04895640;
$color-shadow-add: #f92dd340;
$color-link-hover: #90befc;
$color-separator: #707070;
$color-background-light:#fafafa;
$color-unikint-standard: #0d71f9;

$font-family: "Overpass", "Roboto", sans-serif;

@mixin weight($value) {
  font-family: $font-family;
  letter-spacing: 0px;
  opacity: 1;

  @if ($value == "light") {
    font-style: normal;
    font-variant: normal;
    font-weight: 300;
  }

  @if ($value == "regular") {
    font-style: normal;
    font-variant: normal;
    font-weight: normal;
  }

  @if ($value == "semi-bold") {
    font-style: normal;
    font-variant: normal;
    font-weight: 600;
  }

  @if ($value == "bold") {
    font-style: normal;
    font-variant: normal;
    font-weight: bold;
  }
}
