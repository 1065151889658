@import "liftworldVariables.scss";

.liftworld-input-search-wrapper {
  padding: 0;
  margin: 0;
  height: 30px;
  width: inherit;
}

.liftworld-input-search-icon {
  width: 20px;
  height: 19.39px;
}
.liftworld-input-search-icon-wrapper {
  display: flex;
  padding-right: 16px;
  align-items: center;
}

.liftworld-input-search-field {
  background-color: transparent;
  border: none;
  height: 100%;
  width: 100%;
  border-radius: 23px;
  padding-left: 10px;
  &:focus {
    outline: none;
  }
}

.liftworld-input-search-field-wrapper {
  display: flex;
  flex-direction: row;
  height: 30px;
  background: $liftworld-color-neutral-0 0% 0% no-repeat padding-box;
  border: 2px solid $liftworld-color-neutral-2;
  flex: 1;
  text-align: left;
  @include weight("regular");
  font-size: 16px;
  color: $liftworld-color-neutral-6;
  transition: all 0.2s linear;
  width:100%;
  &:hover {
    cursor: default;
  }
  &:focus-within {
    outline: none;
    border: 2px solid  $liftworld-color-neutral-2;
  }
  &.single {
    border-bottom-right-radius: 12px;
    border-bottom-left-radius: 12px;
  }
  &.start {
    border-bottom-left-radius: 12px;
  }
  &.end {
    border-bottom-right-radius: 12px;
  }
  &[disabled] {
    background-color: $liftworld-color-neutral-1;
    color: $liftworld-color-neutral-3;
  }
}

.liftworld-input-selected {
  outline: none;
  border: 2px solid $liftworld-color-font-primary;
}
