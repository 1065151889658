@import "theming.scss";

@mixin form($theme) {
  form {
    width: 100%;
    height: 100%;

    & label {
      color: theme-color($theme, "textColor");
    }
    .form-control {
      color: theme-color($theme, "textColor");
      width: 100%;
    }
  }

  button.link {
    background: transparent;
    color: theme-color($theme, "linkColor");
    border: none;
    outline: 0;
    padding: 0;
  }
  button.link:hover {
    color: theme-color($theme, "linkColorHover");
    text-decoration: underline;
  }
  button.link:focus {
    outline: none;
  }
  .signature-icon {
    background: transparent;
    color: theme-color($theme, "linkColor");
    border: none;
    outline: 0;
    padding: 0;
    margin-left: 10px;
  }
  .signature-icon:hover {
    color: theme-color($theme, "linkColorHover");
  }
  .signature-preview-wrap {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    height: 100%;
  }
  .signature-col-wrapper {
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid #dee2e6;
    padding: 10px;
    margin: 10px 0px;
  }

  .signature-layout-wrapper {
    margin-top: 10px;
  }
  .signature-button {
    min-width: 105px;
    background-color: theme-color($theme, "uniksystemColor");
  }
  .preview-canvas-wrapper {
    width: 100%;
    outline: 1px solid theme-color($theme, "uniksystemColor");

    .react-pdf__Page__canvas {
      width: 100% !important;
      height: auto !important;
      z-index: 0 !important;
    }
  }
  .helper-button {
    z-index: 100;
    position: absolute;
    bottom: 100px;
    left: 100px;
    height: 100px;
    width: 300px;
    border: 3px dotted #2980b9;
    color: #2980b9;
    background-color: #f8f9fa;
  }

  .helper-button:focus {
    outline: none;
  }

  .iflow-table {
    table {
      & th {
        font-size: 12px;
      }
      thead {
        & tr:first-child {
          border-radius: 23px !important;
        }
      }
    }
  }

  .hidden-searchbar-fix {
    padding: 0px !important;
  }

  .searchbar-float-right {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
  }

  .info-icon{
    height: 20px;
    width: 20px;
  }

  .searchbar-float-left {
    padding-top: 25px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
  }

  .iflow-form-label-fix {
    min-width: 250px !important;
    flex-basis: theme-color($theme, "flexBasis");
    width: 250px !important;
    flex-grow: 0 !important;
  }

  .borderOnNotDisabledFields {
    border-left: theme-color($theme, "borderOnNotDisabledFields");
    height: auto;
  }

  .iflow-form-label-fix-cma {
    flex-grow: 0 !important;
  }

  .create-text-input-suffix-fix {
    border-bottom-right-radius: 0px !important;
    border-top-right-radius: 0px !important;
  }

  .create-text-input-suffix {
    background-color: #fff !important;
    border: 1px solid #ced4da !important;
    border-radius: 23px !important;
    border-bottom-left-radius: 0px !important;
    border-top-left-radius: 0px !important;
    height: 35px;
  }

  .create-text-input-suffix-hidden {
    background-color: transparent !important;
    border: none !important;
    font-weight: bold !important;
    padding: 0px !important;
  }

  .create-bootstraptable-select-input-fix {
    margin-bottom: 0px !important;

    .custom-input-dropdown-field-wrapper {
      border-radius: 20px;
      height: 35px;
    }

    input {
      padding: 10px !important;
      font-size: 14px;
    }

    option {
      padding-left: 0px !important;
    }
  }

  .create-table-text-input {
    > input {
      padding: 0px !important;
      height: 35px !important;
    }
  }

  .autoWidth {
    width: auto;
    min-width: 50%;
    table {
      width: auto;
      min-width: 50%;

      & th {
        font-size: 14px;
      }
    }

    .checkbox-fix {
      width: auto;
      table {
        width: auto;
        min-width: 100%;
      }
    }
  }

  .tableFont12 {
    table {
      & td {
        font-size: 12px !important;
      }
    }
  }

  .tableFont14 {
    table {
      & td {
        font-size: 14px !important;
      }
    }
  }

  .tableFont16 {
    table {
      & td {
        font-size: 16px !important;
      }
    }
  }

  .tableFont18 {
    table {
      & td {
        font-size: 18px !important;
      }
    }
  }

  .tableFont20 {
    table {
      & td {
        font-size: 20px !important;
      }
    }
  }

  .fitContent {
    width: fit-content;
  }

  .iflow-tableUltraCompact {
    .react-bootstrap-table {
      padding: 0px 15px;
    }
  }

  .iflow-table {
    margin-bottom: 20px;

    scrollbar-color: theme-color($theme, "uniksystemColor") #ffffff;

    .checkbox-fix {
      overflow: auto;
      &::-webkit-scrollbar {
        width: 6px;
      }

      &::-webkit-scrollbar-track {
        background: transparent;
        border: 1px solid #e7e7e7;
      }

      &::-webkit-scrollbar-thumb {
        background-color: theme-color($theme, "uniksystemColor");
        border-right: 1px solid #e7e7e7;
        border-left: 1px solid #e7e7e7;
      }

      .react-bootstrap-table {
        max-height: 250px;

        thead tr th {
          position: sticky;
          top: 0;
          background-color: #fff;
          border-top: 1px solid #dee2e6;
        }
      }
    }
  }

  .form-control-dropdown {
    display: flex;
    width: 100%;
    height: 45px;
    background: #fff 0% 0% no-repeat padding-box;
    border: 1px solid #ced4da;
    border-radius: 23px;
    flex: 1;
    text-align: left;
    font-size: 16px;
    color: theme-color($theme, "textColor");
    transition: all 0.2s linear;
    &:hover {
      cursor: default;
    }
    &:focus-within {
      color: #495057;
      background-color: #fff;
      border-color: #80bdff;
      outline: 0;
      box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
    }

    &.input-dropdown-disabled {
      background-color: #e9ecef;
      color: #b9b9b9;
    }
  }

  .icon-remove {
    cursor: pointer;
  }

  .icon-maximize {
    cursor: pointer;
  }

  .icon-restore {
    cursor: pointer;
  }
  .renderFieldsParent {
    align-content: space-evenly;
  }
  
  @media (max-width: 768px) {
    .renderFieldsParent {
      padding: 0px !important;
    }
  }
}
