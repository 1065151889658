.tableDateInformation.react-bootstrap-table {
    overflow-y: auto;
    max-height: 280px;
}
.scrollBar-tableDateInformation .react-bootstrap-table {
    padding: 10px;
}
.scrollBar-tableDateInformation .react-bootstrap-table::-webkit-scrollbar {
    width: 6px;
  } 

  .scrollBar-tableDateInformation .react-bootstrap-table::-webkit-scrollbar-track {
    background: #dadce0;
  }

  .scrollBar-tableDateInformation .react-bootstrap-table::-webkit-scrollbar-thumb {
    background-color: #c1c1c1;
    border-radius: 6px;
    height: 10px;
  }


  .scrollBar-autorizersDateInformation {
    padding: 10px;
    max-height: 220px;
    overflow-y: auto;
    }
  .scrollBar-autorizersDateInformation::-webkit-scrollbar {
    width: 6px;
  } 

  .scrollBar-autorizersDateInformation::-webkit-scrollbar-track {
    background: #dadce0;
  }

  .scrollBar-autorizersDateInformation::-webkit-scrollbar-thumb {
    background-color: #c1c1c1;
    border-radius: 6px;
    height: 10px;
  }

  .MuiAlert-message{
    width: 100%;
  }
  .tableModalRemoteWork{
    max-height: 280px;
  }

  .scrollBar-visibleModal{
    overflow-y: auto;
    max-height: 500px;
  }
.scrollBar-visibleModal::-webkit-scrollbar {
  width: 6px;
}

.scrollBar-visibleModal::-webkit-scrollbar-track {
  background: #dadce0;
}

.scrollBar-visibleModal::-webkit-scrollbar-thumb {
  background-color: #c1c1c1;
  border-radius: 6px;
  height: 10px;
}

.table-desempenho thead {
  position: sticky!important;
  top: 0!important;
}