@import "liftworldVariables.scss";

.liftworld-documents-list-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.liftworld-blue-text{
  font-size: 16px;
  color: $liftworld-link-color-font ;
  @include weight("bold");
}

.liftworld-document-submitted-wrapper{
  padding-top: 13px;
  display: flex;
  width: fit-content;
  cursor: pointer;
}
