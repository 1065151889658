@import "liftworldVariables.scss";

.liftworld-text-wrapper-large{
    color: $liftworld-color-font-primary;
    @include weight("bold");
    font-size: 16px;
    align-items: center;
    display: flex;
    width: 300px;
}

.liftworld-text-wrapper{
    color: $liftworld-color-font-primary;
    @include weight("bold");
    font-size: 16px;
    align-items: center;
    display: flex;
}

.liftworld-text-wrapper-proposal{
    color: $liftworld-color-font-primary;
    @include weight("bold");
    font-size: 16px;
    align-items: center;
    display: flex;
    min-width: fit-content;
    width:100px;
}

.liftworld-text-large{
    color: $liftworld-color-font-primary;
    @include weight("bold");
    font-size: 20px;
    align-items: center;
    padding-bottom: 20px;
    display: flex;
    width: 300px;
}


.liftworld-image-text-wrapper{
    display: flex;

}

.liftworld-expenses-sales-wrapper{
    padding-top: 20px;
    padding-left: 28px;
}

.liftworld-expenses-sales-values-wrapper{
    padding-top: 15px;
}

.liftworld-option-wrapper{
    display: flex;
}
