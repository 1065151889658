@import 'theming.scss';

@mixin chat($theme) {
    
  .right-sidebar, .chat {
    background-color: theme-color($theme, 'backgroundColor');
  }

  .right-sidebar .sidebar-title {
    color: theme-color($theme, 'textColor');
  }

  .sidebar-chat .list-group-item, .chat-panel .user-name {
    color: theme-color($theme, 'textColor');
    .name, .username {
      color: theme-color($theme, 'textColor');
    }
  }

  .widget-chat-activity .message p {
    background: theme-color($theme, 'uniksystemColor');
    border: 1px solid theme-color($theme, 'uniksystemColor');
  }

  .widget-chat-activity .message.reply p {
    background: theme-color($theme, 'chatMessageReplyBackground');
    border: theme-color($theme, 'navbarTextColor');
  }

  .widget-chat-activity .message p:first-child:before {
    border-color: transparent transparent transparent theme-color($theme, 'widgetChat');
  }

  .widget-chat-activity .message p:first-child:after {
    border-color: transparent transparent transparent theme-color($theme, 'widgetChat');
  }
  
  //Reply
  .widget-chat-activity .message.reply p:first-child:before {
    border-color: transparent transparent transparent theme-color($theme, 'chatMessageReplyBackground');
  }

  .widget-chat-activity .message.reply p:first-child:after {
    border-color: transparent theme-color($theme, 'chatMessageReplyBackground') transparent transparent;
  }

  .chat-msg-input {
    background: theme-color($theme, 'chatMsgInput');
  }

  .chat-panel .close {
   color: theme-color($theme, 'closeChat');
  }

}
