@import "variables.scss";

.easypay-dropdown {
  display: none;
  position: relative;
  z-index: 5;
  width: 100%;
  height: fit-content;
  background: $color-neutral-0 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 10px $color-shadow-secondary;
  border-radius: 4px;
  margin-top: 5px;
  padding-top: 4px;
  max-height: 215px;
  overflow: hidden;
  overflow-y: scroll;
}

.easypay-dropdown-option {
  display: flex;
  justify-content: left;
  align-items: center;
  padding-left: 20px;
  @include weight("semi-bold");
  font-size: 14px;
  color: $color-neutral-6;
  height: 40px;

  &.easypay-no-border {
    border: none;
  }
  width: 100%;
  opacity: 1;

  &:hover {
    background: $color-neutral-1 0% 0% no-repeat padding-box;
    color: $color-primary;
  }
  & > * {
    cursor: default;
  }
}

.easypay-dropdown-option-wrapper {
  display: flex;
  border: 2px solid $color-neutral-1;
  border-width: 0px 0px 2px 0px;
  &:hover {
    background: $color-neutral-1 0% 0% no-repeat padding-box;
    & > * {
      color: $color-primary;
    }
  }
  & > * {
    cursor: default;
  }
}

.easypay-country-flag-option {
  width: 30px;
  height: 20px;
  margin: 10px 0px 10px 10px;
}

.easypay-option-selected {
  background: $color-neutral-1 0% 0% no-repeat padding-box;
  color: $color-primary;
}

.easypay-country-flag-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 20px;
}

.easypay-rotate-180 {
  transform: rotate(180deg);
}

/* Show the dropdown menu (use JS to add this class to the .dropdown-content container when the user clicks on the dropdown button) */
.easypay-show {
  display: block;
}

#easypay-dropdown-no-result {
  display: flex;
  justify-content: left;
  align-items: center;
  padding-left: 20px;
  @include weight("semi-bold");
  font-size: 14px;
  height: 40px;
  border: 2px solid $color-neutral-1;
  border-width: 0px 0px 2px 0px;
  background: $color-neutral-1 0% 0% no-repeat padding-box;
  color: $color-primary;
}
