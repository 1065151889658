@import 'theming.scss';

@mixin menu($theme) {
    .icon-menu-settings {
      float: right;
      cursor: pointer;
      margin-right: 10px;
    }

    .menu {
        background-color: theme-color($theme, 'menuBackgroundColor');
        min-width: 260px;
        width: 260px;
        min-height: 89vh;
        height: 100%;
        position: relative;
        border-radius: 0;

        .menu-item {
            border: 0px;
            border-radius: 0;

            &.card {
              cursor: pointer;
            }

            .menu-toggle-main {
                position: relative;
                padding: 0.75rem 1rem;
                width: 100%;
                border-radius: 0;
                text-indent: 0px;
                background-color: theme-color($theme, 'menuMainItemBackgroundColor');
                color: white;
                font-weight: 700;
            }
            .menu-toggle-main:hover {
                text-indent: 10px;
            }
            .menu-toggle-lvl1 {
                display: flex;
                justify-content: space-between;
                position: relative;
                padding: 0.75rem 1rem;
                width: 100%;
                height: auto;
                border-radius: 0;
                text-indent: 0px;
                color: theme-color($theme, 'menuItemTextColor');
                background-color: theme-color($theme, 'menuBackgroundColor');
            }

            .menu-toggle-lvl1:hover {
                background-color: theme-color($theme, 'menuItemLvl1BackgroundColorHover');
                text-indent: 10px; 
                //height: 58px;
            }

            .menu-toggle-lvl2 {
                display: flex;
                justify-content: space-between;
                position: relative;
                padding: 0.75rem 1rem;
                width: 100%;
                color: theme-color($theme, 'menuItemLvl2TextColor');
                background-color: theme-color($theme, 'menuItemLvl2BackgroundColor');

            }
            .menu-toggle-lvl2-active-div {
                height: 48px;
                display: flex;
                justify-content: space-between;
                position: relative;
                width: 100%;
                color: theme-color($theme, 'menuItemLvl2TextColor');
                background-color: theme-color($theme, 'menuItemLvl2BackgroundColor');
                padding: 0rem;
                font-weight: 600;

            }
            .menu-toggle-lvl2-active {
                height: 100%;
                align-items: center;
                justify-content: center;
                display: flex;
                border-left: theme-color($theme, 'menuActiveBorder');
                padding-left: 20px;
            }
            .menu-toggle-lvl2-active-div:hover{
                background-color: theme-color($theme, 'menuItemLvl2BackgroundColorHover');
            }
            .menu-toggle-lvl2:hover {
                background-color: theme-color($theme, 'menuItemLvl2BackgroundColorHover');
            }
            .menu-toggle-lvl3 {
                display: flex;
                justify-content: space-between;
                position: relative;
                padding: 0.75rem 1rem;
                width: 100%;
                color: theme-color($theme, 'menuItemLvl3TextColor');
                background-color: theme-color($theme, 'menuItemLvl3BackgroundColor');
            }
            .menu-toggle-lvl3:hover {
                background-color: theme-color($theme, 'menuItemLvl3BackgroundColorHover');
            }

            .menu-toggle-lvl3-active-div {
                height: 48px;
                display: flex;
                justify-content: space-between;
                position: relative;
                width: 100%;
                color: theme-color($theme, 'menuItemLvl3TextColor');
                background-color: theme-color($theme, 'menuItemLvl3BackgroundColor');
                padding: 0rem;
                font-weight: 600;
            }

            .menu-toggle-lvl3-active {
                height: 100%;
                align-items: center;
                justify-content: center;
                display: flex;
                border-left: theme-color($theme, 'menuActiveBorder');
                padding-left: 20px;
            }
            .menu-toggle-lvl3-active-div:hover{
                background-color: theme-color($theme, 'menuItemLvl3BackgroundColorHover');
            }

            .icon-pin {
                color: lightgray;
            }
            .icon-pin:hover {
                color: theme-color($theme, 'menuPinColor');
            }
            .icon-pin-active {
                color: theme-color($theme, 'menuPinColor');
            }
            .menu-link {
                text-decoration: none;
                color: theme-color($theme, 'menuItemTextColor');
                display: flex;
                justify-content: center;
                align-items: center;
            }
            .menu-link-main {
                text-decoration: none;
                color: white;
            }
        }
    }
}
