@import "variables.scss";

.easypay-signatures-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding-bottom: 15px;
}

.easypay-sig-link{
  width: fit-content;
  color: $color-primary;
  background-color: transparent;
  text-decoration: underline;
  @include weight("semi-bold");
  padding-left: 5px;
  font-size: 16px;
  &:focus {
    outline: none;
  }
  &:hover {
    color: $color-link-hover !important;
  }
  
}

.easypay-signatures-name-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.easypay-signatures-wrapper-no-padding {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.easypay-pda-text-wrapper {
  display: flex;
  flex-direction: column;
  min-width: fit-content;
}

.easypay-bullet-number-with-padding {
  display: flex;
  justify-content: center;
  align-content: center;
  background: $color-primary;
  color: $color-neutral-0;
  height: 28px;
  padding-top: 2px;
  @include weight("bold");
  font-size: 18px;
  width: 28px;
  border-radius: 14px;
  margin-right: 8px;
}
