.anacom-table-big {
  overflow: hidden;
  min-height: 45px;
  border: 1px solid #e7e6e6;
  outline: 1px solid #e7e6e6;
  align-items: center;
  transition: all 0.2s linear;
  text-align: left;
  font-size: 16px !important;
  color: #252529;
  transition: all 0.2s linear;
  display: flex;
  padding-left: 5px;
  width: 51%;
  &.color {
    background-color: #b9b9b9;
  }
  &.bold {
    font-weight: bold;
  }
}

.anacom-table-bigger {
  overflow: hidden;
  min-height: 45px;
  border: 1px solid #e7e6e6;
  outline: 1px solid #e7e6e6;
  align-items: center;
  transition: all 0.2s linear;
  text-align: left;
  font-size: 16px !important;
  color: #252529;
  transition: all 0.2s linear;
  display: flex;
  padding-left: 5px;
  width: 55%;
  &.color {
    background-color: #b9b9b9;
  }
  &.bold {
    font-weight: bold;
  }
}

.anacom-table-big-real {
  overflow: hidden;
  min-height: 45px;
  border: 1px solid #e7e6e6;
  outline: 1px solid #e7e6e6;
  align-items: center;
  transition: all 0.2s linear;
  text-align: left;
  font-size: 16px !important;
  color: #252529;
  transition: all 0.2s linear;
  display: flex;
  padding-left: 5px;
  width: 52%;
  &.color {
    background-color: #b9b9b9;
  }
  &.bold {
    font-weight: bold;
  }
}

.anacom-table-normal {
  overflow: hidden;
  min-height: 45px;
  border: 1px solid #e7e6e6;
  outline: 1px solid #e7e6e6;
  background-color: #ffffff;
  align-items: center;
  transition: all 0.2s linear;
  text-align: left;
  font-size: 16px !important;
  color: #252529;
  transition: all 0.2s linear;
  display: flex;
  padding-left: 5px;
  width: 37%;
  &.color {
    background-color: #b9b9b9;
  }
  &.bold {
    font-weight: bold;
  }
}

.anacom-table-medium {
  overflow: hidden;
  min-height: 45px;
  border: 1px solid #e7e6e6;
  outline: 1px solid #e7e6e6;
  background-color: #ffffff;
  align-items: center;
  transition: all 0.2s linear;
  text-align: left;
  font-size: 16px !important;
  color: #252529;
  transition: all 0.2s linear;
  display: flex;
  padding-left: 5px;
  width: 8%;
  &.color {
    background-color: #b9b9b9;
  }
  &.bold {
    font-weight: bold;
  }
}

.anacom-table-small {
  overflow: hidden;
  min-height: 45px;
  border: 1px solid #e7e6e6;
  outline: 1px solid #e7e6e6;
  background-color: #ffffff;
  align-items: center;
  justify-content: center;
  justify-items: center;
  transition: all 0.2s linear;
  text-align: left;
  font-size: 16px !important;
  color: #07070c;
  transition: all 0.2s linear;
  display: flex;
  width: 4%;
  &.color {
    background-color: #b9b9b9;
  }
  &.bold {
    font-weight: bold;
  }
}

.anacom-table-assunto {
  padding-left: 5px;
  overflow: hidden;
  min-height: 45px;
  border: 1px solid #e7e6e6;
  outline: 1px solid #e7e6e6;
  background-color: #ffffff;
  align-items: center;
  transition: all 0.2s linear;
  text-align: left;
  font-size: 16px !important;
  color: #07070c;
  transition: all 0.2s linear;
  display: flex;
  width: 96%;
  &.color {
    background-color: #b9b9b9;
  }
  &.bold {
    font-weight: bold;
  }
}

.anacom-table-percentagem {
  overflow: hidden;
  min-height: 45px;
  border: 1px solid #e7e6e6;
  outline: 1px solid #e7e6e6;
  background-color: #ffffff;
  align-items: center;
  justify-content: center;
  justify-items: center;
  transition: all 0.2s linear;
  text-align: left;
  font-size: 16px !important;
  color: #07070c;
  transition: all 0.2s linear;
  display: flex;
  width: 4%;
  &.color {
    background-color: #b9b9b9;
  }
  &.bold {
    font-weight: bold;
  }
}

.anacom-checkmark-img {
  height: 23px;
}

.Resultados_dropdownCategories {
  padding-top: 30px;
  display: flex;
  justify-content: center;
}

.dropdownPopUp {
  min-width: 400px;
}
