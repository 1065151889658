@import "variables.scss";

.easypay-backoffice-wrapper-leads {
  display: flex;
  overflow: auto;
  flex-direction: row;
  align-items: flex-end;
  position: relative;
  width: 100%;
  background: $color-neutral-0;
  height: 100%;
  justify-content: left;
}

.easypay-backoffice-leads-sub-wrapper {
  flex: 1;
  justify-content: right;
  padding-right: 80px;
}

.easypay-backoffice-leads-dummy-wrapper {
  min-width: 480px;
  min-height: 100px;
}

.easypay-backoffice-leads-form-wrapper {
max-width: 1210px;
  margin-top: 343px;
}

.easypay-backoffice-leads-buttons-wrapper {
  z-index: 3;
  position: fixed;
}

.easypay-backoffice-wrapper {
  width: 100%;
  height: 100%;
}

.easypay-backoffice-line-break-wrapper {
 max-width: 1210px;
  width: 100%;
}

.easypay-backoffice-submit-button-wrapper {
  padding-bottom: 30px;
}

.easypay-backoffice-vertical-nav {
  display: flex;
  margin-top: 343px;
}
