@import "variables.scss";

.easypay-backoffice-provadevida-section-header {
  color: $color-primary;
  @include weight("bold");
  font-size: 33px;
}

.easypay-backoffice-provadevida-main-wrapper {
  width: 100%;
}

.easypay-backoffice-registry-section-column-content {
  max-width: 580px;
  width: 100%;
}
.easypay-backoffice-provadevida-titles-wrapper {
  display: flex;
  color: $color-neutral-5;
  flex-direction: row;
  padding-left: 40px;
  padding-right: 40px;
  padding-bottom: 18px;
}

.easypay-backoffice-provadevida-results-wrapper {
  display: flex;
  flex-direction: row;
  padding-left: 40px;
  padding-right: 40px;
  padding-bottom: 10px;
  background-color: $color-background-light;
}

.easypay-backoffice-provadevida-method-title {
  width: 20%;
}

.easypay-backoffice-provadevida-date-title {
  width: 20%;
  justify-content: flex-end;
  display: flex;
}

.easypay-backoffice-provadevida-state-title {
  width: 60%;
  display: flex;
  justify-content: flex-end;
}

.easypay-backoffice-provadevida-method-result {
  @include weight("bold");
  width: 20%;
  padding-top: 20px;
  padding-bottom: 23px;
}

.easypay-backoffice-provadevida-date-result {
  width: 20%;
  padding-top: 20px;
  color: $color-neutral-5;
  padding-bottom: 23px;
  justify-content: flex-end;
  display: flex;
}

.easypay-backoffice-provadevida-state-result {
  width: 60%;
  display: flex;
  justify-content: flex-end;
  padding-top: 20px;
  padding-bottom: 23px;
}

.easypay-backoffice-provadevida-state-result-content {
  display: flex;
  flex-direction: row;
}

.easypay-backoffice-provadevida-state-result-text {
  @include weight("bold");
  color: $color-success;
}
.easypay-backoffice-provadevida-data-received-header {
  @include weight("bold");
  color: $color-primary;
  padding-top: 30px;
  padding-bottom: 20px;
}

.easypay-backoffice-provadevida-bottom-right-input-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.easypay-backoffice-provadevida-small-input-wrapper {
  max-width: 280px;
  width: 48%;
}

.easypay-backoffice-provadevida-content-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
