@import "variables.scss";

.easypay-tab-button-wrapper {
  display: flex;
  flex-wrap: wrap;
  background-color: $color-neutral-1;
  padding: 10px 0px 10px 10px;
  width: fit-content;
  border-radius: 30px;
}

.easypay-tab-button-content {
  margin-top: 20px;
}

.separator-card {
  border-top-left-radius: 0px;
  padding: 0px;
}
