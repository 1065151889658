@import "theming.scss";

@mixin richtextWrpper($theme) {
  .richtext-wrapper {
    color: #4c4b4b;
    border: 1px solid #ced4da;
    border-radius: theme-color($theme, "borders");
    padding: 5px 10px;
    min-height: 160px;
  }
}
