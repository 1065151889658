@import "liftworldVariables.scss";

.liftworld-table-input-wrapper-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width:100%;
}

.liftworld-table-input-wrapper {
  position: relative;
  align-items: center;
  padding: 0;
  margin: 0;
  width: 100%;
}

.liftworld-table-input-label {
  height: 30px;
  background: $liftworld-color-font-primary;
  color: $liftworld-color-neutral-0;
  justify-content: center;
  text-align: center;
  display: flex;
  border: 1px solid $liftworld-color-neutral-2;
  outline: 1px solid $liftworld-color-neutral-2;
  width: 100%;
  align-items: center;
  font-size: 11px;
  &.single {
    border-top-right-radius: 12px;
    border-top-left-radius: 12px;
  }
  &.start {
    border-top-left-radius: 12px;
  }
  &.end {
    border-top-right-radius: 12px;
  }
}

.liftworld-table-input-icon {
  position: relative;
  width: 16px;
  height: 12px;
  right: 40px;
}

.liftworld-table-input-field {
  background-color: transparent;
  border: none;
  height: 100%;
  width: 100%;
  border-radius: 23px;
  padding-left: 8px;
  &:focus {
    outline: none;
  }
  &.date{
    padding: 0px;
    justify-content: center;
    text-align: center;
  }
  &.number{
    justify-content: flex-end;
    text-align: right;
  }
  &.error{
    color: $liftworld-color-error;
  }
}

.liftworld-table-input-field-wrapper {
  width: 100%;
  overflow: hidden;
  height: 30px;
  background-color: $liftworld-color-neutral-0;
  border: 1px solid $liftworld-color-neutral-2;
  outline: 1px solid $liftworld-color-neutral-2;
  align-items: center;
  flex: 1;
  display: flex;
  justify-content: center;
  text-align: left;
  @include weight("regular");
  font-size: 17px;
  color: $liftworld-color-neutral-6;
  transition: all 0.2s linear;
  &.single {
    border-bottom-right-radius: 12px;
    border-bottom-left-radius: 12px;
  }
  &.start {
    border-bottom-left-radius: 12px;
  }
  &.end {
    border-bottom-right-radius: 12px;
  }
}

.liftworld-table-input-error-message {
  color: $liftworld-color-error;
  padding-left: 20px;
  padding-top: 5px;
  text-align: left;
  @include weight("bold");
  font-size: 14px;
  color: $liftworld-color-error;
  margin-bottom: 0;
}

.liftworld-table-input-size-380 {
  width: 380px;
  margin-bottom: 30px;
}
