@import 'theming.scss';

@mixin toast($theme) {
  & .toast {
      border: 0px;
      border-radius: 0;
      color: theme-color($theme, 'textColor');
     

      .toast-header {
        background-color: theme-color($theme, 'cardBackgroundColor');
        border-bottom: 0px;
        color: theme-color($theme, 'textColor');
      }
      
      &	.toast-body {
        background-color: theme-color($theme, 'backgroundColor');
       
      }

      & .toast-footer {
        background-color: theme-color($theme, 'cardBackgroundColor');
        border-radius: 0px;
        color: rgb(203, 203, 203);
        
      }
  }
}

