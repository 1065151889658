.avatar-upload {
  position: relative;
  max-width: 205px;
  .avatar-edit {
    position: absolute;
    right: 12px;
    z-index: 1;
    input {
      display: none;
      + label {
        display: inline-block;
        width: 34px;
        height: 34px;
        margin-bottom: 0;
        border-radius: 100%;
        background: #ffffff;
        border: 1px solid transparent;
        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.12);
        cursor: pointer;
        font-weight: normal;
        transition: all 0.2s ease-in-out;
        &:hover {
          background: #f1f1f1;
          border-color: #d6d6d6;
        }
        &:after {
          color: #757575;
          position: absolute;
          top: 10px;
          left: 0;
          right: 0;
          text-align: center;
          margin: auto;
        }
      }
    }
  }
  .avatar-delete {
    position: absolute;
    right: 58px;
    z-index: 1;
    display: inline-block;
    width: 34px;
    height: 34px;
    margin-bottom: 0;
    border-radius: 100%;
    background: #ffffff;
    border: 1px solid transparent;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.12);
    cursor: pointer;
    font-weight: normal;
    transition: all 0.2s ease-in-out;
    &:hover {
      background: #f1f1f1;
      border-color: #d6d6d6;
    }
    &:after {
      color: #757575;
      position: absolute;
      top: 10px;
      left: 0;
      right: 0;
      text-align: center;
      margin: auto;
    }
  }

  .avatar-preview {
    width: 192px;
    height: 192px;
    position: relative;
    clip-path: polygon(
      0 9%,
      9% 0%,
      91% 0%,
      100% 9%,
      100% 81%,
      81% 100%,
      18% 100%,
      0% 81%
    );
    > div {
      width: 100%;
      height: 100%;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
    }
  }

  .avatar-preview-fit-inside {
    width: 192px;
    height: 192px;
    position: relative;
    clip-path: polygon(
      0 9%,
      9% 0%,
      91% 0%,
      100% 9%,
      100% 81%,
      81% 100%,
      18% 100%,
      0% 81%
    );
    > div {
      width: 100%;
      height: 100%;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
    }
  }

  .add-picture {
    margin-left: 8px;
    margin-top: 8px;
    display: block;
  }
}
