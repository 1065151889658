
.element-hour-range {
    display: flex;
    flex-direction: row;
    align-items: center;
    border-bottom: 2px solid transparent;
    border:none;
    position: relative;

    &::after{
        content: '';
        position: absolute;
        width: 0px;
        height: 2px;
        left: 0%;
        bottom:0;
        background-color: #80bdff;
        transition: all ease-in-out .2s;
    }

    &:focus-within::after{
        width: 100%;
    }

    > input {
        -moz-appearance: textfield;
        width: 25px;
        border: none;
        &:focus-within {
            outline: none;
        }
    }
    
    > input::-webkit-outer-spin-button, input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    > div {
        position: absolute;

        > .element-hour-range-selector {
            position: fixed;
            display: none;
            max-height: 20%;
            overflow: auto;
            box-shadow: 0px 0px 5px -1px black;
            z-index: 9999;
            &.active {
                display: flex;
                flex-direction: column;
                margin-top: 20px;
                width: 50px;
                background-color: white;
            }

            &::-webkit-scrollbar{
                width: 5px;
                height: auto;
                background-color: transparent;
            }

            &::-webkit-scrollbar-thumb {
                background-color: var(--purple);
            }

            > span {
                height: 25px;
                position: relative;
                cursor: pointer;
                background-color: var(transparent);
                transition: all 0.3s ease-in-out;

                &:hover {
                    background-color: #80bdff;
                }
            }
        }
    }
}