@import "theming.scss";

@mixin dropdown($theme) {
  .custom-input-dropdown-wrapper {
    padding: 0;
    margin: 0;
    height: fit-content;
    width: 100%;
    padding-bottom: 0px;
    position: relative;
  }
  .custom-input-dropdown-icon-wrapper {
    display: flex;
    padding-right: 16px;
    align-items: center;
  }

  .custom-input-dropdown-icon {
    width: 15px;
    height: 8px;
    transition: transform 0.2s linear;
  }

  .custom-input-dropdown-field {
    background-color: transparent;
    color: #4c4b4b;
    border: none;
    height: 100%;
    width: 100%;
    border-radius: 23px;
    padding-left: 20px;
    &:focus {
      outline: none;
    }
    &:hover {
      cursor: default;
    }
  }

  .custom-input-dropdown-field-wrapper {
    display: flex;
    width: 100%;
    height: 35px;
    background: #fff 0% 0% no-repeat padding-box;
    border: 1px solid #ced4da;
    border-radius: theme-color($theme, "inputBorder");
    flex: 1;
    text-align: left;
    font-size: 16px;
    color: #252529;
    transition: all 0.2s linear;
    &:hover {
      cursor: default;
    }
    &:focus-within {
      color: #495057;
      background-color: #fff;
      border-color: #80bdff;
      outline: 0;
      box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
    }

    &.input-dropdown-disabled {
      background-color: #e9ecef;
      color: #b9b9b9;
    }
  }

  .custom-dropdown {
    display: none;
    position: absolute;
    z-index: 5;
    width: 100%;
    height: fit-content;
    background: #fff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 10px #6d6d6d40;
    border-radius: 4px;
    margin-top: 5px;
    padding-top: 4px;
    max-height: 215px;
    overflow: hidden;
    overflow-y: scroll;
    // &::-webkit-scrollbar {
    //   width: 0px;
    // }
    &::-webkit-scrollbar {
      width: 6px;
    }
    
    &::-webkit-scrollbar-track {
      background: #e2e2f1;
    }
    
    &::-webkit-scrollbar-thumb {
      background-color: #c1c1c1;
      border-radius: 6px;
    }
  }

  .custom-dropdown-option {
    display: flex;
    justify-content: left;
    align-items: center;
    padding-left: 20px;
    font-size: 14px;
    color: #252529;
    height: 40px;

    &.custom-no-border {
      border: none;
    }
    width: 100%;
    opacity: 1;

    &:hover {
      background: #f6f6f6 0% 0% no-repeat padding-box;
      color: #0d71f9;
    }
    & > * {
      cursor: default;
    }
  }

  .custom-dropdown-option-wrapper {
    display: flex;
    border: 2px solid #f6f6f6;
    border-width: 0px 0px 2px 0px;
    &:hover {
      background: #f6f6f6 0% 0% no-repeat padding-box;
      & > * {
        color: #0d71f9;
      }
    }
    & > * {
      cursor: default;
    }
  }

  .custom-country-flag-option {
    width: 30px;
    height: 20px;
    margin: 10px 0px 10px 10px;
  }

  .custom-option-selected {
    background: #f6f6f6 0% 0% no-repeat padding-box;
    color: #0d71f9;
  }

  .custom-country-flag-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 20px;
  }

  .custom-rotate-180 {
    transform: rotate(180deg);
  }

  /* Show the dropdown menu (use JS to add this class to the .dropdown-content container when the user clicks on the dropdown button) */
  .custom-show {
    display: block;
  }

  #custom-dropdown-no-result {
    display: flex;
    justify-content: left;
    align-items: center;
    padding-left: 20px;
    font-weight: 600;
    font-size: 14px;
    height: 40px;
    border: 2px solid #f6f6f6;
    border-width: 0px 0px 2px 0px;
    background: #f6f6f6 0% 0% no-repeat padding-box;
    color: #0d71f9;
  }
}
