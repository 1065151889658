@import "liftworldVariables.scss";

.liftworld-textarea-input-wrapper-wrapper {
  justify-content: center;
  align-items: center;
  padding-bottom: 20px;
  min-width: 100%;
}

.liftworld-textarea-input-wrapper {
  position:relative;
  flex-direction: row;
  align-items: center;
  padding: 0;
  margin: 0;
  height: 100%;
  &.normal{
    height: 100px;
  }
  min-width: 100%;
  &.small{
    height: 60px;
  }
}
.liftworld-textarea-input-filled {
  &:not(:focus-within) {
    border: 2px solid $liftworld-color-neutral-4;
  }
}

.liftworld-textarea-input-label {
  padding-left: 22px;
  padding-bottom: 2px;
  padding-right: 60px;
  color: $liftworld-color-font-primary;
  @include weight("bold");
  font-size: 16px;
}

.liftworld-textarea-input-icon {
  position: relative;
  width: 16px;
  height: 12px;
  right: 40px;
}

.liftworld-textarea-input-field {
  background-color: transparent;
  border: none;
  height: 100%;
  min-width: 100%;
  border-radius: 23px;
  font-size: 16px;
  padding-left: 20px;
  &:focus {
    outline: none;
  }
  &.color 
  {
    background-color: $liftworld-color-neutral-1;
  }
}

.liftworld-textarea-input-field-wrapper {
  min-width: 100%;
  background-color: $liftworld-color-neutral-0;
  border: 1px solid $liftworld-color-neutral-2;
  outline: 1px solid $liftworld-color-neutral-2;
  border-radius: 23px;
  padding-top: 3px;
  flex: 1;
  text-align:left;
  text-align-last: left;
  @include weight("regular");
  font-size: 16px;
  color: $liftworld-color-neutral-6;
  transition: all 0.2s linear;
  height: 100%;
  &:focus-within {
    outline: none;
    border: 2px solid $liftworld-color-font-primary;
    &:not(:read-only) &:not(.liftworld-error) {
      border: 2px solid $liftworld-color-font-primary;
    }
  }
  &.disabled {
    background-color: $liftworld-color-neutral-2;
  }
  &.liftworld-error {
    border: 3px solid $liftworld-color-error;
    color: $liftworld-color-error;
  }

  &.liftworld-correct-info {
    border: 3px solid $liftworld-color-font-primary;
  }
  &.small{
    height: 60px;
  }
  &.normal{
    height: 100px;
  }
}


.liftworld-textarea-input-error-message {
  color: $liftworld-color-error;
  padding-left: 20px;
  padding-top: 5px;
  text-align: left;
  @include weight("bold");
  font-size: 14px;
  color: $liftworld-color-error;
  margin-bottom: 0;
}


.liftworld-textarea-input-size-380 {
  width: 380px;
  margin-bottom: 30px;
}

.liftworld-textarea-input-selected {
  outline: none;
  border: 2px solid $liftworld-color-font-primary;
}
