.delegacoes-editor {
  display: flex;
  flex-direction: column;
  padding-top: 15px;

  > .row {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 10px 0px;

    > .label {
      font-weight: bold;
      width: 250px;
    }
  }

  > .column {
    display: flex;
    flex-direction: column;
    margin: 10px 0px;

    > .label {
      align-self: flex-start;
      font-weight: bold;
      width: 250px;
    }

    > span:not(.label) {
      margin-left: 20px;
    }
  }
}
