.easypay-password-container {
  display: flex;
  position: relative;
  flex-direction: column;
}

.easypay-password-strength-label {
  padding-top: 7px;
  font-weight: 500;
  font-size: 15px;
}

.easypay-password-warning-label {
  font-weight: 300;
  font-size: 12px;
  color: "#BFB8B8";
}
