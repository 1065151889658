@import "variables.scss";

.easypay-tab-button {
  display: flex;
  flex-direction: column;
  margin-right: 10px;
  width: 275px;
  height: 40px;
  @include weight("semi-bold");
  font-size: 16px;
  //padding: 9px 55px;
  border-radius: 20px;
  color: $color-neutral-5;
  background: $color-neutral-0 0% 0% no-repeat padding-box;
  border: solid $color-neutral-0;
  justify-content: center;
  align-items: center;
  &:focus {
    outline: none;
  }
  &.easypay-active,
  &:hover{
    color: $color-neutral-0;
    background: $color-secondary 0% 0% no-repeat padding-box;
    //border: solid $color-secondary;
    border: transparent;
  }
  &.liftworld-active  {
    color: $color-neutral-0;
    background: $color-unikint-standard 0% 0% no-repeat padding-box;    
    //border: solid $color-secondary;
    border: transparent;
  }
  &[disabled] {
    color: $color-neutral-3;
    background: $color-neutral-0 0% 0% no-repeat padding-box;
    border: solid $color-neutral-0;
  }
  &.easypay-separator {
    width: 180px;
  }
}
