.delegacoes-fix {
    overflow: visible;

    > .card-flat {
        overflow: visible !important;
    }

    .card-header {
        padding: 0px !important;
        padding-left: 20px !important;
        margin-bottom: -10px !important;
    }

    .btn-link {
        cursor: pointer;
    }
}