@import "variables.scss";

.easypay-checkbox {
  display: block;
  position: relative;
  padding-left: 30px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}


/* Hide the browser's default checkbox */
.easypay-checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.easypay-checkmark {
  position: absolute;
  top: 0;
  margin-top: 7px;
  left: 0;
  height: 24px;
  width: 24px;
  border: solid $color-neutral-2;
  border-radius: 5px;
  background-color: $color-neutral-0;
  &.disabled {
    background-color: $color-neutral-1;
  }
}

/* When the checkbox is checked, add a blue background */
.easypay-checkbox input:checked ~ .easypay-checkmark {
  background-color: $color-primary;
  &[disabled] {
    background-color: $color-neutral-3;
  }
  border: $color-primary;
}

/* Create the checkmark/indicator (hidden when not checked) */
.easypay-checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.easypay-checkbox input:checked ~ .easypay-checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.easypay-checkmark:after {
  left: 8px;
  top: 3px;
  width: 8px;
  height: 13px;
  border: solid $color-neutral-0;
  border-width: 0 2.5px 2.5px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  &[disabled] {
    border: solid $color-neutral-6;
  }
}

.easypay-checkbox-label {
  font-size: 16px;
  color: $color-neutral-6;
  @include weight("regular");
  margin-left: 2px;
  &[disabled] {
    color: $color-neutral-3;
  }
}

.easypay-checkbox-multiple {
  display: flex;
}

.easypay-checkbox-multiple-label {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  color: $color-neutral-6;
  @include weight("regular");
}
