@import "liftworldVariables.scss";
.liftworld-textbox-main-wrapper {
  display: flex;
  align-items: center;
  padding-right: 10px;
  padding-left: 7px;
  width: fit-content;
}

.liftworld-textbox-title-wrapper {
  align-items: center;
  min-width: fit-content;
  display: flex;
  height: 30px;
  justify-content: flex-start;
  padding-right: 10px;
  color: $liftworld-color-font-primary;
  @include weight("bold");
  font-size: 16px;
  width: fit-content
}

.liftworld-textbox-value-wrapper {
  @include weight("bold");
  font-size: 14px;
  display: flex;
  align-items: center;
  overflow:hidden;
  white-space:nowrap;
  text-overflow:ellipsis;
  background-color: $liftworld-color-neutral-2;
  justify-content: center;
  border-radius: 24px;
  height: 45px;
  border: 1px solid $liftworld-color-neutral-4;
  &.normal {
    width: 150px ;
  }
  &.large {
    width: 750px;
  }
}
