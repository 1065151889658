@import "variables.scss";

.easypay-card {
  width: 100%;
  background-color: $color-neutral-0;
  border-radius: 20px;
  box-shadow: 0px 4px 30px $color-shadow-secondary;
  padding: 60px;
  margin-bottom: 40px;
  min-width: 500px;
}
