.dropdown-menu-inner {
  max-width: 30vw;
  width: 30vw;
}

.mark-as-read {
  font-size: 12px;
  cursor: pointer;
  color: #007bff;
  text-decoration: none;
}

.mark-as-read:hover {
  color: #0056b3;
  text-decoration: underline;
}

.message-container {
  margin-left: 10px;
  font-size: 12px;
}

.empty_notifications {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 2em 0 2em;
}

.empty-notifications-message {
  font-size: 12px;
  color: #bec4ca;
  margin-bottom: 10px;
}

.show-all-notifications {
  font-size: 12px;
  cursor: pointer;
  color: #007bff;
  text-decoration: none;
}

.show-all-notifications:hover {
  color: #0056b3;
  text-decoration: underline;
}

.notification-container {
  display: flex;
  padding: 8px;
}

.notification-container_CMA {
  display: flex;
  padding: 8px;
  background-color: #d9d9d924;
  opacity: 0.6;
}

.notification-container-unread {
  display: flex;
  padding: 8px;
  background-color: #b6dce1;
  border-radius: 10px;
  margin: 1px 0;
}

.notification-container-unread_CMA {
  display: flex;
  padding: 8px;
}

.pending-period {
  text-decoration: underline dotted #0056b3;
}

.modal-notification {
  min-width: 30vw;
}

.scrollBar-visible::-webkit-scrollbar {
  width: 6px;
}

.scrollBar-visible::-webkit-scrollbar-track {
  background: #e2e2f1;
}

.scrollBar-visible::-webkit-scrollbar-thumb {
  background-color: #c1c1c1;
  border-radius: 6px;
  height: 100px;
}

.scrollBar-visible-dropdown::-webkit-scrollbar {
  width: 5px;
}

.scrollBar-visible-dropdown::-webkit-scrollbar-track {
  background: #dadce0;
}

.scrollBar-visible-dropdown::-webkit-scrollbar-thumb {
  background-color: #c1c1c1;
  border-radius: 6px;
  height: 10px;
}