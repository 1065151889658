@import "variables.scss";

.easypay-tab-separator {
  border-radius: 30px 30px 0px 0px;
  background-color: $color-neutral-1;
  padding: 10px 20px;
  &.easypay-active-tab {
    background-color: $color-neutral-0;
    box-shadow: 0px 4px 30px #6d6d6d26;
    clip-path: inset(-30px -30px 0px -30px);
  }
  &.easypay-unactive-tab {
    z-index: 1;
  }
}
