@import "theming.scss";

@mixin widget($theme) {
  .widget-list-wrapper {
    max-height: 105px;
    width: 170px;
    margin: 3rem;
    border: 3px dashed theme-color($theme, "uniksystemColor");
    font-size: 48pt;
    color: theme-color($theme, "uniksystemColor");
    text-align: center;
    border-radius: 20px;
    cursor: pointer;
  }
  .widget-list-wrapper-add {
    max-height: 65px;
    width: 100px;
    margin: 3rem 1rem;
    border: 3px dashed theme-color($theme, "uniksystemColor");
    font-size: 48pt;
    color: theme-color($theme, "uniksystemColor");
    text-align: center;
    border-radius: 20px;
    cursor: pointer;
  }
}
