.iflow-calculos-table {
  .table-head > td {
    font-size: 12px;
    border: 1px solid #dee2e6;
    outline: none;
    font-weight: bold;
    text-align: center;
  }

  .table-content td {
    font-size: 12px;
    border: 2px solid #dee2e6;
    border-bottom: none;
    outline: none;
    text-align: center;
  }

  .table-content > tr:last-of-type {
    border-bottom: 2px solid #dee2e6;
  }

  .header > td {
    margin-top: 20px;
    padding: 10px;
    text-align: center;
  }

  .iflow-form-control {
    display: block;
    width: 100%;
    height: 30px;
    padding: 5px;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

    &:focus {
      color: #495057;
      background-color: #fff;
      border-color: #80bdff;
      outline: 0;
      box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
    }
  }

  .calculos-tooltip {
    position: relative;
    display: inline-block;
    width: 100%;
  }

  .calculos-tooltip .calculos-tooltiptext {
    visibility: hidden;
    max-width: 400px;
    height: 25px;
    background-color: #4c4b4b;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 2px 5px;
    /* Position the tooltip */
    bottom: 100%;
    position: absolute;
    z-index: 1;
    white-space: nowrap;
  }

  .calculos-tooltip:hover .calculos-tooltiptext {
    visibility: visible;
  }
}
