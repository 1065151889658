@import "variables.scss";

.easypay-badge-wrapper {
    display: flex;
    justify-content: center;
    align-content: center;
    background: $color-neutral-5;
    color: $color-neutral-0;
    height: 28px;
    padding-top: 2px;
    @include weight("bold");
    font-size: 18px;
    width: 28px;
    border-radius: 14px;
  }
  