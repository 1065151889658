@import "variables.scss";

.easypay-accordion {
  margin-top: 30px;
  margin-bottom: 30px;
  margin-right: 30px;
  //width: 580px;
  display: flex;
  padding-bottom: 15px;
  flex-direction: column;
  border-bottom: 0.5px solid $color-neutral-2;
  &:hover {
    cursor: pointer;
  }
}

.easypay-accordion-backoffice {
  margin-top: 30px;
  margin-bottom: 30px;
  margin-right: 30px;
  width: 100%;
  display: flex;
  padding-bottom: 15px;
  flex-direction: column;
  border-bottom: 0.5px solid $color-neutral-2;
}

.easypay-accordion-header {
  width: 580px;
  height: 30px;
  display: flex;
  margin-bottom: 15px;
  justify-content: space-between;
  flex-direction: row;
}

.easypay-accordion-header-backoffice {
  width: 100%;
  height: 30px;
  display: flex;
  margin-bottom: 15px;
  justify-content: space-between;
  flex-direction: row;
}

.easypay-accordion-header.easypay-accordion-content-card {
  padding: 20px;
}

.easypay-accordion-top-content-container {
  width: 500px;
  display: flex;
  flex-direction: row;
  height: 28px;
}

.easypay-id {
  @include weight("bold");
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 3px;
  color: $color-neutral-0;
  font-size: 18px;
  background: $color-primary;
  height: 28px;
  min-width: 28px;
  border-radius: 14px;
}

.easypay-title {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  color: $color-neutral-6;
  @include weight("bold");
  padding-top: 4px;
  padding-left: 10px;
  padding-right: 10px;
}

.easypay-image-container {
  display: flex;
}
.easypay-accordion-header-image {
  width: 20px;
  height: 10px;
  margin-top: 10px;
}

.easypay-accordion-checkmark-img {
  padding-right: 15px;
  padding-bottom: 10px;
  height: 40px;
  width: 40px;
}

.easypay-accordion-checkmark-img-no-padding {
  height: 40px;
  width: 40px;
}

.easypay-accordion-warning-img {
  padding-right: 15px;
  height: 40px;
  width: 35px;
}
.easypay-accordion-btn-img {
  height: 10px;
  width: 20px;
  margin-top: 10px;
  &.active {
    transform: rotate(180deg);
  }
}

.easypay-accordion-content-card {
  width: 580px;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 30px;
  padding-bottom: 40px;
  margin-bottom: 10px;
  border-radius: 7px;
  background: $color-neutral-1;
}
.easypay-backoffice-text-button-wrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.easypay-accordion-content-card-backoffice {
  width: 100%;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 30px;
  padding-bottom: 40px;
  margin-bottom: 10px;
  border-radius: 7px;
  background: $color-neutral-1;
}

.easypay-capital-empresa-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.easypay-accordion-radio-button-wrapper {
  padding-bottom: 20px;
  flex-direction: row;
  display: flex;
  &.column{
    flex-direction: column;
  }
}

.easypay-accordion-bottom-button-wrapper {
  outline: none;
  flex: 1;
  flex-direction: row;
  justify-items: end;
  justify-content: flex-end;
  align-items: center;
  display: flex;
  border: none;
}
