@import "variables.scss";

.easypay-custom-checkbox {
  @include weight("semi-bold");
  width: 58px;
  height: 28px;
  input.easypay-toggle-status {
    display: none;
    // Unchecked State
    + label {
      height: 100%;
      width: 100%;
      > .easypay-status-switch {
        cursor: pointer;
        width: 100%;
        height: 100%;
        position: relative;
        background-color: $color-neutral-3;
        color: $color-neutral-0;
        transition: all 0.5s ease;
        padding: 3px;
        border-radius: 14px;
        &[disabled] {
          background-color: $color-neutral-2;
        }
        &:before,
        &:after {
          margin-left: 2px;
          border-radius: 14px;
          height: 22px;
          width: 22px;
          display: flex;
          align-items: center;
          position: absolute;
          justify-content: center;
          transition: all 0.3s ease;
        }

        &:before {
          background-color: $color-neutral-0;
          color: $color-neutral-3;
          left: 3px;
          content: attr(data-unchecked);
        }
        &:after {
          right: 0;
        }
      }
    }

    // Checked state
    &:checked + label > .easypay-status-switch {
      background-color: $color-primary;
      &:after {
        left: 0;
      }
      &:before {
        color: $color-primary;
        left: 50%;
        content: attr(data-checked);
      }
    }
  }
}
