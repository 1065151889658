.portal-landing-page {
  width: 100%;
  min-height: 250px;
  display: flex;
}

.portal-checkin-checkout {
  display: flex;
  width: 15%;
  justify-content: center;
  align-items: center;
}

.portal-checkin-checkout > button {
  padding: 15px 40px;
}

.portal-notifications {
  display: flex;
  width: 50%;
  padding: 2rem 3rem;
  min-height: 260px;
}

.checkin-landing-button {
  min-width: 120px;
  border-radius: 40px !important;
}

.portal-notifications-wrap {
  background-color: #fff;
  box-shadow: 0px 4px 30px #6d6d6d26;
  min-height: 17rem;
  width: 30rem;
  border-radius: 23px;
  padding: 30px;
}
.portal-notifications-header {
  display: flex;
  justify-content: space-between;
  padding: 5px;
}
.portal-notifications-header-text {
  font-size: 22px;
  margin-left: 6px;
  float: left;
  color: #4484f4;
}
.portal-notifications-header-mark {
  margin-top: 5px;
  font-size: 14px;
  color: black;
  margin-right: 6px;
  float: right;
  text-align: center;
  height: 100%;
}

.portal-notifications-header-mark:hover {
  cursor: pointer;
  color: #3a6cc2;
}

.portal-notifications-body {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 160px;
}
.portal-notifications-body-text {
  color: #212529;
  font-size: 16px;
  margin-bottom: 32.8px;
}

/*Document management*/
.portal-image-upload-icon {
  font-size: 40px;
  margin-left: 40%;
  border: 1px solid #b5b5b5;
  color: #9e9d9d;
  border-radius: 45px;
  background: white;
  padding: 15px;
}
.portal-image-upload-icon-active {
  font-size: 40px;
  margin-left: 40%;
  border: 1px dotted #efefef;
  border-radius: 45px;
  color: #3a6cc2;
  padding: 15px;
}
.portal-image-upload-icon-active:hover {
  border: 1px solid #b5b5b5;
  border-radius: 45px;
  background: white;
}

.portal-label-main {
  padding-top: 20px;
  font-weight: bold;
  color: #9e9d9d;
}

.portal-label-main-active {
  padding-top: 20px;
  font-weight: bold;
  color: #3a6cc2;
}

.portal-image-preview-one {
  height: 100px;
  width: 100px;
  object-fit: contain;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.portal-image-preview-multiple {
  height: 80px;
  width: 80px;
  object-fit: contain;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.search-wrapper {
  margin-right: 10px;
  .search-label {
    width: 100%;
  }
}

.portal-upload-select-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.portal-document-preview {
  width: 100%;
  height: auto;
}

.slide-in-bottom {
  -webkit-animation: slide-in-bottom 0.3s ease-out;
  animation: slide-in-bottom 0.3s ease-out;
}
/* ----------------------------------------------
 * Generated by Animista on 2021-7-13 16:45:31
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-in-bottom
 * ----------------------------------------
 */
@-webkit-keyframes slide-in-bottom {
  0% {
    -webkit-transform: translateY(30px);
    transform: translateY(30px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes slide-in-bottom {
  0% {
    -webkit-transform: translateY(30px);
    transform: translateY(30px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}
