@import "variables.scss";

.easypay-backoffice-client-section-input-wrapper{
    width: 48%;
}

.easypay-backoffice-client-section-columns-wrapper{
    display: flex;
    width: 100%;
    justify-content: space-between;
    flex-direction: row;
}

.easypay-backoffice-client-section-column-wrapper{
    display: flex;
    width: 48%;
    justify-content: space-between;
    flex-direction: row;
}

.easypay-backoffice-client-section-country-mobile-wrapper{
    width:48%;
    justify-content: space-between;
    display: flex;
    flex-direction: row;
}

.easypay-backoffice-client-section-small-input-wrapper{
    width: 48%;
}

.easypay-backoffice-client-blue-header{
    @include weight("bold");
    color: $color-primary;
    padding-bottom: 20px;
}

.easypay-backoffice-client-section-small-header{
    @include weight("bold");
}

.easypay-backoffice-client-small-wrapper{
    padding-bottom: 20px;
}

.easypay-backoffice-link-wrapper{
    padding-bottom: 20px;
}

.easypay-backoffice-country-falag-text-wrapper{
    display: flex;
    flex-direction: row;
}

.easypay-backoffice-host-country-text{
    padding-left: 5px;
}