.onboarding-button-wrap > Button {
  margin: 25px;
}
.onboarding-form-header {
  margin-bottom: 25px;
  font-size: 20px;
}

.onboarding-test-form {
  margin-bottom: 25px;
}

.onboarding-output {
  margin-top: 25px;
}

.output-textarea {
  background-color: white !important;
  margin-bottom: 25px;
  resize: vertical !important;
}

.onboarding-button {
  min-width: 105px;
}

.onboarding-layout-wrapper {
  margin-top: 10px;
}
.onboarding-iframe {
  width: 100%;
  height: 100%;
}

.onboarding-iframe-wrap {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
}

.onboarding-iframe-pdf-layout {
  margin-bottom: 12px;
  height: 80%;
  width: 100%;
  outline: 1px solid black;
}
.onboarding-a4-proportions{
  height: calc(20vw *1.41);
  width: 20vw;
  outline: 1px solid black;
  margin-top: 10px;
}
