@import "variables.scss";

.easypay-pagination-container {
  display: flex;
  flex-direction: row;
  margin: 30px;
}

.easypay-pagination-left-img-container {
  width: 32px;
  display: flex;
  padding-top: 8px;
  margin-top: 6.5px;
  height: 32px;
  background: #ffffff;
  justify-content: center;
  align-content: center;
  border-radius: 20px;
  border: 1.5px solid $color-neutral-2;
  &.disabled {
    border: none;
    background: $color-neutral-1;
  }
}

.easypay-pagination-left-arrow {
  height: fit-content;
  margin-top: 2px;
  margin-right: 2px;
  width: fit-content;
  &.active {
    transform: rotate(90deg);
  }
  &.disabled {
    transform: rotate(-90deg);
  }
}

.easypay-pagination-right-img-container {
  width: 32px;
  margin-left: 5px;
  margin-top: 6.5px;
  padding-top: 8px;
  display: flex;
  height: 32px;
  background: #ffffff;
  justify-content: center;
  align-content: center;
  border-radius: 20px;
  border: 1.5px solid $color-neutral-2;
  &.disabled {
    border: none;
    background: $color-neutral-1;
  }
}

.easypay-pagination-right-arrow {
  height: fit-content;
  margin-top: 2px;
  margin-left: 2px;
  width: fit-content;
  &.active {
    transform: rotate(-90deg);
  }
  &.disabled {
    transform: rotate(90deg);
  }
}

.easypay-pagination-badge {
  margin-top: 8px;
  height: 28px;
  margin-left: 5px;
  justify-content: center;
  align-content: center;
  padding-top: 2px;
  display: flex;
  @include weight("regular");
  font-size: 14px;
  flex-direction: row;
  width: 28px;
  border-radius: 20px;
  &.selected {
    color: #ffffff;
    background: $color-primary;
    border: 1.5px solid $color-primary;
  }
  &.not-selected {
    background: #ffffff;
    border: 1.5px solid $color-neutral-2;
    color: $color-neutral-4;
  }
  &:hover {
    cursor: default;
  }
}

.easypay-right-pagination-ellipsis {
  margin-top: 5px;
  margin-left: 5px;
  color: $color-neutral-4;
  @include weight("regular");
  &:hover {
    cursor: default;
  }
}
