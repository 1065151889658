@import "variables.scss";

.easypay-tab {
  display: flex;
  flex-direction: column;
  height: 40px;
  @include weight("bold");
  font-size: 18px;
  color: $color-neutral-5;
  background: $color-neutral-0 0% 0% no-repeat padding-box;
  justify-content: center;
  align-items: center;
  border: none;
  border-bottom: 3px solid $color-neutral-3;
  &:focus {
    outline: none;
  }
  &.easypay-active,
  &:hover {
    color: $color-primary;
    background: $color-neutral-0 0% 0% no-repeat padding-box;
    border-bottom: 3px solid $color-primary;
  }
  &[disabled] {
    color: $color-neutral-3;
    border-bottom: 3px solid $color-neutral-3;
  }
}
