@import "../../pages/Liftworld/assets/scss/liftworldVariables.scss";

//css of manually created divs
.element-input-date-range {
  display: flex;
  flex-direction: column;
  position: absolute;
  justify-content: space-between;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 10px #6d6d6d40;
  border-radius: 11px;
  z-index: 1;
  margin-top: 55px;
}

.element-input-date-bottom {
  display: flex;
  align-items: center;
  height: 85px;
}

.element-input-date-predefined-ranges {
  padding-top: 30px;
  padding-left: 20px;
  padding-bottom: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  width: 160px;
  border-right: 1px solid $liftworld-color-neutral-2;
  opacity: 1;
}
.element-input-date-top {
  display: flex;
  height: 270px;
  border-bottom: 1px solid $liftworld-color-neutral-2;
  opacity: 1;
}
.element-input-date-main-calendar {
  display: flex;
  width: 502px;
  height: 268px;
  padding-left: 85px;
}
.element-input-date-predefined-button {
  &:focus {
    outline: none;
    color: $liftworld-color-font-primary;
  }
  &:hover {
    color: $liftworld-color-font-primary;
  }
  text-align: left;
  @include weight("semi-bold");
  font-size: 14px;
  color: $liftworld-color-neutral-4;
  outline: none;
  justify-content: center;
  width: 100px;
  height: 18px;
  align-items: center;
  border: 0;
  display: block;
  padding: 0;
  background: $liftworld-color-neutral-0;
  white-space: nowrap;
}
.element-input-date-predefined-option {
  outline: none;
  width: 160px;
}

.element-input-selected-dates {
  display: flex;
  width: 200px;
  align-items: center;
}
.element-input-date-link {
  display: flex;
  align-items: center;
  padding: 16px;
  .element-input-link-primary {
    width: 63px;
    height: 21px;
    opacity: 1;
    text-align: left;
    color: $liftworld-color-font-primary;
    .element-input-icon-less {
      display: none;
    }
  }
}

.element-input-date-buttons {
  display: flex;
  flex-direction: row;
  margin-left: auto;
}

.element-input-span-selected-dates {
  //font-family: arial;

  width: 180px;
  height: 18px;

  float: left;

  opacity: 1;
  text-align: center;
  @include weight("semi-bold");
  font-size: 14px;
  color: $liftworld-color-neutral-6;
}

.element-input-date-select-button {
  padding: 16px;
  .element-input-button-primary {
    width: 108px;
    height: 45px;
    padding: 0;

    text-align: center;
    @include weight("semi-bold");
    font-size: 16px;
    color: $liftworld-color-neutral-0;
    opacity: 1;
  }
}
