@import "theming.scss";

@mixin cardv2($theme) {
  .card-flat {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    color: theme-color($theme, "textColor");
    background-color: theme-color($theme, "backgroundColor");
    padding: 1.4rem 2rem;

    @if $theme ==dark {
      border: 1.5px solid black;
    }

    overflow: hidden;
    border-radius: theme-color($theme, "borders");
    box-shadow: 0px 4px 30px #6d6d6d26;

    >.card-header {
      padding: 10px 0px 20px 0px;

      >h6 {
        padding: 0px;
      }
    }
  }

  @media (max-width: 576px) {

    // 576px é o tamanho padrão para mobile no Bootstrap
    .form-row>[class*=col-] {
      flex-flow: column !important;
    }

    .react-bootstrap-table {
      overflow-x: auto;
    }

    .react-bootstrap-table-pagination-total {
      flex: 1 0 100%;
    }

    .table tbody tr td {
      align-content: center;
    }

    //     .rbc-calendar {
    //   display: block;
    //   height: auto !important;
    // }

    // .rbc-time-view {
    //   display: block;
    //   overflow-x: scroll;
    //   height: auto !important;
    // }

    // .rbc-time-content {
    //   min-width: 1000px;
    // }

    // .rbc-time-header {
    //   display: none;
    // }
  }

  #dropdown-basic {
    background-color: theme-color($theme, "cardButtonBackgroundColor");
    border: 0px;
    border-radius: theme-color($theme, "inputBorder") !important;
    padding: 6px 18px;
    min-height: 35px;
    width: auto;
    height: 35px !important;
    color: white;
  }

  .row {
    margin: 0px;
  }

  .card {
    background-color: theme-color($theme, "backgroundColor");
  }

  .card-header {
    display: flex;
    margin-bottom: 0px;
    padding: 8px;
    border-radius: 0;
    border-bottom: 0;
    color: theme-color($theme, "textColor");
    background-color: theme-color($theme, "cardBackgroundColor");

    &.card-header-toggle {
      background-color: #f1f1f1;

      cursor: pointer;

      &:hover {
        background-color: #e7e7e7;
      }
    }

    .dropdown-text {
      padding: 10px;
      font-size: 13px;
      font-weight: 400;
      min-width: 150px;
      color: theme-color($theme, "textColor");
    }

    .dropdown-toggle {
      color: theme-color($theme, "textColor");
    }

    .dropdown-menu {
      color: theme-color($theme, "dropdownMenuTextColor");
      background-color: theme-color($theme, "dropdownMenuBackgroundColor");

      @if $theme ==dark {
        border-color: #000;
      }

      text-decoration: none;
      border-radius: 0;
    }

    .dropdown-item {
      color: theme-color($theme, "dropdownItemColor");
      background-color: theme-color($theme, "dropdownItemBackgroundColor");
    }

    .dropdown-item:hover {
      color: theme-color($theme, "dropdownItemHover");
      background-color: theme-color($theme, "dropdownItemBackgroundColorHover");
    }
  }

  .btn {
    border: 0px;
    border-radius: theme-color($theme, "inputBorder") !important;
    padding: 6px 18px;
    min-height: 35px;
  }

  .card-button {
    background-color: theme-color($theme, "cardButtonBackgroundColor");
    border: 0px;
    border-radius: theme-color($theme, "inputBorder") !important;
    padding: 6px 18px;
    min-height: 35px;
    width: auto;
    height: 35px !important;
  }

  .card-button-expenses {
    margin-bottom: 1rem;
    width: 100%;
    background-color: #4c4b4b;
    padding: 6px 18px;
    min-height: 35px;
    border: 0px;
    border-radius: theme-color($theme, "inputBorder") !important;
    height: 35px !important;
  }

  .card-button-expenses:focus {
    background-color: #4c4b4b !important;
  }

  .card-button-red {
    background-color: red;
    border: 0px;
    border-radius: theme-color($theme, "inputBorder") !important;
    padding: 6px 18px;
    min-height: 35px;
    height: 35px !important;
  }

  .card-button-green {
    background-color: green;
    border: 0px;
    border-radius: theme-color($theme, "inputBorder") !important;
    padding: 6px 18px;
    min-height: 35px;
    height: 35px !important;
  }

  .card-button-secondary {
    background-color: #6c757d;
    border: 0px;
    border-radius: theme-color($theme, "inputBorder") !important;
    padding: 6px 18px;
    min-height: 35px;
    height: 35px !important;
  }

  .card-button-orange {
    background-color: orange;
    border: 0px;
    border-radius: theme-color($theme, "inputBorder") !important;
    padding: 6px 18px;
    min-height: 35px;
    height: 35px !important;
  }

  .card-button-all {
    background-color: theme-color($theme, "cardButtonBackgroundColor");
    border: 0px;
    border-radius: theme-color($theme, "inputBorder") !important;
    padding: 6px 18px;
    min-height: 35px;
    height: 35px !important;
  }

  .card-button-all.active {
    opacity: 1;
    background-color: theme-color($theme, "cardButtonBackgroundColor") !important;
  }

  .card-outlined-button.groups {
    height: 25px !important;
    min-height: 0px !important;
    font-size: small !important;
    padding: 0px 18px !important;
    margin: 4px !important;
  }

  .card-outlined-button.active {
    background-color: #3870ab80 !important;
    border-color: #3870ab80 !important;
  }

  .button-scheduler.active {
    opacity: 1;
    background-color: theme-color($theme, "cardButtonBackgroundColor") !important;
    border: 0px;
    border-radius: theme-color($theme, "inputBorder") !important;
    padding: 6px 18px;
    min-height: 35px;
    width: auto;
    height: 35px !important;
  }

  .button-scheduler {
    margin: 10px 10px 10px 0px
  }

  .btnstocleanandprint {
    margin-top: 0.6rem;
  }

  .stackButtonsGroup {
    display: inline-block;
    max-height: 130px;
    overflow-y: auto;
    margin-top: 10px;
  }

  .stackButtonsGroup::-webkit-scrollbar {
    width: 6px;
  }

  .stackButtonsGroup::-webkit-scrollbar-track {
    background: #dadce0;
  }

  .stackButtonsGroup::-webkit-scrollbar-thumb {
    background-color: #c1c1c1;
    border-radius: 6px;
    height: 10px;
  }

  .card-button-all:not(.active) {
    opacity: 0.3;
  }

  .button-scheduler:not(.active) {
    opacity: 0.5;
  }

  .card-button-red.active {
    opacity: 1;
    background-color: red !important;
  }

  .card-button-red:not(.active) {
    opacity: 0.3;
  }

  .card-button-green.active {
    opacity: 1;
    background-color: green !important;
  }

  .card-button-green:not(.active) {
    opacity: 0.3;
  }

  .card-button-secondary.active {
    opacity: 1;
    background-color: #6c757d !important;
  }

  .card-button-secondary:not(.active) {
    opacity: 0.3;
  }

  .card-button-orange.active {
    opacity: 1;
    background-color: orange !important;
  }

  .card-button-orange:not(.active) {
    opacity: 0.3;
  }

  .card-button-10 {
    background-color: theme-color($theme, "cardButtonBackgroundColor");
    border: 0px;
    border-radius: 10px !important;
    padding: 6px 18px;
    min-height: 35px;
  }

  .card-button-10:hover {
    background-color: theme-color($theme,
        "cardButtonBackgroundColorHover"
      ) !important;
    border: 0px;
  }

  .card-button-10NoHeight {
    background-color: theme-color($theme, "cardButtonBackgroundColor");
    border: 0px;
    border-radius: 10px !important;
    padding: 6px 18px;
  }

  .collapse.show {
    border-left: 3px solid theme-color($theme, "uniksystemColor") !important;
    margin-bottom: 1.5rem;
    padding-left: 1rem;
  }

  .accordionWithoutBorder.collapse.show {
    border-left: 0px !important;
    margin-bottom: 1.5rem;
    padding-left: 1rem;
  }

  .card-button-10NoHeight:hover {
    background-color: theme-color($theme,
        "cardButtonBackgroundColorHover"
      ) !important;
    border: 0px;
  }


  .card-button:hover {
    background-color: theme-color($theme,
        "cardButtonBackgroundColorHover"
      ) !important;
    border: 0px;
  }

  .card-button-red:hover {
    background-color: brown !important;
    border: 0px;
  }

  .card-button-green:hover {
    background-color: darkgreen !important;
    border: 0px;
  }

  .card-button-secondary:hover {
    background-color: #4d5358 !important;
    border: 0px;
  }

  .card-button-orange:hover {
    background-color: chocolate !important;
    border: 0px;
  }

  .card-outlined-button {
    border-radius: theme-color($theme, "inputBorder") !important;
    padding: 6px 18px;
    min-height: 35px;
  }

  .card-outlined-button {
    background-color: theme-color($theme, "backgroundColor");
    border: 2px solid theme-color($theme, "cardButtonBackgroundColor");
    color: theme-color($theme, "cardButtonBackgroundColor");
  }

  .card-outlined-button:hover {
    background-color: theme-color($theme, "cardButtonBackgroundColor");
    color: #fff;
  }

  .card-outlined-button-10 {
    border-radius: 15px !important;
    padding: 6px 18px;
    min-height: 42px;
    background-color: theme-color($theme, "backgroundColor");
    border: 2px solid theme-color($theme, "cardButtonBackgroundColor");
    color: theme-color($theme, "cardButtonBackgroundColor");
  }

  .card-outlined-button-10:hover {
    background-color: theme-color($theme, "cardButtonBackgroundColor");
    color: #fff;
  }

  .card-footer {
    display: flex;
    justify-content: flex-end;

    @if $theme ==dark {
      border-top: 1px solid black;
    }

    border: 0;
    background-color: theme-color($theme, "cardBackgroundColor");
  }

  h6 {
    display: flex;
    margin-bottom: 0px;
    padding: 8px;
    background-color: theme-color($theme, "cardBackgroundColorV2");
    color: theme-color($theme, "textColor");
    border-radius: 0px;
    font-size: 1.2em;
  }

  .card-header h6 {
    color: theme-color($theme, "h6TextColor");
    font-weight: bold;
    font-size: 1.45em;
  }

  .card-header h6.h6old {
    display: flex;
    margin-bottom: 0px;
    padding: 8px;
    background-color: theme-color($theme, "cardBackgroundColorV2");
    color: theme-color($theme, "textColor");
    border-radius: 0px;
    font-size: 1.2em;
    font-weight: 500;
  }

  .list-group-item {
    color: theme-color($theme, "textColor");
    background-color: theme-color($theme, "backgroundColor");
  }

  .card-mini {
    display: flex;
    flex-direction: column;
    color: theme-color($theme, "textColor");
    background-color: theme-color($theme, "backgroundColor");
    border: 1px solid theme-color($theme, "cardBorder");
    overflow: hidden;
    margin: 10px;
    flex-grow: 1;
    flex-basis: 0;
  }

  .btn-group {
    .btn-success {
      color: #fff;
    }

    .btn-danger {
      color: #fff;
    }
  }

  .btn-danger {
    background-color: theme-color($theme, "buttonDanger");
    border-color: theme-color($theme, "buttonDanger");
  }

  .btn-danger:hover {
    background-color: theme-color($theme, "buttonDangerHover");
    border-color: theme-color($theme, "buttonDangerHover");
  }

  .btn-success {
    background-color: theme-color($theme, "buttonSuccess");
    border-color: theme-color($theme, "buttonSuccess");
  }

  .btn-success:hover {
    background-color: theme-color($theme, "buttonSuccessHover");
    border-color: theme-color($theme, "buttonSuccessHover");
  }

  .alert-danger {
    color: theme-color($theme, "alertDangerColor");
    background-color: theme-color($theme, "alertDangerBackgroundColor");
    border-color: theme-color($theme, "alertDangerBackgroundColor");
  }

  .alert-info {
    color: theme-color($theme, "alertInfoColor");
    background-color: theme-color($theme, "alertInfoBackgroundColor");
    border-color: theme-color($theme, "alertInfoBorder");
  }

  .error-message {
    color: theme-color($theme, "buttonDanger");
  }

  .badge-success {
    background-color: theme-color($theme, "buttonSuccess");
    border-color: theme-color($theme, "buttonSuccess");
    color: theme-color($theme, "badgeSuccessColor");
    border-radius: theme-color($theme, "inputBorder") !important;
  }

  .badge-danger {
    background-color: theme-color($theme, "buttonDanger");
    border-color: theme-color($theme, "buttonDanger");
    color: theme-color($theme, "badgeSuccessColor");
    border-radius: theme-color($theme, "inputBorder") !important;
  }

  .form-control {
    border-radius: theme-color($theme, "inputBorder") !important;
    padding: 0.25rem 1rem !important;

    &.form-suffix {
      border-radius: theme-color($theme, "inputBorder") !important;
    }

    &.form-table {
      border-radius: 0.25rem !important;
    }

    &.react-datetime-picker--disabled {
      background-color: #e9ecef !important;
      height: 35px !important;
    }
  }

  .dateTimePickerClass {
    padding: 0px !important;

    .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-formControl.MuiInputBase-adornedEnd {
      height: 35px;
      border-radius: 23px !important;
    }

    .Mui-disabled {
      background-color: #e9ecef !important;
      height: 0px;
    }
  }

  input[type="time"]::-webkit-calendar-picker-indicator {
    display: none;
    background: none;
  }

  .react-calendar {
    box-shadow: 0px 3px 10px #6d6d6d40;
    border-radius: theme-color($theme, "inputBorder") !important;
    border: none !important;
  }

  .text-primary-color {
    color: theme-color($theme, "uniksystemColor");
  }
}

.main-card-v2 {
  width: 100%;
  height: auto;
  position: relative;
  display: flex;
  overflow: hidden;
  justify-content: center;
  border-radius: 20px;
  padding: 1rem;

}

.scrollBar-visible::-webkit-scrollbar {
  width: 6px;
}

.scrollBar-visible::-webkit-scrollbar-track {
  background: #dadce0;
}

.scrollBar-visible::-webkit-scrollbar-thumb {
  background-color: #c1c1c1;
  border-radius: 6px;
  height: 10px;
}


.scrollBar-visible-dropdown::-webkit-scrollbar {
  width: 5px;
}

.scrollBar-visible-dropdown::-webkit-scrollbar-track {
  background: #dadce0;
}

.scrollBar-visible-dropdown::-webkit-scrollbar-thumb {
  background-color: #c1c1c1;
  border-radius: 6px;
  height: 10px;
}

h6.MuiTypography-root {
  padding: 0px !important;
}

p.MuiTypography-root {
  color: rgb(39, 39, 39);
}

.MuiTextField-root {
  width: 100%;
}

.eventSpan {
  font-size: x-small;
  font-weight: bold;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.event:hover {
  background-color: lightgray;
  /* Por exemplo, altere a cor de fundo */
  border: 2px solid darkgray;
  /* Altere a borda do evento */
  color: black;
  /* Altere a cor do texto */
}

/* Adicione estilos específicos para a descrição do evento no hover */
.event:hover .event-desc {
  font-weight: bold;
  /* Altere o estilo da descrição do evento no hover */
}

.horarioEventClass {
  left: 0% !important;
  right: auto !important;
  width: 50% !important;
}

.registoEventClass {
  right: 0% !important;
  left: auto !important;
  width: 50% !important;
}

.MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-formControl.MuiInputBase-adornedEnd {
  border-radius: 40px;
  border: 1px solid #ced4da;
}

.checkoutDatepicker .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-formControl.MuiInputBase-adornedEnd {
  border-radius: 23px !important;
  border: 1px solid #ced4da;
  height: 44px !important;
}

.MuiInputBase-input.MuiOutlinedInput-input.MuiInputBase-inputAdornedEnd {
  padding: 12.5px 12px;
  color: #4c4b4b;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
}

.MuiOutlinedInput-notchedOutline {
  border: none ! important;
}