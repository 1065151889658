@import "variables.scss";

.easypay-chip {
  display: flex;
  height: 40px;
  background: $color-primary-light;
  width: fit-content;
  border-radius: 20px;
  justify-content: center;
}

.easypay-onboarding-step {
  display: flex;
  height: 40px;
  padding-left: 15px;
  padding-right: 15px;
  width: fit-content;
  justify-content: center;
  padding-top: 6px;
  border-radius: 20px;
  background: #ffffff;
  border: 1px solid $color-neutral-2;
}

.easypay-onboarding-step-text {
  font-style: normal;
  justify-content: center;
  align-content: center;
  padding-top: 2px;
  @include weight("semi-bold");
  color: $color-neutral-5;
}

.easypay-tag-label {
  display: flex;
  height: 40px;
  padding-left: 10px;
  padding-right: 10px;
  width: fit-content;
  justify-content: center;
  padding-top: 8px;
  border-radius: 20px;
  &.easypay-approved {
    background: $color-success-light;
  }
  &.easypay-denied {
    background: $color-error-light;
  }
  &.easypay-evaluation {
    background: $color-neutral-2;
  }
}

.easypay-tag-label-text {
  font-style: normal;
  justify-content: center;
  align-content: center;
  margin-left: 10px;
  @include weight("semi-bold");
  padding-top: 2px;
  &.easypay-approved {
    color: $color-success;
  }
  &.easypay-denied {
    color: $color-error;
  }
  &.easypay-evaluation {
    color: $color-neutral-5;
  }
}

.easypay-chip:hover {
  background: $color-chip-hover;
}

.easypay-tag-large {
  display: flex;
  height: 48px;
  background: $color-primary-light;
  width: fit-content;
  border-radius: 24px;
  justify-content: center;
}

.easypay-tag {
  display: flex;
  background: $color-primary-light;
  width: fit-content;
  justify-content: center;
  align-items: center;
  &.easypay-small {
    border-radius: 11px;
    height: 20px;
  }
  &.easypay-normal {
    height: 28px;
    border-radius: 14px;
  }
  &.easypay-large {
    height: 48px;
    border-radius: 24px;
  }
}

.easypay-tag-small {
  display: flex;
  height: 20px;
  background: $color-primary-light;
  width: fit-content;
  border-radius: 20px;
  justify-content: center;
}

.easypay-chip-text {
  color: $color-primary;
  @include weight("semi-bold");
  font-size: 16px;
  padding-top: 8.5px;
  padding-left: 15px;
  padding-right: 20px;
}

.easypay-text-section {
  justify-content: center;
  align-content: center;
  color: $color-primary;
  @include weight("semi-bold");
  &.easypay-small {
    font-size: 9px;
    padding-left: 8px;
    padding-right: 8px;
    padding-top: 1px;
  }
  &.easypay-normal {
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 2px;
    font-size: 14px;
    white-space: nowrap;
  }
  &.easypay-large {
    padding-left: 16px;
    padding-top: 3px;
    padding-right: 16px;
    font-size: 22px;
  }
}

.easypay-chip-img-section {
  padding-top: 6px;
  padding-right: 15px;
}
