@import "variables.scss";

.easypay-backoffice-document-request-header{
    width: 100%;
    padding-top: 37px;
    padding-bottom: 40px;
    padding-left: 140px;
    padding-right: 140px;
    background: $color-background-light;
    display: flex;
}

.easypay-backoffice-document-request-section{
    padding-top: 100px;
    width: 100%;
    height: 100%;
    background: $color-neutral-0;
}

.easypay-backoffice-text-header-title{
    padding-top: 30px;
    font-size: 30px;
    @include weight("bold");
}

.easypay-backoffice-request-document-section-content-wrapper{
    height: 100%;
    width: 100%;
    padding-left: 140px;
    padding-right: 140px;
    padding-bottom: 90px;
}

.easypay-backoffice-request-document-buttons-wrapper{
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
}

.easypay-backoffice-request-document-table-item-wrapper{
    padding-top: 30px;
    padding-bottom: 30px;
    padding-left: 50px;
}

.easypay-backoffice-requst-document-add-document-wrapper{
    display: flex;
    flex-direction: row;
    align-items: center;
}

.easypay-backoffice-request-document-input-wrapper{
    width: 400px;
    padding-right: 20px;
}

.easypay-backoffice-small-input-wrapper-fixed{
    width: 280px;
}