@import "variables.scss";

.easypay-backoffice-subheader-wrapper {
  display: flex;
  position: fixed;
  flex-direction: row;
  z-index: 1;
  top: 100px;
  padding-left: 80px;
  padding-top: 40px;
  padding-right: 80px;
  background: $color-neutral-0;
  justify-content: space-between;
  width: 100%;
}

.easypay-backoffice-subheader-labels-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-bottom: 30px;
  justify-content: space-evenly;
}

.easypay-backoffice-onboarding-step-label-wrapper {
  padding-left: 20px;
  padding-right: 20px;
}

.easypay-backoffice-subheader-name {
  @include weight("bold");
  font-size: 32px;
  padding-top: 42px;
  padding-bottom: 21px;
}

.easypay-backoffice-subheader-right-buttons-wrapper {
  display: flex;
  flex-direction: row;
  padding-bottom: 57px;
  justify-content: flex-end;
}

.easypay-backoffice-subheader-right-dropdown-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
.easypay-backoffice-subheader-right-dropdown-wrapper {
  padding-left: 20px;
}

.easypay-backoffice-subheader-right-button-wrapper {
  padding-left: 20px;
}
