@import "variables.scss";

.easypay-single-accordion {
  width: 1200px;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translateX(-25%);
  display: flex;
  padding: 15px 30px;
  margin-bottom: 30px;
  flex-direction: column;
  background-color: $color-neutral-1;
  border-radius: 10px;
}

.easypay-single-accordion-header {
  width: 1200px;

  height: 30px;
  display: flex;
  margin-bottom: 15px;
  justify-content: space-between;
  flex-direction: row;
}

.easypay-single-accordion-content-card {
  padding-left: 20px;
}

.easypay-single-accordion-top-content-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  height: 28px;
}

.easypay-single-title {
  display: flex;
  width: 100%;
  padding-top: 12px;
  padding-left: 30px;
}

.easypay-single-image-container {
  display: flex;
  padding-right: 30px;
  padding-top: 8px;
}
.easypay-single-accordion-header-image {
  width: 20px;
  height: 10px;
  margin-top: 12px;
}

.easypay-single-accordion-checkmark-img {
  padding-right: 15px;
  padding-bottom: 10px;
  height: 40px;
  width: 40px;
}

.easypay-single-accordion-btn-img {
  height: 10px;
  width: 20px;
  margin-top: 10px;
  &.active {
    transform: rotate(180deg);
  }
}

.easypay-single-accordion-content-card {
  width: 1200px;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 30px;
  padding-bottom: 40px;
  margin-bottom: 10px;
  border-radius: 7px;
  background: $color-neutral-1;
}
