@import "variables.scss";

.easypay-tab-wrapper {
  display: flex;
  padding: 10px 0px 10px 10px;
  width: fit-content;
}

.easypay-tab-content {
  text-align: left;
  @include weight("semi-bold");
  font-size: 16px;
  width: 580px;
  margin: 30px;
}
