.right-sidebar-expand .right-sidebar {
    transform: translateY(0);
}

.right-sidebar {
    position: fixed;
    top: 3.71rem;
    right: 0;
    width: 14rem;
    background: #fff;
    height: calc(100vh - 3.75rem);
    border-left: 1px solid #ccd2da;
    transform: translateX(100%);
    padding: 0 1.07143em 1.07143em;
    z-index: 9;
}

.sidebar-chat {
    z-index: 9999999;
    margin-top: 1.875rem;
}

.sidebar-chat-info {
    color: #000;
    font-size: 0.92857em;
}

.right-sidebar .sidebar-title {
    font-size: 1em;
    letter-spacing: .15em;
    text-transform: uppercase;
}

.sidebar-chat .list-group {
    margin-left: -1.07143em;
    margin-right: -1.07143em;
}

.list-group {
    display: flex;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
}

.sidebar-chat .list-group-item {
    background: none;
    border: 0;
    padding: 1em 1.07143em;
    overflow: hidden;
    color: #666;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.sidebar-chat .list-group-item figure {
    margin-bottom: 0;
}

[class*="user--"] {
    position: relative;
}
.sidebar-chat .list-group-item > span {
    line-height: 1em;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
}

.sidebar-chat .list-group-item .name {
    font-size: 0.92857em;
    color: #666;
    font-weight: 500;
    top: 0.69231em;
    margin-bottom: 0.38462em;
    margin-top: 0.30769em;
}

.sidebar-chat .list-group-item .username {
    color: #5a5a5a;
    opacity: 0.7;
    font-size: 0.85714em;
    top: -0.08333em;
}

.thumb-xs img, .thumb-xs .user-char-image {
    height: 40px;
    width: 40px;
    line-height: 40px;
}

.user-rounded-circle {
    border-radius: 50% !important;
}

.sidebar-chat [class*="user-"]:after {
    position: absolute;
    top: calc(50% + 15px);
    left: 0;
    transform: translateY(-50%);
}

[class*="user--"][class*="thumb-xs"]::after {
    right: 0.25rem;
}

.user--online:after {
    background: #4be1ab;
}

[class*="user--"]::after {
    height: 0.64286em;
    width: 0.64286em;
    display: block;
    position: absolute;
    top: 0;
    right: 10%;
    border-radius: 100px;
    content: "";
    box-shadow: 0 0 0 2px #fff;
}

.empty-label {
    text-align: center;
    color: #666;   
}