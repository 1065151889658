.right-sidebar-expand .chat-panel {
  transform: translateY(-100%) translateX(0);
}

.chat-panel {
  position: fixed;
  top: calc(100vh);
  right: calc(14.0625rem + 15px);
  width: 24.28571em;
  transform: translateY(-100%) translateX(14.0625rem);
  z-index: 9999;
}

.chat-panel .chat {
  border: 1px solid #ccd2da;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1);
}

.chat {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid #ccd2da;
  border-radius: 0.25rem;
}

.chat-panel .chat-header {
  background: none;
  padding: 1.07143em 0.4em;
  font-size: 1.14286em;
  border-bottom: 1px solid rgba(153, 153, 153, 0.2);
}

.chat-header:first-child {
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}

.text-success {
  color: #4484f4 !important;
}

.chat-panel .user-name {
  color: #000;
}
.fw-400 {
  font-weight: 400 !important;
}
.flex-1 {
  flex: 1 !important;
}

.close:not(:disabled):not(.disabled) {
  cursor: pointer;
}

.chat-panel .chat-header button {
  cursor: pointer;
}

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
  -webkit-appearance: none;
}

//body
.chat-panel .chat-body {
  padding: 1.07143em;
}
.chat-body {
  z-index: 1;
  flex: 1 1 auto;
  padding: 1.25rem;
  min-height: 280px;
  max-height: 280px;
  overflow-y: scroll;
}

.chat-panel .messages {
  position: relative;
  margin-top: -1.07143em;
  margin-right: -1.07143em;
  margin-left: -1.07143em;
  padding-top: 1.07143em;
  padding-right: 1.07143em;
  padding-left: 1.07143em;
}
.scrollbar-enabled {
  position: relative;
  padding-right: 15px;
}
.ps {
  overflow: hidden !important;
  overflow-anchor: none;
  -ms-overflow-style: none;
  touch-action: auto;
  -ms-touch-action: auto;
}

.widget-chat-activity .message:first-child {
  margin-top: 0;
}
.widget-chat-activity .message {
  margin: 0.71429em 0;
}

.media {
  display: flex;
  align-items: flex-start;
}

.widget-chat-activity .message figure {
  order: 2;
}

.widget-chat-activity .message.reply .message-body {
  align-items: flex-start;
  order: 2;
}

.widget-chat-activity .message .message-body {
  margin-top: 0.5em;
  display: inline-flex;
  flex-direction: column;
  align-items: flex-end;
  margin: auto 1.5em;
  order: 1;
}

.widget-chat-activity .message p {
  border-radius: 3px;
  padding: 0.5em 1em;
  color: #fff;
  margin-bottom: 0.5em;
  position: relative;
}

.widget-chat-activity .message.reply p:first-child:before,
.widget-chat-activity .message.reply p:first-child:after {
  content: "";
  height: 0;
  width: 0;
  display: block;
  position: absolute;
  right: auto;
  left: -7px;
  border-style: solid;
  border-width: 6px 7px 6px 0;
  border-color: transparent #fff transparent transparent;
  transform: translateY(-50%);
}

.widget-chat-activity .message.reply p {
  border-radius: 0;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  border-radius: 3px;
  color: #666;
}

.widget-chat-activity .message p:first-child:before,
.widget-chat-activity .message p:first-child:after {
  content: "";
  height: 0;
  width: 0;
  display: block;
  position: absolute;
  top: 1.25rem;
  right: -7px;
  left: auto;
  border-style: solid;
  border-width: 6px 0 7px 6px;
  border-color: transparent transparent transparent #4484f4;
  transform: translateY(-50%);
}

p {
  margin-top: 0;
}

.thumb-xs2 img,
.thumb-xs2 .user-char-image {
  height: 50px;
  width: 50px;
  line-height: 50px;
}

textarea {
  background: #f7f7f7;
  resize: vertical;
}

textarea:focus {
  outline: none;
}

.img-chat-user {
  padding-right: 10px;
}

.send-button {
  height: 30px;
  cursor: pointer;
}

.no-resize {
  resize: none;
}
