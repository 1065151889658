@import "variables.scss";

.easypay-backoffice-document-table-header {
  width: 100%;
  max-width: 1210px;
  padding-left: 40px;
  padding-right: 40px;
  display: flex;
  flex-direction: row;
}

.easypay-backoffice-document-table-header-document-wrapper {
  display: flex;
  flex-direction: row;
  @include weight("bold");
  width: 33%;
  cursor: pointer;
}

.easypay-backoffice-document-table-header-doc-name-wrapper {
  @include weight("bold");
  width: 17.6%;
  display: flex;
  flex-direction: row;
  cursor: pointer;
}

.easypay-backoffice-document-table-header-date-wrapper {
  @include weight("bold");
  width: 12.4%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  cursor: pointer;
}

.easypay-backoffice-document-table-header-hour-wrapper {
  @include weight("bold");
  width: 15.6%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  cursor: pointer;
}

.easypay-backoffice-document-table-header-state {
  @include weight("bold");
  width: 14.8%;
  display: flex;
  justify-content: flex-end;
}

.easypay-backoffice-document-table-item-doc-img-name-wrapper {
  display: flex;
  flex-direction: row;
  cursor: pointer;
}

.easypay-backoffice-document-table-item-wrapper {
  display: flex;
  flex-direction: column;
}

.easypay-backoffice-document-table-item-doc-name {
  color: $color-primary;
  font: Overpass;
}

.easypay-backoffice-document-table-item-doc-title {
  font: Overpass;
  font-weight: bold;
}

.easypay-backoffice-document-table-item-doc-desc {
  font: Overpass;
}

.easypay-backoffice-document-table-item-doc-name-pending {
  @include weight("bold");
  color: $color-warning;
}

.easypay-backoffice-document-table-item-header {
  @include weight("bold");
}

.easypay-backoffice-document-table-item-subheader {
  color: $color-neutral-4;
}

.easypay-backoffice-document-table-item-doc-name-wrapper {
  width: 17.6%;
}

.easypay-backoffice-document-table-item-wrapper {
  width: 100%;
  max-width: 1210px;
  padding-left: 40px;
  padding-right: 40px;
  height: 90px;
  align-items: center;
  display: flex;
  flex-direction: row;
}
.easypay-backoffice-document-table-item-name-wrapper {
  width: 33%;
}
.easypay-backoffice-document-table-item-name {
  @include weight("bold");
}

.easypay-backoffice-document-table-item-date {
  color: $color-neutral-4;
  width: 12.4%;
  display: flex;
  justify-content: flex-end;
}

.easypay-backoffice-document-table-item-hour {
  color: $color-neutral-4;
  width: 15.3%;
  display: flex;
  justify-content: flex-end;
}

.easypay-backoffice-document-table-item-state {
  width: 14.8%;
  display: flex;
  font-size: 50px;
  justify-content: flex-end;
  &.pending{
    padding-right: 9px;
  }
}

