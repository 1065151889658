.easypay-country-flag {
  width: 30px;
  height: 20px;
  margin-right: 10px;
}

.easypay-country-selected-wrapper {
  display: flex;
  padding-top: 20px;
  justify-content: space-between;
}

.easypay-AT {
  background-image: url("./AT.png") !important;
}
.easypay-BE {
  background-image: url("./BE.png") !important;
}
.easypay-BG {
  background-image: url("./BG.png") !important;
}
.easypay-CY {
  background-image: url("./CY.png") !important;
}
.easypay-CZ {
  background-image: url("./CZ.png") !important;
}
.easypay-DE {
  background-image: url("./DE.png") !important;
}
.easypay-DK {
  background-image: url("./DK.png") !important;
}
.easypay-EE {
  background-image: url("./EE.png") !important;
}
.easypay-EL {
  background-image: url("./EL.png") !important;
}
.easypay-ES {
  background-image: url("./ES.png") !important;
}
.easypay-FI {
  background-image: url("./FI.png") !important;
}
.easypay-GR {
  background-image: url("./GR.png") !important;
}
.easypay-FR {
  background-image: url("./FR.png") !important;
}
.easypay-HR {
  background-image: url("./HR.png") !important;
}
.easypay-HU {
  background-image: url("./HU.png") !important;
}
.easypay-IE {
  background-image: url("./IE.png") !important;
}
.easypay-IT {
  background-image: url("./IT.png") !important;
}
.easypay-LT {
  background-image: url("./LT.png") !important;
}
.easypay-LU {
  background-image: url("./LU.png") !important;
}
.easypay-LV {
  background-image: url("./LV.png") !important;
}
.easypay-MT {
  background-image: url("./MT.png") !important;
}
.easypay-NL {
  background-image: url("./NL.png") !important;
}
.easypay-PL {
  background-image: url("./PL.png") !important;
}
.easypay-PT {
  background-image: url("./PT.png") !important;
}
.easypay-RO {
  background-image: url("./RO.png") !important;
}
.easypay-SE {
  background-image: url("./SE.png") !important;
}
.easypay-SI {
  background-image: url("./SI.png") !important;
}
.easypay-SK {
  background-image: url("./SK.png") !important;
}
