.scrollable{
  width: 100%;
  height: 164px;
  overflow: hidden;
  overflow-y: visible;
  scrollbar-width: thin;/*fancy width*/
}

.scrollable::-webkit-scrollbar {
  width: 10px;
}

.scrollable::-webkit-scrollbar-track {
  background: #dadce0;
}

.scrollable::-webkit-scrollbar-thumb {
  background-color: #4484f4;
  border-radius: 6px;
}

.scrollableAbout{
  width: 100%;
  height: 480px;
  overflow-x: hidden; /* Impede scroll horizontal */
  overflow-y: auto; /* Permite scroll vertical se necessário */
  scrollbar-width: thin; /* Largura da barra de rolagem elegante */
}

.scrollableAbout::-webkit-scrollbar {
  width: 10px;
}

.scrollableAbout::-webkit-scrollbar-track {
  background: #dadce0;
}

.scrollableAbout::-webkit-scrollbar-thumb {
  background-color: #4484f4;
  border-radius: 6px;
}

.scrollableBootsraptable{
  width: 100%;
  max-height: 550px;
  overflow: hidden;
  overflow-y: visible;
  scrollbar-width: thin;/*fancy width*/
}

.scrollableBootsraptable::-webkit-scrollbar {
  width: 10px;
}

.scrollableBootsraptable::-webkit-scrollbar-track {
  background: #dadce0;
}

.scrollableBootsraptable::-webkit-scrollbar-thumb {
  background-color: #c1c1c1;
  border-radius: 6px;
}

.scrollableBootsraptable .react-bootstrap-table .table  {
  width: 99%!important;
}

.scrollableBootsraptable .react-bootstrap-table .table thead {
  position: sticky;
  top: 0;
  background-color: #fff; /* set the background color to match the table background */
  z-index: 100;
}
