@import "variables.scss";

.easypay-main-modal-wrapper {
  position: absolute;
  min-height: 100vh;
}

.easypay-back-shed {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.4);
  //height: 100vh;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 150;
  display: flex;
  top: 0px;
  left: 0px;
  margin-bottom: auto;
  overflow: hidden;
  overflow-x: hidden;
  overflow-y: hidden;
  overflow-y: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.easypay-modal-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: $color-neutral-0;
  box-shadow: 0px 4px 30px $color-shadow-secondary;
  z-index: 32;
  opacity: 100%;
  padding: 40px;
  max-width: 580px;
  border-radius: 20px;
  opacity: 1;

  max-height: 95vh;
  overflow: auto;

  &.nomax{
    max-width: 90%;
    min-width: 90%;
  }
}

.easypay-modal-header {
  display: flex;
  align-content: center;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  height: 26px;
  margin-bottom: 30px;
}

.easypay-modal-header-title {
  align-items: center;
  color: $color-neutral-6;
  @include weight("bold");
  font-size: 20px;
}

.easypay-close-modal-btn {
  color: $color-neutral-5;
  cursor: pointer;
  font-size: 29px;
  @include weight("regular");
}
.easypay-close-modal-btn:hover {
  color: $color-neutral-3;
}

.easypay-modal-body {
  width: 100%;
  align-items: center;
  justify-content: center;
}

.easypay-modal-footer {
  background: $color-neutral-0;
  flex-direction: row;
  justify-items: end;
  justify-content: flex-end;
  align-items: center;
  display: flex;
}
