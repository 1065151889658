@import "liftworldVariables.scss";

.liftworld-input-dropdown-wrapper {
  padding: 0;
  margin: 0;
  height: 30px;
  width: inherit;
}

.liftworld-input-hours-dropdown-wrapper {
  padding: 0;
  margin: 0;
  height: 30px;
  width: inherit;
}


.liftworld-input-dropdown-icon-wrapper {
  display: flex;
  padding-right: 16px;
  align-items: center;
}

.liftworld-input-dropdown-icon {
  width: 15px;
  height: 8px;
  transition: transform 0.2s linear;
}

.liftworld-input-dropdown-field {
  background-color: transparent;
  border: none;
  height: 100%;
  width: 100%;
  padding-left: 10px;
  &:focus {
    outline: none;
  }
  &:hover {
    cursor: default;
  }
}

.liftworld-input-hours-dropdown-field {
  background-color: transparent;
  border: none;
  height: 100%;
  width: inherit;
  padding-left: 20px;
  &:focus {
    outline: none;
  }
  &:hover {
    cursor: default;
  }
}

.liftworld-input-dropdown-field-wrapper {
  display: flex;
  flex-direction: row;
  height: 30px;
  background: $liftworld-color-neutral-0 0% 0% no-repeat padding-box;
  border: 2px solid $liftworld-color-neutral-2;
  flex: 1;
  text-align: left;
  @include weight("regular");
  font-size: 16px;
  color: $liftworld-color-neutral-6;
  transition: all 0.2s linear;
  width:100%;
  &:hover {
    cursor: default;
  }
  &:focus-within {
    outline: none;
    border: 2px solid  $liftworld-color-neutral-2;
  }
  &.single {
    border-bottom-right-radius: 12px;
    border-bottom-left-radius: 12px;
  }
  &.start {
    border-bottom-left-radius: 12px;
  }
  &.end {
    border-bottom-right-radius: 12px;
  }
  &[disabled] {
    background-color: $liftworld-color-neutral-1;
    color: $liftworld-color-neutral-3;
  }
  &.regular{
    border-radius: 23px;
    height: 45px;
    width: inherit;
  }
}

.liftworld-dropdown {
  display: none;
  position: relative;
  z-index: 5;
  width: inherit;
  min-width: fit-content;
  height: fit-content;
  background: $liftworld-color-neutral-0 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 10px $liftworld-color-shadow-secondary;
  border-radius: 4px;
  margin-top: 5px;
  padding-top: 4px;
  max-height: 215px;
  overflow: hidden;
  overflow-y: scroll;
}

.liftworld-hours-dropdown-input-wrapper{
  width: inherit;
  height: 30px;
  background-color: $liftworld-color-neutral-0;
  border: 1px solid $liftworld-color-neutral-2;
  align-items: center;
  display: flex;
  @include weight("regular");
  font-size: 16px;
  color: $liftworld-color-neutral-6;
  transition: all 0.2s linear;
}

.liftworld-dropdown-option {
  display: flex;
  justify-content: left;
  align-items: center;
  padding-left: 20px;
  @include weight("semi-bold");
  font-size: 14px;
  color: $liftworld-color-neutral-6;
  height: 40px;

  &.liftworld-no-border {
    border: none;
  }
  width: 100%;
  opacity: 1;

  &:hover {
    background: $liftworld-color-neutral-1 0% 0% no-repeat padding-box;
    color: $liftworld-color-font-primary;
  }
  & > * {
    cursor: default;
  }
}

.liftworld-hours-dropdown-option {
  display: flex;
  justify-content: left;
  align-items: center;
  padding-left: 20px;
  @include weight("semi-bold");
  font-size: 14px;
  color: $liftworld-color-neutral-6;
  height: 40px;

  &.liftworld-no-border {
    border: none;
  }
  width: fit-content;
  opacity: 1;

  &:hover {
    background: $liftworld-color-neutral-1 0% 0% no-repeat padding-box;
    color: $liftworld-color-font-primary;
  }
  & > * {
    cursor: default;
  }
}

.liftworld-dropdown-option-wrapper {
  display: flex;
  border: 2px solid $liftworld-color-neutral-1;
  border-width: 0px 0px 2px 0px;
  &:hover {
    background: $liftworld-color-neutral-1 0% 0% no-repeat padding-box;
    & > * {
      color: $liftworld-color-font-primary;
    }
  }
  & > * {
    cursor: default;
  }
}

.liftworld-country-flag-option {
  width: 30px;
  height: 20px;
  margin: 10px 0px 10px 10px;
}

.liftworld-option-selected {
  background: $liftworld-color-neutral-2 0% 0% no-repeat padding-box;
  color: $liftworld-color-font-primary;
}

.liftworld-country-flag-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 20px;
}

.liftworld-rotate-180 {
  transform: rotate(180deg);
}

/* Show the dropdown menu (use JS to add this class to the .dropdown-content container when the user clicks on the dropdown button) */
.liftworld-show {
  display: block;
}

#liftworld-dropdown-no-result {
  display: flex;
  justify-content: left;
  align-items: center;
  padding-left: 20px;
  @include weight("semi-bold");
  font-size: 14px;
  height: 40px;
  border: 2px solid $liftworld-color-neutral-1;
  border-width: 0px 0px 2px 0px;
  background: $liftworld-color-neutral-1 0% 0% no-repeat padding-box;
  color: $liftworld-color-font-primary;
}
