.data-capture-settings:hover {
  cursor: pointer;
}
.adatacapture-metadata-item-row{
  margin-top: 15px;
  display: flex;
}
.adatacapture-metadata-item-label{
  margin-right: 10px;
  flex-grow: 1;
  width: 100%;
}
.adatacapture-metadata-item-value{
  flex-grow: 2;
  width: 100%;
}
.adatacapture-metadata-nesteditems{
  padding-top: 15px;
  margin-top: 15px;
  margin-left: -20px;
}
.adatacapture-metadata-nesteditem{
  padding: 0 1rem 1rem 1rem;
  margin-top: 5px;
  border: 1px solid rgba(0,0,0,.125);
  border-radius: 0.25rem;
}
.calendar-automation .element-input-wrapper-date {
  width: auto !important;
  min-width: 0px !important;
}