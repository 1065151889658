@import "variables.scss";

.easypay-feedback-message-success {
  display: flex;
  margin: 30px;
  flex-direction: row;
  width: fit-content;
  @include weight("bold");
  font-size: 18px;
  height: 64px;
  background: $color-success-light;
  color: $color-success;
  border-radius: 10px;
  padding-left: 20px;
  padding-top: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
}

.easypay-feedback-message-error {
  display: flex;
  flex-direction: row;
  margin: 30px;
  width: fit-content;
  @include weight("bold");
  font-size: 18px;
  background: $color-error-light;
  color: $color-error;
  border-radius: 10px;
  padding-left: 20px;
  padding-top: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
}

.easypay-feedback-message-warning {
  display: flex;
  flex-direction: row;
  margin: 30px;
  width: fit-content;
  @include weight("bold");
  font-size: 18px;
  height: 64px;
  background: $color-warning-light;
  color: $color-warning;
  border-radius: 10px;
  padding-left: 20px;
  padding-top: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
}

.easypay-feedback-message-info {
  display: flex;
  flex-direction: row;
  margin: 30px;
  width: fit-content;
  @include weight("bold");
  font-size: 18px;
  height: 64px;
  background: $color-primary-light;
  color: $color-primary;
  border-radius: 10px;
  padding-left: 20px;
  padding-top: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
}

.easypay-feedback-content-container {
  flex: row;
  display: flex;
}

.easypay-feedback-img {
  height: 24px;
  width: 24px;
}

.easypay-feedback-text {
  padding-left: 10px;
}
