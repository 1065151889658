@import "theming.scss";

@mixin navbar($theme) {
  .navbar {
    height: 65px;
    box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.1);
    z-index: 2;
    background-color: theme-color($theme, "navbarBackgroundColor");

    .navbar > .container,
    .navbar > .container-fluid {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-between;
    }

    @media (max-width: 991.98px) {
      .navbar-header {
        width: 100% !important;
      }
    }

    @media (max-width: 991.98px) {
      .navbar-nav {
        display: table;
        margin: 0;
        height: auto;
        display: block;
      }
    }

    @media (min-width: 992px) {
      .navbar-expand-lg .nav-item {
        display: flex !important;
        flex-basis: auto;
      }

      #sidebar {
        display: block;
      }
    }

    @media (min-width: 320px) {
      .navbar-header {
        float: left;
        align-items: center;
      }
    }

    .logo {
      filter: theme-color($theme, "navbarImg");
    }
    .nav-icon {
      fill: theme-color($theme, "navbarIconBackgroundColor");
    }
    .nav-icon:hover {
      fill: theme-color($theme, "navbarIconBackgroundColorHover");
    }

    .nav-item-wrapper {
      height: 31px;
      width: 31px;
      background-color: theme-color($theme, "navbarIconBackgroundColor");
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      cursor: pointer;
      &:hover {
        background-color: theme-color($theme, "navbarIconBackgroundColorHover");
      }
    }

    .dropdown-text {
      padding: 10px;
      font-size: 13px;
      font-weight: 400;
      min-width: 150px;
      color: theme-color($theme, "textColor");
    }

    .dropdown-toggle {
      color: theme-color($theme, "textColor");
    }

    .dropdown-menu {
      position: absolute;
      color: theme-color($theme, "dropdownMenuTextColor");
      background-color: theme-color($theme, "dropdownMenuBackgroundColor");
      @if $theme == dark {
        border-color: #000;
      }
      text-decoration: none;

      border-radius: 4px;
      border: none;
      box-shadow: 0px 3px 10px #6d6d6d40;
    }

    .dropdown-item {
      color: theme-color($theme, "dropdownItemColor");
      background-color: theme-color($theme, "dropdownItemBackgroundColor");
      white-space: normal !important;
    }

    .dropdown-item:hover {
      color: theme-color($theme, "dropdownItemHover") !important;
      background-color: theme-color(
        $theme,
        "dropdownItemBackgroundColorHover"
      ) !important;
    }

    .dropdown-right-wrapper {
      .dropdown-menu {
        right: 0px;
        left: auto;
        top: 105%;
      }
    }
  }

  @media (max-width: 768px) {
    .navbar {
      position: sticky;
      top: 0;
    }
  }
}