@import "liftworldVariables.scss";

.liftworld-item-row-wrapper {
  display: flex;
  width: 100%;
  align-items: center;
  padding-bottom: 15px;
}

.liftworld-item-option-row-wrapper {
  display: flex;
  width: 100%;
  align-items: center;
  padding-bottom: 15px;
  justify-content: space-between;
}
.liftworld-item-option-row-wrapper-end {
  display: flex;
  width: 100%;
  align-items: center;
  padding-bottom: 15px;
  padding-right: 47.5px;
  justify-content: flex-end;
}


.liftworld-item-option-row-wrapper-inputs {
  display: flex;
  width: 100%;
  align-items: center;
  padding-bottom: 15px;
}


.liftworld-item-row-option-wrapper {
  display: flex;
  width: 100%;
  padding-top: 10px;
  align-items: center;
  padding-bottom: 15px;
}


.liftworld-workplan-bottom-button-wrapper{
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.liftworld-objective-row-wrapper {
  display: flex;
  width: 100%;
}

.liftworld-item-main-wrapper{
  min-width: 100%;
}

.liftworld-item-content-wrapper {
  width: 100%;
}

.liftworld-item-bottom-button-wrapper {
  width: 100%;
  display: flex;
  padding-bottom: 30px;
  padding-left: 30px;
}

.liftworld-activity-bottom-button-wrapper {
  width: 100%;
  padding-top: 30px;
  display: flex;
}

.liftworld-tab-wrapper {
  display: flex;
  flex-direction: column;
  margin-right: 10px;
  width: 275px;
  height: 40px;
  @include weight("semi-bold");
  font-size: 16px;
  //padding: 9px 55px;
  border-radius: 20px;
  color: $liftworld-color-neutral-5;
  background: $liftworld-color-neutral-0 0% 0% no-repeat padding-box;
  border: solid $liftworld-color-neutral-0;
  justify-content: center;
  align-items: center;
  &:focus {
    outline: none;
  }
  &.easypay-active,
  &:hover {
    color: $liftworld-color-neutral-0 !important;
    background: $liftworld-color-font-primary 0% 0% no-repeat padding-box !important;
    //border: solid $color-secondary;
    border: transparent !important;
  }
  &[disabled] {
    color: $liftworld-color-neutral-3;
    background: $liftworld-color-neutral-0 0% 0% no-repeat padding-box;
    border: solid $liftworld-color-neutral-0;
  }
  &.easypay-separator {
    width: 180px;
  }
}

.liftworld-tab-wrapper-disabled {
  display: flex;
  flex-direction: column;
  margin-right: 10px;
  width: 275px;
  height: 40px;
  @include weight("semi-bold");
  font-size: 16px;
  //padding: 9px 55px;
  border-radius: 20px;
  color: $liftworld-color-neutral-5;
  background: $liftworld-color-neutral-2 0% 0% no-repeat padding-box;
  border: solid $liftworld-color-neutral-2;
  justify-content: center;
  align-items: center;
  &:focus {
    outline: none;
  }
  &.easypay-separator {
    width: 180px;
  }
}
