@import "theming.scss";

@mixin datepicker($theme) {
  .date-picker {
    position: relative;
    transition: all 0.3s ease-in-out;
    user-select: none;

    .data-arrow {
      cursor: pointer;
      border-radius: 5px;
      font-size: 24px;
      display: flex;
      align-content: center;
      justify-content: center;
      transition: color 0s ease;

      &:hover {
        border-radius: 10px;
        background-color: theme-color($theme, "uniksystemColor");
        color: #e9ecef;
      }
    }

    .data-picker-top-button {
      border-radius: 5px;
      padding: 5px;
      user-select: none;

      &:hover {
        cursor: pointer;
        background-color: theme-color($theme, "uniksystemColor");
        color: var(--light);
      }
    }

    * {
      user-select: none;
      transition: all 0.3s ease-in-out;
    }

    > input {
      background-color: transparent !important;

      &.disabled {
        background-color: #e9ecef !important;
      }
    }

    .date-picker-time {
      display: flex;
      flex-direction: row;
      padding: 10px;
      gap: 20px;
      align-content: center;
      justify-content: center;

      .data-time-selector {
        display: flex;
        flex-direction: column;
        width: 20px;
        align-items: center;
        justify-content: center;

        > span {
          flex: 1;
        }
      }
    }

    @media (max-width: 1368px) {
      .date-picker-selector_rightSide {
        right: 15px;
      }
    }
    .ultracompactForm {
      height: 35px!important;
    }
    .date-picker-selector_rightSide {
      width: 320px;
      z-index: 9999;
      position: absolute;
      display: flex;
      overflow: hidden;
      flex-direction: column;
      border-radius: 10px;
      margin-top: 10px;
      box-shadow: 0px 0 10px -1px #a7a7a7;
      background-color: white;
      padding: 0px 10%;
      max-height: 0px;

      &.active {
        max-height: 100vh;
      }

      > .date-picker-top {
        display: flex;
        flex-direction: row;
        margin: 10px 0px;
      }

      > .date-picker-content {
        width: 100%;
        overflow: hidden;

        > .date-picker-week {
          margin-bottom: 10px;
        }

        > .date-picker-days > .data-valid:hover {
          background-color: theme-color($theme, "uniksystemColor");
          color: var(--light);
          cursor: pointer;
          border-radius: 100%;
        }

        > .date-picker-days,
        .date-picker-week {
          display: grid;
          grid-template-columns: auto auto auto auto auto auto auto;
          text-align: center;

          .date-picker-month-select {
            grid-column: 1 / -1;
            display: grid;
            grid-template-columns: auto auto auto auto auto auto auto;
            > * {
              grid-column: span 2;
              align-self: center;
              justify-self: center;
            }
            > span {
              grid-column: span 3;
            }
          }

          > .selected-date {
            border-radius: 100%;
            background-color: theme-color($theme, "uniksystemColor");
            color: var(--light);
          }

          > .today {
            border-radius: 100%;
            background-color: theme-color($theme, "uniksystemColor");
            color: var(--light);
          }

          > span {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 25px;
            height: 25px;
            border-radius: 5px;
            user-select: none;
            padding: 3px;
          }
        }

        > .date-picker-months {
          display: grid;
          grid-template-columns: auto auto auto auto auto;
          overflow-y: scroll;
          overflow-x: hidden;
          max-height: 150px;

          > .data-selected {
            cursor: pointer;
            user-select: none;
            background-color: theme-color($theme, "uniksystemColor");
            color: var(--light);
          }

          &::-webkit-scrollbar {
            width: 5px;
            height: auto;
            background-color: transparent;
            cursor: pointer;
          }

          &::-webkit-scrollbar-thumb {
            background-color: theme-color($theme, "uniksystemColor");
          }

          > span {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 25px;
            border-radius: 5px;
            padding: 5px;
            margin: 3px;

            &:hover {
              cursor: pointer;
              user-select: none;
              background-color: theme-color($theme, "uniksystemColor");
              color: var(--light);
            }
          }
        }
      }

      > .date-picker-bottom {
        margin: 10px 0px;
        width: 100%;
        display: flex;
        flex-direction: row;

        /*
        > button {
          border: none;
          border-radius: 23px;
          background-color: theme-color($theme, "uniksystemColor");
          padding: 5px 10px;
          color: var(--light);
          margin: 0px 5px;
        }*/
      }
    }

    .date-picker-selector {
      width: 320px;
      z-index: 9999;
      position: absolute;
      display: flex;
      overflow: hidden;
      flex-direction: column;
      border-radius: 10px;
      margin-top: 10px;
      box-shadow: 0px 0 10px -1px #a7a7a7;
      background-color: white;
      padding: 0px 10%;
      max-height: 0px;

      &.active {
        max-height: 100vh;
        position: relative;
      }

      > .date-picker-top {
        display: flex;
        flex-direction: row;
        margin: 10px 0px;
      }

      > .date-picker-content {
        width: 100%;
        overflow: hidden;

        > .date-picker-week {
          margin-bottom: 10px;
        }

        > .date-picker-days > .data-valid:hover {
          background-color: theme-color($theme, "uniksystemColor");
          color: var(--light);
          cursor: pointer;
          border-radius: 100%;
        }

        > .date-picker-days,
        .date-picker-week {
          display: grid;
          grid-template-columns: auto auto auto auto auto auto auto;
          text-align: center;

          .date-picker-month-select {
            grid-column: 1 / -1;
            display: grid;
            grid-template-columns: auto auto auto auto auto auto auto;
            > * {
              grid-column: span 2;
              align-self: center;
              justify-self: center;
            }
            > span {
              grid-column: span 3;
            }
          }

          > .selected-date {
            border-radius: 100%;
            background-color: theme-color($theme, "uniksystemColor");
            color: var(--light);
          }

          > .today {
            border-radius: 100%;
            background-color: theme-color($theme, "uniksystemColor");
            color: var(--light);
          }

          > span {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 25px;
            height: 25px;
            border-radius: 5px;
            user-select: none;
            padding: 3px;
          }
        }

        > .date-picker-months {
          display: grid;
          grid-template-columns: auto auto auto auto auto;
          overflow-y: scroll;
          overflow-x: hidden;
          max-height: 150px;

          > .data-selected {
            cursor: pointer;
            user-select: none;
            background-color: theme-color($theme, "uniksystemColor");
            color: var(--light);
          }

          &::-webkit-scrollbar {
            width: 5px;
            height: auto;
            background-color: transparent;
            cursor: pointer;
          }

          &::-webkit-scrollbar-thumb {
            background-color: theme-color($theme, "uniksystemColor");
          }

          > span {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 25px;
            border-radius: 5px;
            padding: 5px;
            margin: 3px;

            &:hover {
              cursor: pointer;
              user-select: none;
              background-color: theme-color($theme, "uniksystemColor");
              color: var(--light);
            }
          }
        }
      }

      > .date-picker-bottom {
        margin: 10px 0px;
        width: 100%;
        display: flex;
        flex-direction: row;
      }
    }
  }
}
