@import "liftworldVariables.scss";

.liftworld-header{
    width: 100%;
    color: #838383;
    justify-content: center;
    display: flex;
    font-size: 1.45rem;
    align-items: center;
    border-radius: 23px;
    font-weight: bold;
    margin-bottom: 30px;
}