.x-icon {
  margin-right: 12px;
}

.btn-microsoft {
  font-family: Segoe UI Regular 15px;
  font-weight:600;
  color: #5e5e5e;
  background: #ffffff;
  border: 1px solid #8c8c8c;
  padding-left: 24px;
  padding-right: 24px;
  height: 44px;

}