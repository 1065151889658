.input-grid {
  padding: 30px 55px 0px 55px;
  display: grid;
  grid-template-columns: auto auto auto auto auto;
  grid-column-gap: 40px;
}

.grid-search {
  grid-column: span 2;
}

.liftworld-input-grid {
  padding: 30px 0px 15px 25px;
  display: grid;
  grid-template-columns: auto auto auto auto auto;
  grid-column-gap: 40px;
}
