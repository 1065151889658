.pointer {
    cursor: pointer !important;
}

.lg-badge {
    font-size: 14px !important;
}
.lg-badge.disabled {
    background-color: #287480 !important;
    pointer-events: none;
    cursor: default;
}

.lg-icon {
    font-size: 20px !important;
}

.add-table-icon {
    color: #007bff;
}

#user-table {
    &.table th:nth-child(1) {
        width: 70%;
    }

    &.table th:nth-child(2) {
        width: 300px;
    }

    &.table th:nth-child(3) {
        width: 20%;
    }
}

#modal-user-table {
    &.table th:nth-child(1) {
        width: 85%;
    }

    &.table th:nth-child(2) {
        width: 15%;
    }

}
