@import "liftworldVariables.scss";

.liftworld-table-header-element{
    height: 30px;
    background: $liftworld-color-font-primary;
    color: $liftworld-color-neutral-0;
    justify-content: center;
    text-align: center;
    display: flex;
    border: 1px solid $liftworld-color-neutral-2;
    outline: 1px solid $liftworld-color-neutral-2;
    width: 100%;
    align-items: center;
    font-size: 11px;
    &.start {
      border-top-left-radius: 12px;
    }
    &.end {
      border-top-right-radius: 12px;
    }
    &.single{
      border-top-left-radius: 12px;
      border-top-right-radius: 12px;
    }
}

.liftworld-table-header-element-9{
  height: 30px;
  background: $liftworld-color-font-primary;
  color: $liftworld-color-neutral-0;
  justify-content: center;
  text-align: center;
  display: flex;
  border: 1px solid $liftworld-color-neutral-2;
  outline: 1px solid $liftworld-color-neutral-2;
  width: 10.91%;
  align-items: center;
  font-size: 11px;
  &.start {
    border-top-left-radius: 12px;
  }
  &.end {
    border-top-right-radius: 12px;
  }
}

.liftworld-simulator-table-header-element-20{
  height: 30px;
  background: $liftworld-color-font-primary;
  color: $liftworld-color-neutral-0;
  justify-content: center;
  text-align: center;
  display: flex;
  border: 1px solid $liftworld-color-neutral-2;
  outline: 1px solid $liftworld-color-neutral-2;
  width: 20%;
  align-items: center;
  font-size: 11px;
  &.start {
    border-top-left-radius: 12px;
  }
  &.end {
    border-top-right-radius: 12px;
  }
  &.bottom-start {
    border-bottom-left-radius: 12px;
  }
}

.liftworld-table-header-wrapper{
  width: 100%;
  display: flex;
  flex-direction: row !important;
}

.liftworld-minus-icon{
  height:20px;
  width: 20px;
}

.lifworld-table-wrapper
{
  width: 100%;
}

.liftworld-table-10-wrapper{
  width: 9.8%;
}

.liftworld-table-7-wrapper{
  width: 7.45%;
}

.liftworld-table-11-wrapper{
  width: 11%;
}

.liftworld-table-12-wrapper{
  width: 12%;
}

.liftworld-table-13-wrapper{
  width: 12.8%;
}

.liftworld-table-115-wrapper{
  width: 11.5%;
}


.liftworld-table-row-wrapper{
  display: flex;
}

.liftworld-costs-table-9-wrapper{
  width: 10.91%;
}