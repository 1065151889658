@import "variables.scss";

@mixin weight($value) {
  font-family: $font-family;
  letter-spacing: 0px;
  opacity: 1;

  @if ($value == "light") {
    font-style: normal;
    font-variant: normal;
    font-weight: 300;
  }

  @if ($value == "regular") {
    font-style: normal;
    font-variant: normal;
    font-weight: normal;
  }

  @if ($value == "semi-bold") {
    font-style: normal;
    font-variant: normal;
    font-weight: 600;
  }

  @if ($value == "bold") {
    font-style: normal;
    font-variant: normal;
    font-weight: bold;
  }
}

@mixin overpass($type) {
  @if ($type== "header-1") {
    text-align: center;
    font-size: 32px;
    color: $color-primary;
    @include weight("regular");
    padding-bottom: 40px;
  }

  @if ($type== "header-3") {
    text-align: center;
    font-size: 24px;
    color: $color-primary;
    @include weight("regular");
    padding-bottom: 20px;
  }

  @if ($type== "header-4") {
    text-align: left;
    font-size: 22px;
    color: $color-primary;
    @include weight("bold");
  }

  @if ($type== "header-4-small") {
    text-align: left;
    font-size: 18px;
    color: $color-primary;
    @include weight("bold");
    padding-bottom: 10px;
  }

  @if ($type== "header-4-small-disabled") {
    text-align: left;
    font-size: 18px;
    color: $color-primary-disabled;
    @include weight("bold");
    padding-bottom: 10px;
  }

  @if ($type== "header-5") {
    text-align: left;
    font-size: 20px;
    color: $color-neutral-6;
    @include weight("bold");
    padding-bottom: 30px;
  }

  @if ($type== "header-6") {
    text-align: left;
    font-size: 18px;
    color: $color-neutral-6;
    @include weight("bold");
    padding-bottom: 10px;
  }

  @if ($type== "header-7") {
    text-align: left;
    font-size: 16px;
    color: $color-neutral-6;
    @include weight("semi-bold");
    padding-bottom: 30px;
  }

  @if ($type== "body") {
    text-align: left;
    font-size: 16px;
    color: $color-neutral-6;
    @include weight("regular");
    padding-bottom: 15px;
  }

  @if ($type== "body-bold") {
    text-align: left;
    font-size: 16px;
    color: $color-neutral-6;
    @include weight("bold");
  }

  @if ($type== "body-blue") {
    text-align: center;
    font-size: 16px;
    color: $color-primary;
    @include weight("semi-bold");
  }

  @if ($type== "body-s") {
    text-align: left;
    font-size: 14px;
    color: $color-neutral-6;
    @include weight("bold");
  }

  @if ($type== "body-xs") {
    text-align: left;
    font-size: 14px;
    color: $color-neutral-4;
    @include weight("regular");
  }

  @if ($type== "body-xl") {
    text-align: center;
    font-size: 17px;
    color: $color-neutral-6;
    @include weight("bold");
    padding-bottom: 20px;
  }

  @if ($type== "body-xl-no-padding") {
    text-align: center;
    font-size: 17px;
    color: $color-neutral-6;
    @include weight("bold");
  }

  @if ($type== "subheader-3-success") {
    text-align: left;
    font-size: 24px;
    color: $color-success;
    @include weight("bold");
  }

  @if ($type== "subheader-3-success-small") {
    text-align: center;
    font-size: 16px;
    color: $color-success;
    @include weight("bold");
    padding-bottom: 40px;
  }

  @if ($type== "subheader-3-info-small") {
    text-align: center;
    font-size: 16px;
    color: $color-primary;
    @include weight("bold");
    padding-bottom: 40px;
  }

  @if ($type== "card-text-green") {
    text-align: center;
    font-size: 16px;
    color: $color-success;
    @include weight("bold");
    padding-bottom: 15px;
  }

  @if ($type== "card-text-error") {
    text-align: center;
    font-size: 16px;
    color: $color-error;
    @include weight("bold");
    padding-bottom: 15px;
  }

  @if ($type== "card-text-warning") {
    text-align: center;
    font-size: 16px;
    color: $color-warning;
    @include weight("bold");
    padding-bottom: 15px;
  }

  @if ($type== "card-text-warning-no-padding") {
    text-align: center;
    font-size: 16px;
    color: $color-warning;
    @include weight("bold");
  }

  @if ($type== "subheader-3-warning") {
    text-align: left;
    font-size: 24px;
    color: $color-warning;
    @include weight("bold");
  }

  @if ($type== "subheader-3-error") {
    text-align: left;
    font-size: 24px;
    color: $color-error;
    @include weight("bold");
  }

  @if ($type== "line-break") {
    text-align: center;
    font-size: 22px;
    color: $color-neutral-4;
    padding: 0px 20px;
    @include weight("bold");
  }

  @if ($type== "card-text") {
    text-align: left;
    font-size: 17px;
    padding-bottom: 20px;
    color: $color-neutral-6;
    @include weight("bold");
  }

  @if ($type== "card-text-no-padding") {
    text-align: left;
    font-size: 16px;
    color: $color-neutral-6;
    @include weight("bold");
  }

  @if ($type== "card-text-title") {
    text-align: left;
    font-size: 28px;
    padding-bottom: 40px;
    color: $color-neutral-6;
    @include weight("bold");
  }

  @if ($type== "card-text-blue") {
    text-align: left;
    font-size: 16px;
    color: $color-primary;
    @include weight("bold");
  }

  @if ($type== "card-text-blue-large") {
    text-align: left;
    font-size: 19px;
    color: $color-primary;
    @include weight("semi-bold");
  }

  @if ($type== "card-text-blue-small-padding") {
    text-align: left;
    font-size: 16px;
    color: $color-primary;
    @include weight("bold");
    padding-bottom: 15px;
  }

  @if ($type== "card-text-blue-disabled") {
    text-align: left;
    font-size: 16px;
    color: $color-primary-disabled;
    @include weight("bold");
  }

  @if ($type== "header-2") {
    text-align: left;
    font-size: 28px;
    padding-bottom: 30px;
    color: $color-neutral-6;
    @include weight("bold");
  }

  @if ($type== "header-2-no-padding") {
    text-align: left;
    font-size: 28px;
    color: $color-neutral-6;
    @include weight("bold");
  }

  @if ($type== "card-text-medium") {
    text-align: left;
    font-size: 17px;
    padding-bottom: 20px;
    color: $color-neutral-6;
    @include weight("semi-bold");
  }

  @if ($type== "card-text-light") {
    text-align: left;
    font-size: 16px;
    color: $color-neutral-4;
    @include weight("regular");
    padding-bottom: 30px;
  }

  @if ($type== "card-text-light-margin") {
    text-align: left;
    font-size: 16px;
    color: $color-neutral-4;
    @include weight("regular");

    padding: 0px 40px 30px;
  }

  @if ($type== "card-text-light-small-padding") {
    text-align: left;
    font-size: 16px;
    color: $color-neutral-4;
    @include weight("regular");
    padding-bottom: 15px;
  }

  @if ($type== "card-text-light-no-padding") {
    text-align: left;
    font-size: 16px;
    color: $color-neutral-4;
    @include weight("regular");
  }

  @if ($type== "card-text-light-no-padding-italic") {
    text-align: left;
    font-size: 16px;
    color: $color-neutral-4;
    @include weight("regular");
    font-style: italic;
  }

  @if ($type== "card-text-light-disabled") {
    text-align: left;
    font-size: 16px;
    color: $color-neutral-4-disabled;
    @include weight("regular");
    padding-bottom: 15px;
  }

  @if ($type== "card-text-light-no-padding-disabled") {
    text-align: left;
    font-size: 16px;
    color: $color-neutral-4-disabled;
    @include weight("regular");
    padding-bottom: 15px;
  }
}
.easypay-text-wrapper-center {
  display: flex;
  justify-content: center;
  padding-bottom: 20px;
}

.easypay-text-wrapper {
  display: flex;
}

.easypay-text-icon {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding-right: 9px;
  padding-bottom: 2px;
}

.easypay-text-icon-check {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding-right: 9px;
  img {
    width: 24px;
    height: 24px;
  }
}

.easypay-text-icon-check-padding-left {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding-right: 9px;
  padding-left: 9px;
  img {
    width: 24px;
    height: 24px;
  }
}

.easypay-text-icon-check-documents {
  display: flex;
  align-items: flex-start;
  cursor: pointer;
  padding-right: 9px;
  img {
    width: 24px;
    height: 24px;
  }
}
.easypay-text-icon-warning {
  display: flex;
  align-items: flex-start;
  img {
    width: 40px;
    height: 28px;
  }
}
.easypay-text-icon-check-fixed-width {
  display: flex;
  width: 110px;
  justify-content: center;
  align-items: flex-start;
  padding-right: 9px;
  img {
    width: 24px;
    height: 24px;
  }
}

.easypay-text-icon-check-backoffice {
  display: flex;
  justify-content: start;
  align-items: flex-start;
  img {
    width: 24px;
    height: 24px;
  }
}
.easypay-header-1 {
  @include overpass("header-1");
}

.easypay-header-3 {
  @include overpass("header-3");
}

.easypay-header-4-small {
  @include overpass("header-4-small");
  &.center {
    text-align: center;
  }
}

.easypay-header-4-small-disabled {
  @include overpass("header-4-small-disabled");
  &.center {
    text-align: center;
  }
}

.easypay-header-5 {
  @include overpass("header-5");
  &.center {
    text-align: center;
  }
}

.easypay-body-blue {
  @include overpass("body-blue");
}

.easypay-body {
  @include overpass("body");
  &.center {
    text-align: center;
  }
}

.easypay-body-bold {
  @include overpass("body-bold");
  &.center {
    text-align: center;
  }
}

.easypay-body-xs {
  @include overpass("body-xs");
  &.center {
    text-align: center;
  }
}

.easypay-body-xl {
  @include overpass("body-xl");
  &.center {
    text-align: center;
  }
  &.left {
    text-align: left;
  }
}

.easypay-body-xl-no-padding {
  @include overpass("body-xl-no-padding");
  &.center {
    text-align: center;
  }
  &.left {
    text-align: left;
  }
}

.easypay-card-text {
  @include overpass("card-text");
  &.center {
    text-align: center;
  }
}

.easypay-card-text-title {
  @include overpass("card-text-title");
  &.center {
    text-align: center;
  }
}

.easypay-card-text-no-padding {
  @include overpass("card-text-no-padding");
  &.center {
    text-align: center;
  }
}

.easypay-card-text-blue {
  @include overpass("card-text-blue");
  overflow-wrap: break-word;
  &.center {
    text-align: center;
  }
}

.easypay-card-text-blue-large {
  @include overpass("card-text-blue-large");
  &.center {
    text-align: center;
  }
}

.easypay-card-text-blue-small-padding {
  @include overpass("card-text-blue-small-padding");
  &.center {
    text-align: center;
  }
}

.easypay-card-text-warning {
  @include overpass("card-text-warning");
  &.center {
    text-align: center;
  }
}

.easypay-card-text-warning-no-padding {
  @include overpass("card-text-warning-no-padding");
  &.center {
    text-align: center;
  }
}

.easypay-card-text-blue-disabled {
  @include overpass("card-text-blue-disabled");
  &.center {
    text-align: center;
  }
}

.easypay-header-2 {
  @include overpass("header-2");
  &.center {
    text-align: center;
  }
}

.easypay-header-2-no-padding {
  @include overpass("header-2-no-padding");
  &.center {
    text-align: center;
  }
}

.easypay-card-text-medium {
  @include overpass("card-text-medium");
}

.easypay-card-text-light-disabled {
  @include overpass("card-text-light-disabled");
  &.center {
    text-align: center;
  }
}

.easypay-card-text-light {
  @include overpass("card-text-light");
  &.center {
    text-align: center;
  }
}

.easypay-card-text-light-margin {
  @include overpass("card-text-light-margin");
  &.center {
    text-align: center;
  }
}

.easypay-card-text-green {
  @include overpass("card-text-green");
  &.center {
    text-align: center;
  }
  &.left {
    text-align: left;
  }
}

.easypay-card-text-error {
  @include overpass("card-text-error");
  &.center {
    text-align: center;
  }
  &.left {
    text-align: left;
  }
}

.easypay-card-text-light-no-padding {
  @include overpass("card-text-light-no-padding");
  &.center {
    text-align: center;
  }
}

.easypay-card-text-light-no-padding-italic {
  @include overpass("card-text-light-no-padding-italic");
  &.center {
    text-align: center;
  }
}

.easypay-card-text-light-small-padding {
  @include overpass("card-text-light-small-padding");
  &.center {
    text-align: center;
  }
}
.easypay-card-text-light-no-padding-disabled {
  @include overpass("card-text-light-no-padding-disabled");
  &.center {
    text-align: center;
  }
}

.easypay-header-6 {
  @include overpass("header-6");
  &.center {
    text-align: center;
  }
}

.easypay-header-7 {
  @include overpass("header-7");
  &.center {
    text-align: center;
  }
}

.easypay-sub-header-3-warning {
  @include overpass("subheader-3-warning");
}

.easypay-sub-header-3-info-small {
  @include overpass("subheader-3-info-small");
}

.easypay-sub-header-3-error {
  @include overpass("subheader-3-error");
}

.easypay-sub-header-3-success {
  @include overpass("subheader-3-success");
}

.easypay-sub-header-3-success-small {
  @include overpass("subheader-3-success-small");
}

.easypay-line-break {
  @include overpass("line-break");
}
