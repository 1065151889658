@import "variables.scss";

.easypay-button-wrapper {
  display: flex;
  justify-content: center;
  &.left{
    justify-content: left;
  }
}

.easypay-button-primary-large {
  width: 380px;
  height: 35px;
  background: $color-primary 0% 0% no-repeat padding-box;
  //box-shadow: 0px 10px 18px $color-shadow-primary;
  border: 2px solid $color-primary;
  border-radius: 23px;
  @include weight("bold");
  font-size: 16px;
  color: $color-neutral-0;
  margin-bottom: 30px;
  &:hover {
    //box-shadow: none;
  }
  &[disabled] {
    background: $color-neutral-2 0% 0% no-repeat padding-box;
    //box-shadow: 0px 10px 18px $color-shadow-disabled;
    border: 2px solid $color-neutral-2;
    color: $color-neutral-4;
  }
  &.easypay-button-disabledColored {
    background: $color-primary-disabled 0% 0% no-repeat padding-box;
    //box-shadow: 0px 10px 18px $color-shadow-disabled;
    border: 2px solid $color-primary-disabled;
    color: $color-neutral-0;
  }
  &:focus {
    outline: none;
  }
  &.submit {
    margin-top: 30px;
  }
}

.liftworld-button-danger {
  background: red 0% 0% no-repeat padding-box;
  //box-shadow: 0px 10px 18px $liftworld-color-shadow-primary;
  border: 2px solid red;
  border-radius: 23px;
  opacity: 1;
  color: white;
  min-width: 132px;
  padding: 0px 22px;
  height: 35px;
  &.liftworld-error {
    background: red 0% 0% no-repeat padding-box;
    //box-shadow: 0px 10px 18px $liftworld-color-shadow-error;
    border: 2px solid white;
    border-radius: 34px;
  }
  &.liftworld-success {
    background: red 0% 0% no-repeat padding-box;
    //box-shadow: 0px 10px 18px $liftworld-color-shadow-success;
    border: 2px solid red;
    border-radius: 34px;
  }
  &:hover {
    //box-shadow: none;
  }
  &[disabled] {
    background: red 0% 0% no-repeat padding-box;
    border: 2px solid red;
    //box-shadow: none;
    color: white;
  }
  &:focus {
    outline: none;
  }
}

.liftworld-button-primary {
  background: $color-unikint-standard 0% 0% no-repeat padding-box;
  //box-shadow: 0px 10px 18px $liftworld-color-shadow-primary;
  border: 2px solid $color-unikint-standard;
  border-radius: 23px;
  opacity: 1;
  color: $color-neutral-0;
  min-width: 132px;
  padding: 0px 22px;
  height: 35px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  &.liftworld-error {
    background: $color-error 0% 0% no-repeat padding-box;
    //box-shadow: 0px 10px 18px $liftworld-color-shadow-error;
    border: 2px solid $color-error;
    border-radius: 34px;
  }
  &.liftworld-success {
    background: $color-success 0% 0% no-repeat padding-box;
    //box-shadow: 0px 10px 18px $liftworld-color-shadow-success;
    border: 2px solid $color-success;
    border-radius: 34px;
  }
  &:hover {
    //box-shadow: none;
  }
  &[disabled] {
    background: $color-neutral-2 0% 0% no-repeat padding-box;
    border: 2px solid $color-neutral-2;
    //box-shadow: none;
    color: $color-neutral-4;
  }
  &:focus {
    outline: none;
  }
}

.edenred-button-primary {
  background: $color-unikint-standard 0% 0% no-repeat padding-box;
  //box-shadow: 0px 10px 18px $liftworld-color-shadow-primary;
  border: 2px solid $color-unikint-standard;
  border-radius: 23px;
  opacity: 1;
  color: #F72717;
  min-width: 132px;
  padding: 0px 22px;
  height: 45px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  &.liftworld-error {
    background: $color-error 0% 0% no-repeat padding-box;
    //box-shadow: 0px 10px 18px $liftworld-color-shadow-error;
    border: 2px solid $color-error;
    border-radius: 34px;
  }
  &.liftworld-success {
    background: $color-success 0% 0% no-repeat padding-box;
    //box-shadow: 0px 10px 18px $liftworld-color-shadow-success;
    border: 2px solid $color-success;
    border-radius: 34px;
  }
  &:hover {
    //box-shadow: none;
  }
  &[disabled] {
    background: $color-neutral-2 0% 0% no-repeat padding-box;
    border: 2px solid $color-neutral-2;
    //box-shadow: none;
    color: $color-neutral-4;
  }
  &:focus {
    outline: none;
  }
}

.easypay-button-secondary-large {
  width: 380px;
  height: 45px;
  margin: 30px;
  background: $color-neutral-0 0% 0% no-repeat padding-box;
  //box-shadow: 0px 10px 18px $color-shadow-secondary;
  border: 2px solid $color-primary;
  border-radius: 23px;
  color: $color-primary;
  @include weight("bold");
  font-size: 16px;
  &:hover {
    background: $color-neutral-1 0% 0% no-repeat padding-box;
    //box-shadow: none;
  }
  &[disabled] {
    background: $color-neutral-2 0% 0% no-repeat padding-box;
    border: 2px solid $color-neutral-3;
    color: $color-neutral-4;
    //box-shadow: none;
  }
  &:focus {
    outline: none;
  }
}

.easypay-button-remove {
  width: fit-content;
  height: 45px;
  border: none;
  background: $color-neutral-0 0% 0% no-repeat padding-box;
  color: $color-primary;
  @include weight("semi-bold");
  font-size: 16px;
  &:focus {
    outline: none;
  }
  &:hover {
    color: $color-link-hover !important;
  }
}

.easypay-button-link {
  width: fit-content;
  height: 45px;
  border: none;
  color: $color-primary;
  background-color: transparent;
  text-decoration: underline;
  @include weight("semi-bold");
  font-size: 16px;
  &:focus {
    outline: none;
  }
  &:hover {
    color: $color-link-hover !important;
  }
}

.easypay-button-link-disabled {
  width: fit-content;
  height: 45px;
  border: none;
  color: $color-neutral-4;
  background-color: transparent;
  @include weight("semi-bold");
  font-size: 16px;
  cursor: none;
  &:focus {
    outline: none;
  }
}

.easypay-button-secondary-icon {
  width: 165px;
  height: 45px;
  background-color: $color-neutral-0;
  //box-shadow: 0px 10px 18px $color-shadow-secondary;
  border: 2px solid $color-primary;
  border-radius: 23px;
  color: $color-primary;
  @include weight("bold");
  font-size: 16px;
  span {
    display: flex;
    justify-content: space-between;
    margin: 0 20px;
  }
  &:hover {
    background: $color-neutral-1 0% 0% no-repeat padding-box;
    //box-shadow: none;
  }
  &[disabled] {
    background: $color-neutral-2 0% 0% no-repeat padding-box;
    border: 2px solid $color-neutral-3;
    color: $color-neutral-4;
    //box-shadow: none;
  }
  &.easypay-button-disabledColored {
    background: $color-neutral-0 0% 0% no-repeat padding-box;
    //box-shadow: 0px 10px 18px $color-shadow-disabled;
    border: 2px solid $color-primary-disabled;
    color: $color-primary-disabled;
  }
  &:focus {
    outline: none;
  }
}

.easypay-button-upload-img {
  &.easypay-button-img-disabledColored {
    opacity: 50%;
  }
}

.easypay-button-primary {
  background: $color-primary 0% 0% no-repeat padding-box;
  //box-shadow: 0px 10px 18px $color-shadow-primary;
  border: 2px solid $color-primary;
  border-radius: 23px;
  opacity: 1;
  color: $color-neutral-0;
  min-width: 132px;
  padding: 0px 22px;
  height: 35px;
  &.easypay-error {
    background: $color-error 0% 0% no-repeat padding-box;
    //box-shadow: 0px 10px 18px $color-shadow-error;
    border: 2px solid $color-error;
    border-radius: 34px;
  }
  &.easypay-success {
    background: $color-success 0% 0% no-repeat padding-box;
    //box-shadow: 0px 10px 18px $color-shadow-success;
    border: 2px solid $color-success;
    border-radius: 34px;
  }
  &:hover {
    //box-shadow: none;
  }
  &[disabled] {
    background: $color-neutral-2 0% 0% no-repeat padding-box;
    border: 2px solid $color-neutral-2;
    //box-shadow: none;
    color: $color-neutral-4;
  }
  &.easypay-button-disabledColored {
    background: $color-primary-disabled 0% 0% no-repeat padding-box;
    //box-shadow: 0px 10px 18px $color-shadow-disabled;
    border: 2px solid $color-primary-disabled;
    color: $color-neutral-0;
  }
  &:focus {
    outline: none;
  }
}

.easypay-button-add {
  width: fit-content;
  height: 40px;
  margin-bottom: 60px;
  @include weight("bold");
  font-size: 20px;
  color: $color-secondary;
  background-color: $color-neutral-0;
  border-radius: 22px;
  outline: none;
  &:focus {
    outline: none;
  }
  border: $color-neutral-0;
  &[disabled] {
    color: $color-neutral-4;
  }
}

.easypay-button-add-img {
  border-radius: 50%;
  //box-shadow: 0px 10px 18px $color-shadow-add;
  &.easypay-document-img {
    //box-shadow: 0px 10px 18px $color-shadow-primary;
  }
  margin-right: 10px;
  margin-bottom: 5px;
  &[disabled],
  &:hover {
    //box-shadow: none;
  }
}

.easypay-button-secondary-icon-large {
  width: 380px;
  height: 45px;
  background-color: $color-neutral-0;
  //box-shadow: 0px 10px 18px $color-shadow-secondary;
  border: 2px solid $color-primary;
  border-radius: 23px;
  color: $color-primary;
  @include weight("bold");
  font-size: 16px;
  margin-top: 20px;
  span {
    display: flex;
    justify-content: space-between;
    margin: 0 20px;
  }
  &:hover {
    background: $color-neutral-1 0% 0% no-repeat padding-box;
    //box-shadow: none;
  }
  &[disabled] {
    background: $color-neutral-2 0% 0% no-repeat padding-box;
    border: 2px solid $color-neutral-3;
    color: $color-neutral-4;
    //box-shadow: none;
  }
  &.easypay-button-disabledColored {
    background: $color-neutral-0 0% 0% no-repeat padding-box;
    //box-shadow: 0px 10px 18px $color-shadow-disabled;
    border: 2px solid $color-primary-disabled;
    color: $color-primary-disabled;
  }
  &:focus {
    outline: none;
  }
}

.easypay-button-secondary-icon-medium {
  width: 230px;
  height: 45px;
  background-color: $color-neutral-0;
  //box-shadow: 0px 10px 18px $color-shadow-secondary;
  border: 2px solid $color-primary;
  border-radius: 23px;
  color: $color-primary;
  @include weight("bold");
  font-size: 16px;
  margin-top: 20px;
  span {
    display: flex;
    justify-content: space-between;
    margin: 0 20px;
  }
  &:hover {
    background: $color-neutral-1 0% 0% no-repeat padding-box;
    //box-shadow: none;
  }
  &[disabled] {
    background: $color-neutral-2 0% 0% no-repeat padding-box;
    border: 2px solid $color-neutral-3;
    color: $color-neutral-4;
    //box-shadow: none;
  }
  &.easypay-button-disabledColored {
    background: $color-neutral-0 0% 0% no-repeat padding-box;
    //box-shadow: 0px 10px 18px $color-shadow-disabled;
    border: 2px solid $color-primary-disabled;
    color: $color-primary-disabled;
  }
  &:focus {
    outline: none;
  }
}

.easypay-button-approve-img {
  margin-right: 10px;
  margin-bottom: 3px;
}

.easypay-document {
  font-size: 18px;
  color: $color-primary;
  &[disabled] {
    color: $color-neutral-3;
  }
}

.easypay-button-icon-text {
  @include weight("semi-bold");
  font-size: 16px;
}

.easypay-22px-text {
  font-size: 22px;
}

.easypay-20px-text {
  font-size: 20px;
}
