@import "liftworldVariables.scss";

.liftworld-title {
  justify-content: center;
  display: flex;
  padding-left: 15px;
  padding-right: 5px;
  color: #838383;
  align-items: center;
  border-radius: 23px;
  font-weight: bold;
  height: 30px;
  width: 100%;
}

.liftworld-accordion-icon {
  padding-bottom: 5px;
  padding-right: 5px;
  padding-left: 5px;
  &.rotate {
    transform: rotate(180deg);
  }
}