@import "variables.scss";

.easypay-language-dropdown {
  z-index: 0;
  width: 50px;
  margin-right: 20px;
  border-radius: 20px;
}

.easypay-language-dropdown-button {
  background: #ffffff;
  color: $color-secondary;
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: space-between;
  @include weight("semi-bold");
  font-size: 18px;
}

.easypay-language-button-img {
  margin-left: 7px;
  margin-top: 3px;
}

.easypay-language-dropdown-content {
  position: absolute;
  display: block;
  z-index: 0;
  border: none;
  background: $color-neutral-0;
  box-shadow: 0px 3px 10px $color-shadow-secondary;
  border-radius: 4px;
  width: 70px;
  height: 128px;
  margin-left: 10px;
  padding-top: 4px;
  padding-bottom: 4px;
  overflow-y: scroll;
}

.easypay-language-dropdown-content::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.easypay-language-dropdown-content {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.easypay-language-dropdown-content .easypay-language-dropdown-item {
  height: 40px;
  display: flex;
  justify-content: flex-start;
  padding-left: 20px;
  align-items: center;
  cursor: pointer;
  transition: all 0.2s;
  border-bottom: 0.2px solid $color-neutral-2;
  &.easypay-last {
    border: none;
  }
  @include weight("regular");
  font-size: 14px;
}

.easypay-language-dropdown-content .easypay-language-dropdown-item:hover {
  background: $color-neutral-1;
  color: $color-secondary;
}
