#document-table > tbody > tr > td {
  cursor: pointer;
}
#document-table-exportabsence {
  display: none;
}
#document-table-exportabsence > tbody > tr > td {
  cursor: pointer;
}

@media print {
  body * {
    visibility: hidden;
  }
  #document-table-exportabsence {
    display: block;
  }
  #document-table-exportabsence,
  #document-table-exportabsence * {
    visibility: visible;
  }
  #document-table-exportabsence {
    position: absolute;
    left: 0;
    top: 0;
  }
}

.settings-hover-pointer:hover {
  cursor: pointer;
}

.datacapture-look-editable:hover {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 25%);
}

.dataCapture_inputs {
  margin-top: 32px;
}

.dataCapture_secondContainer {
  margin-bottom: 0.5rem;
  margin-left: 10px;
  display: flex;
}

.mobile_group_buttons {
  display: flex;
}

.mobile_buttons {
  display: flex;
}

.button_upload {
  margin-bottom: 0;
}

.button_export {
  margin-bottom: 0;
}

@media only screen and (max-width: 991px) {
  .main-card-v2 {
    max-width: 100%;
    padding-right: 10px;
    padding-left: 10px;
    width: 100vw;
  }

  .card-flat {
    padding-right: 10px !important;
    padding-left: 10px !important;
  }

  .dataCapture_inputs {
    margin-top: 15px;
  }

  .dataCapture_title {
    text-align: center;
    font-size: 14px;
    display: flex;
    justify-content: center;
  }

  .dataCapture_groupButtons {
    display: flex;
    justify-content: center;
  }

  .dataCapture_labels {
    font-size: 14px;
  }

  .custom-table table {
    table-layout: auto !important;
  }

  .card_group {
    display: flex;
    align-items: baseline;
    font-size: 12px;
  }

  .card_group2 {
    display: flex;
    flex-direction: column;
  }

  .card_label {
    margin-right: 5px;
    font-size: 12px;
  }

  .card_main {
    display: none;
  }

  .mobile_buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .mobile_group_buttons {
    display: flex;
    flex-direction: column;
  }
  /* .card_container{
    
  } */
  .custom-button {
    font-weight: 200 !important;
  }

  .button_upload {
    margin-bottom: 10px;
  }

  .button_export {
    margin-bottom: 10px;
  }

  .searchbar {
    display: flex;
    justify-content: center;
    width: 100%;
  }

  .dataCapture_secondContainer {
    margin-bottom: 0.5rem;
    margin-left: 0;
    display: flex;
    justify-content: center;
  }

  .dataCapture_container {
      display: flex;
      align-items: center;
      flex-wrap: nowrap;
      justify-content: center;
  }
  
  @media (max-width: 768px) {
      .dataCapture_container {
          flex-wrap: wrap;
          gap: 1rem
      }
  }

  .element-input-date-bottom {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 85px;
  }

  .element-input-date-buttons {
    display: flex;
    margin-left: unset;
  }

  .adatacapture-metadata-item-row {
    display: flex;
    flex-direction: column;
  }

  .btn {
    font-size: 13px !important;
  }

  .form-control {
    font-size: 12px !important;
  }
  @media only screen and (max-width: 991px) {
    .form-control {
      font-size: unset !important;
    }
  }

  .element-input-wrapper-date {
    overflow: inherit;
  }

  .title_exportModal {
    font-size: 14px;
  }
  .element-input-date-main-calendar {
    padding-left: 40px;
  }
  .custom-table2 {
    transform: scale(0.8) !important;
    transform-origin: 0 0 !important;
  }

  .table thead th {
    font-size: 12px;
  }

  /* .react-bootstrap-table {
    transform: scale(0.8);
    transform-origin: 0 0;
  } */
}

@media only screen and (max-width: 610px) {
  .main-card-v2 {
    max-width: 100%;
  }

  .dataCapture_inputs {
    margin-top: 15px;
  }
}
