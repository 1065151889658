.toPrint {
  display: none;
}

@supports (display: grid) {
  @media print {
    .simulator__buttons Button,
    .simulator__errors,
    .simulator__EditedButtons,
    .simulator_tooltipHelper,
    .simulator__warning,
    .simulator__warning2,
    .dropDownNotPrint {
      display: none;
    }
    .simulator__org {
      display: contents;
    }

    .toPrint {
      display: block;
    }

    .simulator__org {
      display: flex;
    }
    #aqui list {
      display: none;
    }
    .simulator__footer {
      padding-top: 250px;
    }
  }
}
@media (min-width: 1020px) {
  .simulator__org {
    display: flex;
  }

  .simulator_submitButton {
    padding-bottom: 15px;
  }

  .simulator__buttons {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    margin: 70px 0 0;
  }

  .simulator__EditedButtons {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 70px 0 0;
  }

  .simulator__EditedButtons Button {
    margin: 0 10px 0;
  }

  .simulator__buttons Button {
    margin: 0 10px 0;
  }

  .simulator__labels {
    margin: 15px 0 15px;
    white-space: nowrap;
  }

  /* .react-calendar {
    min-width: 150%;
  } */

  .simulator__errors {
    color: rgb(211, 8, 8);
    font-weight: bold;
    margin: 5px 0 -5px 40px;
    font-size: 12px;
    white-space: nowrap;
  }

  .simulator_NewSimulationButton {
    margin: 20px;
    display: flex;
    justify-content: flex-end;
  }

  .simulator_searchBar {
    padding-bottom: 10px;
    padding-top: 10px;
    display: flex;
  }
}

@page {
  size: auto;
  margin: 30mm;
}
/* Mobile */
@media (max-width: 1247px) {
  .simulator__secButtons {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 0px;
  }

  /* .toPrint {
    display: none;
  } */

  .simulator__buttons Button {
    margin: 10px 10px 0;
  }

  .simulator__buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 30px 0 0;
  }
  .simulator__EditedButtons {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 30px 0 0;
  }
  .simulator__EditedButtons Button {
    margin: 10px 10px 0;
  }
  .simulator__errors {
    color: rgb(211, 8, 8);
    font-weight: bold;
    margin: 5px 0 -5px 40px;
    font-size: 12px;
  }

  .simulator_NewSimulationButton {
    margin: 20px;
    display: flex;
    justify-content: center;
  }

  .simulator_searchBar {
    padding-bottom: 10px;
    padding-top: 10px;
    display: flex;
    justify-content: center;
  }

  .simulator_tableList {
    zoom: 50%;
  }
}

@media (max-width: 600px) {
  .simulator_tableList {
    zoom: 100%;
  }
}

.simulators_buttons {
  margin: 20px;
  display: flex;
  justify-content: flex-end;
}

.simulator_subtitles {
  font-weight: bold;
  color: #4484f4;
  margin-top: 30px;
}

.simulator_tooltipLabel {
  display: flex;
  align-items: center;
}

.simulator__warning {
  color: rgba(255, 166, 0, 0.774);
  font-weight: bold;
  margin: 20px 0 0 40px;
  font-size: 12px;
  color: rgb(211, 8, 8);
}

.simulator__warning2 {
  color: rgba(255, 166, 0, 0.774);
  font-weight: bold;
  margin: 0 0 0 40px;
  font-size: 12px;
  color: rgb(211, 8, 8);
  text-align: justify;
}

.simulator__sucess {
  color: green;
}

.simulator__footer h7 {
  display: flex;
  justify-content: space-around;
  margin: 80px 0 30px;
  font-weight: bold;
  color: #4484f4;
}

.simulator__footer p {
  text-align: justify;
  border: 1px solid;
  padding: 10px;
}

.simulator__tooltip2 {
  display: flex;
  flex-direction: column-reverse;
  align-items: flex-start;
}

.simulator__tooltip {
  display: flex;
  align-items: baseline;
}

.simulator_subtitleOutputs {
  margin-top: 10px;
}

.simulator__title {
  font-weight: bold;
  color: #4484f4;
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
}

.simulator__buttons {
  display: flex;
  flex-wrap: wrap;
  margin: 70px 0 0;
  flex-direction: column;
  align-items: center;
}
