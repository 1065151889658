@import "variables.scss";

.easypay-tab-button-wrapper-separator {
  display: flex;
  .easypay-tab-separator {
    .easypay-tab-button {
      width: 240px;
    }
  }
}
