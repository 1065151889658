@import "variables.scss";

.easypay-header {
  display: flex;
  position: fixed;
  justify-content: space-between;
  align-items: center;
  z-index: 5;
  width: 100%;
  flex-direction: row;
  height: 100px;
  padding-right: 320px;
  padding-left: 320px;
  background: $color-neutral-0 0% 0% no-repeat padding-box;
  box-shadow: 0px 5px 20px #c4c4c433;
}

::-webkit-scrollbar {
  width: 0px;
}

.easypay-header-top_logo {
  top: 40px;
}

.easypay-header-logo {
  height: 30px;
  opacity: 1;
}

.easypay-header-top_navigation {
  display: flex;
  align-items: center;
  flex-direction: row;
}

.easypay-header-links {
  display: flex;
}

.easypay-header-logo-link:hover {
  cursor: pointer;
}

.easypay-logo-height-40px{
  height: 40px;
}

.easypay-header-add-doc-button-wrapper{
  margin-right: 20px;
  display: flex;
  padding-top: 60px;
}
