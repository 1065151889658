@import "variables.scss";

.easypay-backoffice-account-type-header {
  @include weight("bold");
}

.easypay-backoffice-account-type-text {
  color: $color-neutral-4;
}

.easypay-backoffice-host-country-header {
  padding-bottom: 10px;
  @include weight("bold");
}

.easypay-backoffice-account-section-host-country-wrapper {
  padding-top: 30px;
  padding-bottom: 22px;
}

.easypay-backoffice-account-section-entity-type-header {
  padding-top: 30px;
  @include weight("bold");
  padding-bottom: 5px;
}

.easypay-backoffice-account-section-organization-nipc-cae-name-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.easypay-backoffice-account-section-entity-data-header {
  @include weight("bold");
  color: $color-primary;
  padding-bottom: 20px;
}

.easypay-backoffice-account-section-input-wrapper {
  width: 48%;
}

.easypay-backoffice-account-section-cod-validity-wrapper {
  display: flex;
  width: 48%;
  flex-direction: row;
  justify-content: space-between;
}

.easypay-backoffice-account-section-small-input-wrapper {
  width: 48%;
}

.easypay-backoffice-account-section-zipcode-locality-wrapper {
  display: flex;
  width: 48%;
  flex-direction: row;
  justify-content: space-between;
}

.easypay-backoffice-account-section-input-wrapper {
  width: 48%;
}

.easypay-backoffice-account-section-addresses-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
