@import "variables.scss";

.easypay-bullet-wrapper{
  //padding-bottom: 40px;
}

.easypay-signature-bullet-item{
  padding-left: 35px;
  padding-bottom: 15px;
}

.easypay-bullet-with-links-wrapper
{  
  padding-top: 4px;
  padding-left: 10px;
  @include weight("bold");
  font-size: 16px;
  display:flex;
}
.easypay-bullet {
  display: flex;
  z-index: 1;
  padding-bottom: 20px;
}

.easypay-bullet-number {
  display: flex;
  justify-content: center;
  align-content: center;
  background: $color-primary;
  color: $color-neutral-0;
  height: 28px;
  padding-top: 2px;
  @include weight("bold");
  font-size: 18px;
  min-width: 28px;
  border-radius: 14px;
}


.easypay-bullet-content {
  padding-top: 4px;
  padding-left: 10px;
  @include weight("bold");
  font-size: 16px;
}
