@import "theming.scss";

@mixin puzzle($theme) {
  .puzzle-wrapper {
    height: auto;
    display: flex;
    flex-direction: column;
    max-width: 260px;
    position: fixed;
    bottom: 0px;
    border: 0px;
    padding-bottom: 1px;
    z-index: 2;
    /*right: 0px;*/
  }

  /*PUZZLE*/
  .tile1 {
    min-width: 65px;
    min-height: 65px;
    line-height: 65px;

    background-position: 0px 0px;
    text-align: center;
  }

  .tile2 {
    min-width: 65px;
    min-height: 65px;
    line-height: 65px;
    background-position: -65px 0px;

    text-align: center;
  }

  .tile3 {
    min-width: 65px;
    min-height: 65px;
    line-height: 65px;
    background-position: -130px 0px;

    text-align: center;
  }

  .tile4 {
    min-width: 65px;
    min-height: 65px;
    line-height: 65px;
    background-position: -195px 0px;

    text-align: center;
  }

  .tile5 {
    min-width: 65px;
    min-height: 65px;
    line-height: 65px;
    background-position: 0px -65px;

    text-align: center;
  }

  .tile6 {
    min-width: 65px;
    min-height: 65px;
    line-height: 65px;
    background-position: -65px -65px;

    text-align: center;
  }

  .tile7 {
    min-width: 65px;
    min-height: 65px;
    line-height: 65px;
    background-position: -130px -65px;

    text-align: center;
  }

  .tile8 {
    min-width: 65px;
    min-height: 65px;
    line-height: 65px;
    background-position: -195px -65px;

    text-align: center;
  }

  .tile9 {
    min-width: 65px;
    min-height: 65px;
    line-height: 65px;
    background-position: 0px -130px;

    text-align: center;
  }

  .tile10 {
    min-width: 65px;
    min-height: 65px;
    line-height: 65px;
    background-position: -65px -130px;

    text-align: center;
  }

  .tile11 {
    min-width: 65px;
    min-height: 65px;
    line-height: 65px;
    background-position: -130px -130px;

    text-align: center;
  }

  .tile12 {
    min-width: 65px;
    min-height: 65px;
    line-height: 65px;
    background-position: -195px -130px;

    text-align: center;
  }

  .tile13 {
    min-width: 65px;
    min-height: 65px;
    line-height: 65px;
    background-position: 0px -195px;

    text-align: center;
    border: 0px;
  }

  .tile14 {
    min-width: 65px;
    min-height: 65px;
    line-height: 65px;
    background-position: -65px -195px;

    text-align: center;
  }

  .tile15 {
    min-width: 65px;
    min-height: 65px;
    line-height: 65px;
    background-position: -130px -195px;

    text-align: center;
  }

  .tile16 {
    min-width: 65px;
    min-height: 65px;
    line-height: 65px;
    background-position: -195px -195px;

    text-align: center;
  }

  .tile-opacity {
    opacity: 0.9;
  }

  .tile-disabled {
    opacity: 0.66;
  }

  .tile1:hover,
  .tile2:hover,
  .tile3:hover,
  .tile4:hover,
  .tile5:hover,
  .tile6:hover,
  .tile7:hover,
  .tile8:hover,
  .tile9:hover,
  .tile10:hover,
  .tile11:hover,
  .tile12:hover,
  .tile13:hover,
  .tile14:hover,
  .tile15:hover,
  .tile16:hover {
    opacity: 1;
  }

  .puzzle-item {
    display: inline-block;
    line-height: normal;
    color: white;
  }

  /*PUZZLE*/
  .tile-v2-1 {
    min-width: 80px;
    min-height: 80px;
    line-height: 80px;
    background-position: 0px 0px;
    text-align: center;
    width: 80px;
    height: 80px;
  }

  .tile-v2-2 {
    width: 80px;
    height: 80px;
    line-height: 80px;
    background-position: -80px 0px;
    text-align: center;
  }

  .tile-v2-3 {
    width: 80px;
    height: 80px;
    line-height: 80px;
    background-position: -160px 0px;
    text-align: center;
  }

  .tile-v2-4 {
    width: 80px;
    height: 80px;
    line-height: 80px;
    background-position: -240px 0px;
    text-align: center;
  }

  .tile-v2-5 {
    width: 80px;
    height: 80px;
    line-height: 80px;
    background-position: 0px -80px;
    text-align: center;
  }

  .tile-v2-6 {
    width: 80px;
    height: 80px;
    line-height: 80px;
    background-position: -80px -80px;
    text-align: center;
  }

  .tile-v2-7 {
    width: 80px;
    height: 80px;
    line-height: 80px;
    background-position: -160px -80px;
    text-align: center;
  }

  .tile-v2-8 {
    width: 80px;
    height: 80px;
    line-height: 80px;
    background-position: -240px -80px;
    text-align: center;
  }

  .tile-v2-9 {
    width: 80px;
    height: 80px;
    line-height: 80px;
    background-position: 0px -160px;
    text-align: center;
  }

  .tile-v2-10 {
    width: 80px;
    height: 80px;
    line-height: 80px;
    background-position: -80px -160px;
    text-align: center;
  }

  .tile-v2-11 {
    width: 80px;
    height: 80px;
    line-height: 80px;
    background-position: -160px -160px;
    text-align: center;
  }

  .tile-v2-12 {
    width: 80px;
    height: 80px;
    line-height: 80px;
    background-position: -240px -160px;
    text-align: center;
  }

  .tile-v2-13 {
    width: 80px;
    height: 80px;
    line-height: 80px;
    background-position: 0px -240px;
    text-align: center;
    border: 0px;
  }

  .tile-v2-14 {
    width: 80px;
    height: 80px;
    line-height: 80px;
    background-position: -80px -240px;
    text-align: center;
  }

  .tile-v2-15 {
    width: 80px;
    height: 80px;
    line-height: 80px;
    background-position: -160px -240px;
    text-align: center;
  }

  .tile-v2-16 {
    width: 80px;
    height: 80px;
    line-height: 80px;
    background-position: -240px -240px;
    text-align: center;
  }

  .tile-v2-opacity {
    background-color: theme-color($theme, "uniksystemColor");
    opacity: 0.9;
  }

  .tile-v2-disabled {
    opacity: 0.66;
  }

  .tile-v2-1:hover,
  .tile-v2-2:hover,
  .tile-v2-3:hover,
  .tile-v2-4:hover,
  .tile-v2-5:hover,
  .tile-v2-6:hover,
  .tile-v2-7:hover,
  .tile-v2-8:hover,
  .tile-v2-9:hover,
  .tile-v2-10:hover,
  .tile-v2-11:hover,
  .tile-v2-12:hover,
  .tile-v2-13:hover,
  .tile-v2-14:hover,
  .tile-v2-15:hover,
  .tile-v2-16:hover {
    opacity: 1;
  }
}
