@import "liftworldVariables.scss";

.liftworldtable-input-wrapper-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 100%;
  max-width: inherit;
  position: relative;
}

.liftworldtable-input-wrapper {
  position: relative;
  align-items: center;
  padding: 0;
  margin: 0;
  height: 30px;
  width: 100%;
}

.liftworldtable-input-icon {
  position: relative;
  width: 16px;
  height: 12px;
  right: 40px;
}

.liftworldtable-input-field {
  background-color: transparent;
  border: none;
  height: 100%;
  width: 100%;
  border-radius: 23px;
  padding-left: 10px;
  &:focus {
    outline: none;
  }
  &.red {
    color:$liftworld-color-error;
  }
  &.number{
    justify-content: flex-end;
    text-align: right;
  }
}

.liftworldtable-input-field-wrapper {
  width: 100%;
  overflow: hidden;
  height: 30px;
  background-color: $liftworld-color-neutral-0;
  border: 1px solid $liftworld-color-neutral-2;
  outline: 1px solid $liftworld-color-neutral-2;
  align-items: center;
  flex: 1;
  display: flex;
  font-size: 16px;
  @include weight("regular");
  transition: all 0.2s linear;
  &.single {
    border-bottom-right-radius: 12px;
    border-bottom-left-radius: 12px;
  }
  &.start {
    border-bottom-left-radius: 12px;
  }
  &.end {
    border-bottom-right-radius: 12px;
  }
  &.red {
    color:red;
  }
}

.liftworldtable-input-error-message {
  color: $liftworld-color-error;
  padding-left: 20px;
  padding-top: 5px;
  text-align: left;
  @include weight("bold");
  font-size: 14px;
  color: $liftworld-color-error;
  margin-bottom: 0;
}

.liftworldtable-input-size-380 {
  width: 380px;
  margin-bottom: 30px;
}
