.liftworld-data-section-wrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.liftworld-navigation-bottom-wrapper {
  width: 100%;
  justify-content: space-between;
  display: flex;
}

.liftworld-company-accordion-wrapper {
  width: 20%;
}

.liftworld-client-accordion-wrapper {
  width: 30%;
}

.liftworld-table-50-wrapper{
    width: 50%;
}

.liftworld-table-30-wrapper{
    width: 33.3333333%;
}

.liftworld-table-60-wrapper{
  width: 60%;
}

.liftworld-table-66-wrapper{
  width: 66.66666666%;
}

.liftworld-table-25-wrapper{
    width: 25%;
}

.liftworld-table-3-wrapper{
  width: 4%;
}

.liftworld-table-9-wrapper{
  width: 8.7999999%;
}

.liftworld-table-8-wrapper{
  width: 8.25%;
}

.liftworld-table-7-wrapper{
  width: 7%;
}
.liftworld-table-11-wrapper{
  width: 11%;
}

.liftworld-table-13-wrapper{
  width: 13%;
}

.liftworld-proposal-accordion-wrapper{
    width: 40%;
}

.liftworld-table-100-wrapper{
  width: 100%;
}

.liftworld-table-20-wrapper{
  width: 20%;
  border: 0px;
}

.liftworld-table-80-wrapper{
  width: 80%;
}