@import "liftworldVariables.scss";

.liftworld-table-textbox-value-wrapper
{
    height: 30px;
    background: $liftworld-color-neutral-0;
    justify-content: flex-start;
    text-align: center;
    display: flex;
    border: 1px solid $liftworld-color-neutral-2;
    outline: 1px solid $liftworld-color-neutral-2;
    padding-left: 8px;
    padding-right: 8px;
    width: 100%;
    max-width: inherit;
    position: relative;
    @include weight("regular");
    align-items: center;
    font-size: 17px;  
    text-overflow: ellipsis;
    /* Required for text-overflow to do anything */
    white-space: nowrap;
    vertical-align: middle;
    overflow:hidden;
    &.single {
      border-top-right-radius: 12px;
      border-top-left-radius: 12px;
    }
    &.start {
      border-top-left-radius: 12px;
    }
    &.start-end {
      border-bottom-left-radius: 12px;
    }
    &.end {
      border-bottom-right-radius: 12px;
    }
    &.top-end{
      border-top-right-radius: 12px;
    }
    &.colored{
      background: $liftworld-color-neutral-3;
    }
    &.number
    {
      justify-content: flex-end;
      min-width: fit-content;
    }
    &.hours
    {
      justify-content: center;
    }
    &.bold{
      @include weight("bold");
    }
    &.error{
      background: $liftworld-color-error;
    }
    &.borderless{
      border: 0px;
    }
}