.rbc-calendar{
    display: grid;
}
.rbc-header{
    padding: 20px;
    align-self: flex-start;
    border-bottom: 0px;
}
.rbc-header.rbc-today{
    background-color: #eaf6ff;
}
.rbc-today{
    background-color: transparent;
}
.rbc-time-view{
    margin-bottom: 1rem;
}
.rbc-time-view .rbc-row{
    min-height: auto;
}
.rbc-button-link{
    font-weight:  bold;
}

.rbc-current-time-indicator{
    height: 2px;
}
.rbc-time-view .rbc-time-gutter{
    text-align: center;
    font-weight: 100;
    color: #a0aec0;
}
.rbc-event{
    background-color: transparent;
    color: #3174ad;
}
.rbc-event:focus {
    outline: none;
  }
.rbc-day-slot .rbc-event, .rbc-day-slot .rbc-background-event{
    display: flow;
}
.rbc-event-content{
    line-height: 2;
    font-size: 15px;
}
.rbc-event-label{
    font-size: 10px;
}
.rbc-month-view{
    display: contents;
}
/* .rbc-month-view .rbc-event-content{
    display: none;
} */
.rbc-date-cell{
    padding: 10px;
    z-index: 1000;
}
.rbc-month-view .rbc-month-row .rbc-row-content .rbc-row .rbc-date-cell.rbc-now{
    color:rgb(11, 102, 41)
}

/* .rbc-now .dh-item a{
    background: rgba(125,124,128, 0.298);
    color:rgb(125,124,128);
    border: 2px solid rgb(125,124,128);
    border-radius: 20px;
    padding: 5px 6px;
    width: 40px;
} */
.rbc-month-view .rbc-month-row .rbc-row-content .rbc-row .rbc-date-cell.rbc-now.rbc-current .dh-item a{
    background: rgb(0 0 0 / 66%);
    color: rgb(255 255 255);
    border: 2px solid rgb(0 0 0);
    border-radius: 20px;
    padding: 5px 6px;
    width: 40px;
}

.rbc-month-view .rbc-month-row .rbc-row-content .rbc-row .rbc-date-cell .rbc-button-link {
    pointer-events: none;
  }

  .custom-date-header {
    display: flex;
    flex-direction: row;
    padding: 5px;
  }
  
  .dh-item {
    flex: 1 1 0;
  }
  
  .header-left {
    text-align: left;
  }

  .rbc-overlay{
    z-index: 4000;
  }

.rbc-show-more{
    display: none!important;
  }

  .popupP{
    margin: 0px;
    font-size: small;
    border-radius: 10px;
    padding: 10px;
  }

  .card-button-redStyle{
    background-color: #dc3545!important;
    border: 0px!important;
    border-radius: 23px!important;
    padding: 6px 18px!important;
    min-height: 35px !important;
    height: 35px !important;
  }
  .card-button-redStyle:hover {
    background-color: brown !important;
    border: 0px;
  }
  .card-button-Style{
    background-color: #3a6cc2!important;
    border: 0px!important;
    border-radius: 23px !important;
    padding: 6px 18px!important;
    min-height: 35px !important;
    width: auto!important;
    height: 35px !important;
  }
  .card-button-Style:hover {
    background-color: #2d5294 !important;
    border: 0px;
  }
  .iconToClose{
    font-size: 1.5rem!important;
    font-weight: 700!important;
    line-height: 1!important;
    color: #000!important;
    text-shadow: 0 1px 0 #fff!important;
    opacity: .5!important;
  }
  .iconToClose:hover {
    opacity: 1;
  }