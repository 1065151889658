@import "variables.scss";

.easypay-wizard-wrapper {
  display: flex;
  justify-content: center;
  padding-bottom: 60px;
}

.easypay-wizard-badge-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.easypay-wizard-badge {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: 38px;
  height: 38px;
  padding-top: 4px;
  color: $color-neutral-0;
  @include weight("semi-bold");
  font-size: 24px;
  background-color: $color-neutral-4;
  &.easypay-active {
    background-color: $color-primary;
  }
  &[disabled] {
    background-color: $color-neutral-3;
  }
}

.easypay-wizard-separator-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 13.5px;
}

.easypay-wizard-separator {
  width: 100px;
  height: 0;
  border: 1px dashed $color-neutral-3;
  opacity: 1;
}

.easypay-wizard-text {
  @include weight("bold");
  color: $color-neutral-3;
  font-size: 14px;
  padding-top: 43px;
  position: absolute;
}

.easypay-wizard-active-text {
  color: $color-primary;
}
