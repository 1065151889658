.docTableUnseen {
  background-color: white;
  font-weight: 500;
}

.docTableSeen {
  background-color: WhiteSmoke;
  font-weight: normal;
}

.docTableManagement thead tr {
  text-align: center;
  overflow-wrap: break-word;
  border-bottom: 1px solid whitesmoke;
}

.docTableManagement tbody tr {
  border-bottom: 1px solid whitesmoke !important;

  &:hover {
    z-index: 5;
    position: relative;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.75);
    cursor: pointer;
  }
}