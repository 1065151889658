@import "variables.scss";
@import "theming.scss";
@import "_responsive.scss";
@import "_navbar.scss";
@import "_spinner.scss";
@import "_cardv2.scss";
@import "_form.scss";
@import "_table.scss";
@import "_modal.scss";
@import "_toast.scss";
@import "_avatar.scss";
@import "_chat.scss";
@import "_breadcrumb.scss";
@import "_puzzle.scss";
@import "_tabs.scss";
@import "_button.scss";
@import "_dropdown.scss";
@import "_searchableDropdown.scss";
@import "_menu.scss";
@import "_menuv2.scss";
@import "_login.scss";
@import "_widget.scss";
@import "_datepicker.scss";
@import "_task.scss";
@import "_richtextWrapper.scss";
@import "_stamp.scss";

@mixin defaultTheme() {
  .default {
    @content;
    background-image: theme-color("default", "contentWrapperBackgroundImage");
  }
}

@mixin darkTheme() {
  .dark {
    @content;
    hr {
      border-color: #4d4d4d;
    }
    background-image: theme-color("dark", "contentWrapperBackgroundImage");
  }
}

@mixin cmaTheme() {
  .cma {
    @content;
    background-image: theme-color("cma", "contentWrapperBackgroundImage");
  }
}
@mixin nblpTheme() {
  .nblp {
    @content;
    background-image: theme-color("nblp", "contentWrapperBackgroundImage");
  }
}

@include defaultTheme() {
  @include navbar("default");
  @include spinner("default");
  @include breadcrumb("default");
  .main-card-v2 {
    @include cardv2("default");
    @include form("default");
    @include table("default");
  }
  @include menu("default");
  @include menuv2("default");
  @include chat("default");
  @include puzzle("default");
  @include tabs("default");
  @include button("default");
  @include dropdown("default");
  @include searchableDropdown("default");
  @include widget("default");
  @include datepicker("default");
  @include task("default");
  @include richtextWrpper("default");
}

@include darkTheme() {
  @include navbar("dark");
  @include spinner("dark");
  @include breadcrumb("dark");
  .main-card-v2 {
    @include cardv2("dark");
    @include form("dark");
    @include table("dark");
  }
  @include menu("dark");
  @include menuv2("dark");
  @include chat("dark");
  @include puzzle("dark");
  @include tabs("dark");
  @include button("dark");
  @include dropdown("dark");
  @include searchableDropdown("dark");
  @include widget("dark");
  @include datepicker("dark");
  @include task("dark");
  @include richtextWrpper("dark");
}

@include cmaTheme() {
  @include navbar("cma");
  @include spinner("cma");
  @include breadcrumb("cma");
  .main-card-v2 {
    @include cardv2("cma");
    @include form("cma");
    @include table("cma");
  }
  @include menu("cma");
  @include menuv2("cma");
  @include chat("cma");
  @include puzzle("cma");
  @include tabs("cma");
  @include button("cma");
  @include dropdown("cma");
  @include searchableDropdown("cma");
  @include widget("cma");
  @include datepicker("cma");
  @include task("cma");
  @include richtextWrpper("cma");
}
@include nblpTheme() {
  @include navbar("nblp");
  @include spinner("nblp");
  @include breadcrumb("nblp");
  .main-card-v2 {
    @include cardv2("nblp");
    @include form("nblp");
    @include table("nblp");
  }
  @include menu("nblp");
  @include menuv2("nblp");
  @include chat("nblp");
  @include puzzle("nblp");
  @include tabs("nblp");
  @include button("nblp");
  @include dropdown("nblp");
  @include searchableDropdown("nblp");
  @include widget("nblp");
  @include datepicker("nblp");
  @include task("nblp");
  @include richtextWrpper("nblp");
}

body.default {
  @include modal("default");
  @include toast("default");
}

body.dark {
  @include modal("dark");
  @include toast("dark");
}

body.cma {
  @include modal("cma");
  @include toast("cma");
}
