@import "variables.scss";

.easypay-breadcrumb-wrapper {
  display: flex;
  width: fit-content;
}

.easypay-breadcrumb-separator {
  width: 0px;
  height: 21px;
  border: 1px solid $color-separator;
  opacity: 1;
  margin: 0px 10px;
}

.easypay-breadcrumb-button {
  width: fit-content;
  text-align: left;
  text-decoration: underline;
  font-style: normal;
  font-variant: normal;
  @include weight("semi-bold");
  line-height: 22px;
  
  font-size: 16px;
  letter-spacing: 0px;
  color: $color-primary;
  opacity: 1;
  background-color: transparent;
  border: none;
  &:hover {
    color: $color-link-hover;
  }
  &[disabled] {
    color: $color-neutral-4;
  }
  &:focus {
    outline: none;
  }
}

.easypay-breadcrumb-item-wrapper{
  display: flex;
  flex-direction: row;
}
