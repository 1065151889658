.loader-wrapper {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 4000;
  background-color: #242f3fa8;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.transparent {
  background-color: #242f3fa8;
}

.opaque {
  background-color: #efefef;
}

.loader {
  z-index: 1;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.bar {
  display: inline-block;
  margin: 2px;
  border-radius: 2px;
}

.lg {
  width: 4px;
  height: 35px;
}

.md {
  width: 3px;
  height: 20px;
}

.sm {
  width: 2px;
  height: 15px;
}

/*
.bar-no-wrapper {
  background-color: #4484f4;
}*/

.bar-wrapper {
  background-color: #fff;
}

.bar-1 {
  animation: 1s cubic-bezier(0.2, 0.68, 0.18, 1.08) 0.1s infinite normal both
    running bar-animation;
}

.bar-2 {
  animation: 1s cubic-bezier(0.2, 0.68, 0.18, 1.08) 0.2s infinite normal both
    running bar-animation;
}

.bar-3 {
  animation: 1s cubic-bezier(0.2, 0.68, 0.18, 1.08) 0.3s infinite normal both
    running bar-animation;
}

.bar-4 {
  animation: 1s cubic-bezier(0.2, 0.68, 0.18, 1.08) 0.4s infinite normal both
    running bar-animation;
}

.bar-5 {
  animation: 1s cubic-bezier(0.2, 0.68, 0.18, 1.08) 0.5s infinite normal both
    running bar-animation;
}

@keyframes bar-animation {
  0% {
    transform: scaleY(1);
  }
  50% {
    transform: scaleY(0.4);
  }
  100% {
    transform: scaleY(1);
  }
}
