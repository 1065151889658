.element-input-date-wrapper {
  display: flex;
  align-items: center;
  height: 44px !important;
}

.element-input-date-label {
  padding-left: 22px;
  padding-right: 60px;
  padding-bottom: 10px;
  color: #4c4b4b;
  align-items: center;
  display: flex;
  font-style: normal;
  font-variant: normal;
  font-weight: bold;
  font-size: 17px;
}

.element-input-field-date {
  height: 45px !important;
  border: none;
  text-align: center;
  color: #4c4b4b;
  &.left {
    margin-left: 10px;
  }

  &:focus {
    outline: none;
  }
}

.element-date-separator-wrap {
  height: 20px;
  width: 1px;
  background-color: gray;
  margin-left: 10px;
}

.element-input-wrapper-date {
  min-width: 305px;
  justify-content: center;
  overflow: hidden;
  display: flex;
  align-items: center;
  padding: 0;
  margin: 0;
  border-radius: 23px;
  height: 30px;
  width: 100%;
  text-align: center;
  transition: all 0.2s linear;

  &.disable {
    background-color: rgba(239, 239, 239, 0.3) !important;
  }

  > input {
    background-color: transparent !important;
  }

  input[type="date"]::-webkit-inner-spin-button,
  input[type="date"]::-webkit-calendar-picker-indicator {
    display: none;
    -webkit-appearance: none;
  }

  &.active,
  &:focus-within {
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  }

  > .element-input-calendar-icon-wrapper {
    flex: 0 100px;
    cursor: pointer;
  }

  &[disabled] {
    background-color: transparent;
  }
}
