@import "variables.scss";

.easypay-document-item-wrapper {
  display: flex;
  width: 100%;
  justify-content: space-between;
  flex-direction: row;
}

.easypay-document-info-wrapper {
  width: 500px;
  display: flex;
  flex-direction: column;
}

.easypay-document-submitted-wrapper-wrapper {
  display: flex;
  justify-content: center;
}

.easypay-document-submitted-wrapper {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.easypay-signature-document-submitted-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.easypay-documen-icon-wrapper {
  display: flex;
}

.easypay-document-text-centered {
  display: flex;
  justify-content: center;
  align-items: center;
}

.easypay-maxwidth-380 {
  width: 380px;
  .easypay-card-text-blue {
    max-width: 330px;
  }
}

.easypay-no-padding{
  padding:0;
  .easypay-card-text-blue {
    min-width: 200px;
  }
}

.easypay-x-icon {
  font-size: 20px;
  color: $color-primary;
  @include weight("semi-bold");
  padding-top: 3px;

  cursor: pointer;
}

.easypay-x-icon-padding-right {
  font-size: 20px;
  color: $color-primary;
  @include weight("semi-bold");
  padding-top: 3px;
  padding-right: 10px;
  cursor: pointer;
}
