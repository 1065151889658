@import "theming.scss";

@mixin menuv2($theme) {
  .menu-wrapper {
    display: flex;
    // height: 100vh;
    overflow-y: auto;
    max-height: 100%;
  }
  .normal-colored {
    background-color: theme-color($theme, "menuMainItemBackgroundColor");
    padding-bottom: 60px;
  }
  .normal-colored.disabled{
    pointer-events: none;
  }

  .menu-div {
    box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.3);
    float: left;
    text-align: center;
  
    @media (max-width: 767px) {
      min-height: 93vh;
      max-height: 93vh;
      overflow-y: auto;
    }
  
    @media (min-width: 768px) {
      min-height: 100vh;
    }
  
    .disabled {
      opacity: 0.5;
    }
  }

  .menu-item {
    color: #fff;
  }
  .menu-item.disabled {
    pointer-events: none;
  }

  .menu-item-wrap {
    min-height: 40px;
    max-height: 70px;
    width: 80px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 12.5px 0;
    font-size: 24px;
  }

  .menu-item-wrap > span {
    margin-top: 3px;
    font-size: 10px;
    font-weight: 500;
    line-height: 1.35;
  }

  .menu-item:hover {
    cursor: pointer;
    background-color: theme-color($theme, "menuMainItemBackgroundColorHover");
  }

  /* Selected */
  .selected {
    background-color: theme-color($theme, "menuMainItemBackgroundColorHover");
    border-left: 3px solid #fff;
  }

  /*lvl 2*/
  .menu-div-lvl2 {
    height: 100%;
    min-height: 89vh;
    max-width: 180px;
    min-width: 180px;
    width: 100%;
    background-color: #e7e7e7;
    float: left;
    text-align: center;

    .title {
      color: theme-color($theme, "menuLvl2BackgroundColor");
      font-size: 1.4em;
      padding: 10px;
      text-align: start;
    }

    /* Menu items lvl 2 */
    .menu-item-wrap-lvl2 {
      width: 170px;
      margin: 1px 5px;
      padding: 0.55rem;
      border: 0px;
      border-radius: 3px;
      text-align: start;
      color: theme-color($theme, "menuLvl2BackgroundColor");
      font-size: 12px;
      line-height: 1;
      font-weight: 500;
      background-color: #fff;
      text-decoration: none;
      white-space: pre-wrap;
      word-break: break-word;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .menu-item-wrap-lvl2:hover {
      cursor: pointer;
    }

    .selected-lvl2 {
      background-color: theme-color($theme, "menuMainItemBackgroundColorHover");
      color: #fff;
    }

    /* Menu items lvl 3 */
    .menu-item-wrap-lvl3 {
      width: 170px;
      margin: 1px 5px;
      padding: 0.55rem 0.55rem 0.55rem 1.2rem;
      border: 0px;
      border-radius: 3px;
      text-align: start;
      color: theme-color($theme, "menuLvl2BackgroundColor");
      font-size: 12px;
      line-height: 1;
      font-weight: 500;
      background-color: #f8f9fa;
      text-decoration: none;
      white-space: pre-wrap;
      word-break: break-word;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .menu-item-wrap-lvl3:hover {
      cursor: pointer;
      font-weight: 700;
    }

    .selected-lvl3 {
      border-left: 3px solid
        theme-color($theme, "menuMainItemBackgroundColorHover");
    }
  }
}
