@import "variables.scss";

//css of manually created divs
.easypay-date-range {
  display: flex;
  flex-direction: column;
  position: absolute;
  justify-content: space-between;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 10px #6d6d6d40;
  border-radius: 11px;
  z-index: 1;
  margin-top: 55px;
}

.easypay-date-bottom {
  display: flex;
  align-items: center;
  height: 85px;
  width: 650px;
}

.easypay-date-predefined-ranges {
  padding-top: 30px;
  padding-left: 20px;
  padding-bottom: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  width: 160px;
  border-right: 1px solid $color-neutral-2;
  opacity: 1;
}
.easypay-date-top {
  display: flex;
  height: 270px;
  width: 650px;
  border-bottom: 1px solid $color-neutral-2;
  opacity: 1;
}
.easypay-date-main-calendar {
  display: flex;
  width: 502px;
  height: 268px;
  padding-left: 85px;
}
.easypay-date-predefined-button {
  &:focus {
    outline: none;
    color: $color-primary;
  }
  &:hover {
    color: $color-primary;
  }
  text-align: left;
  @include weight("semi-bold");
  font-size: 14px;
  color: $color-neutral-4;
  outline: none;
  justify-content: center;
  width: 100px;
  height: 18px;
  align-items: center;
  border: 0;
  display: block;
  padding: 0;
  background: $color-neutral-0;
  white-space: nowrap;
}
.easypay-date-predefined-option {
  outline: none;
  width: 160px;
}

.easypay-selected-dates {
  display: flex;
  width: 200px;
  align-items: center;
}
.easypay-date-link {
  display: flex;
  align-items: center;
  padding: 16px;
  .easypay-link-primary {
    width: 63px;
    height: 21px;
    opacity: 1;
    text-align: left;
    color: $color-primary;
    .easypay-icon-less {
      display: none;
    }
  }
}

.easypay-date-buttons {
  display: flex;
  flex-direction: row;
  margin-left: auto;
}

.easypay-span-selected-dates {
  //font-family: arial;

  width: 180px;
  height: 18px;

  float: left;

  opacity: 1;
  text-align: center;
  @include weight("semi-bold");
  font-size: 14px;
  color: $color-neutral-6;
}
.easypay-date-select-button {
  padding: 16px;
  .easypay-button-primary {
    width: 108px;
    height: 35px;
    padding: 0;

    text-align: center;
    @include weight("semi-bold");
    font-size: 16px;
    color: $color-neutral-0;
    opacity: 1;
  }
}

.rdrInputRanges {
  display: none;
}

//color of days in selected range

.rdrDay:not(.rdrDayPassive) .rdrInRange ~ .rdrDayNumber span,
.rdrDay:not(.rdrDayPassive) .rdrStartEdge span,
.rdrDay:not(.rdrDayPassive) .rdrEndEdge span,
.rdrDay:not(.rdrDayPassive) .rdrSelected ~ .rdrDayNumber span {
  color: $color-neutral-6;
}

.rdrDateDisplayWrapper {
  display: none;
}

//possible safe to remove

.rdrCalendarWrapper:not(.rdrDateRangeWrapper)
  .rdrDayHovered
  .rdrDayNumber:after {
  content: "";
  position: absolute;
  top: 10px;
  bottom: 10px;
  left: 0px;
  right: 0px;
  background: transparent;
}

//css for days in selected range, correcting minor visual bugs
.rdrDayInPreview {
  position: absolute;
  top: 1px;
  left: -15px;
  right: -15px;
  bottom: 1px;
}
//css for first day in selected range border and border size
.rdrDayStartPreview {
  border-radius: 14px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  top: 1px;
  left: 8px;
  right: 8px;
  bottom: 1px;
}
//css for when selecting a the first day of a range and it's displayed as both first and last choice, border and border size
.rdrDayStartPreview.rdrDayEndPreview {
  border-radius: 14px;
  top: 1px;
  left: 8px;
  right: 8px;
  bottom: 1px;
}

//css for last day selected in range, border and border size
.rdrDayEndPreview {
  border-radius: 14px;
  border-bottom-left-radius: 0px;
  border-top-left-radius: 0px;
  top: 1px;
  left: 8px;
  right: 8px;
  bottom: 1px;
}
//css for days at the start of the month or week
//leftmost days in the calendar, only applies when selecting a range, impacts border ending displaying correctly
.rdrDayStartOfMonth,
.rdrDayStartOfWeek {
  .rdrDayEndPreview {
    top: 1px;
    left: 8px;
    right: 8px;
    bottom: 1px;
  }
}
//css for days at the start of the month or week
//leftmost days in the calendar, only applies when selecting a range, impacts border start displaying correctly
.rdrDayEndOfMonth,
.rdrDayEndOfWeek {
  .rdrDayStartPreview {
    top: 1px;
    left: 8px;
    right: 8px;
    bottom: 1px;
  }
}

//css to make month and year static
.rdrMonthAndYearWrapper {
  padding-bottom: 5px;
}
//month and year, correct font and size also capitalize letters
.rdrMonthAndYearPickers {
  select {
    text-align: left;
    @include weight("semi-bold");
    font-size: 14px;
    color: $color-neutral-6;
    text-transform: capitalize;
  }
}

// css for days in range
.rdr {
  &InRange {
    background: $color-neutral-1;
    position: absolute;
    top: 0px !important;
    left: -15px;
    right: -15px;
    bottom: 0px !important;
  }
}
//css to remove blue underline on today's date
.rdrDayToday .rdrDayNumber span:after {
  background: none !important;
}
//css to overwritte imported existing css, causing issues at the start of weeks and months
.rdrDayStartOfWeek .rdrInRange,
.rdrDayStartOfWeek e {
  left: 4px;
}
//css to overwritte imported existing css, causing issues at the end of weeks and months
.rdrDayEndOfWeek .rdrInRange,
.rdrDayEndOfWeek {
  right: 4px;
}
//css to overwritte imported existing css, causing issues at the start of weeks and months
.rdrDayStartOfMonth .rdrInRange,
.rdrDayStartOfMonth .rdrEndEdge,
.rdrDayStartOfWeek .rdrInRange,
.rdrDayStartOfWeek .rdrEndEdge {
  left: 8px;
}
//css to overwritte imported existing css, causing issues at the end of weeks and months
.rdrDayEndOfMonth .rdrInRange,
.rdrDayEndOfMonth .rdrStartEdge,
.rdrDayEndOfWeek .rdrInRange,
.rdrDayEndOfWeek .rdrStartEdge {
  right: 8px;
}
//css for blue background around the selected numbers
.rdrSelected,
.rdrStartEdge,
.rdrEndEdge {
  position: absolute;
  top: 0px;
  left: 8px;
  right: 8px;
  bottom: 0px;
}

//css for numbers in calendar
.rdrDay {
  .rdrStartEdge {
    background: $color-primary 0% 0% no-repeat padding-box;

    border-radius: 13px;
    opacity: 1;
    z-index: 2;
  }
  .rdrEndEdge {
    background: $color-primary 0% 0% no-repeat padding-box;

    border-radius: 13px;
    opacity: 1;
    z-index: 2;
  }
  width: 44px;
  height: 26px;
  &Passive {
    text-align: left;
    @include weight("semi-bold");
    font-size: 14px;
    color: $color-neutral-3;
  }
  &Number {
    text-align: left;
    @include weight("semi-bold");
    font-size: 14px;
    color: $color-neutral-6;
    z-index: 3;
  }
}
//css to align week days, addign padding
.rdrMonth .rdrWeekDays {
  padding: 0.6em 0.833em;
}
//week days total width
.rdrWeekDays {
  width: 327px;
}
//css for each individual week day
.rdrWeekDay {
  width: 29px;
  height: 18px;
  @include weight("semi-bold");
  font-size: 14px;
  color: $color-neutral-5;
  text-transform: capitalize;
}
//css for input, no longer used maybe useful later?
.rdrDateDisplayItem input {
  text-transform: capitalize;
  text-align: left;
  @include weight("semi-bold");
  font-size: 14px;
  color: $color-neutral-6;
}
//css for month name
.rdrMonthName {
  text-align: center;
  @include weight("semi-bold");
  font-size: 14px;
  color: $color-neutral-6;
}
