@import "theming.scss";

@mixin button($theme) {
  .custom-button {
    font-weight: 500;
    min-height: 44px;
    &.btn-outline-primary {
      color: theme-color($theme, "buttonDownload");
      border-radius: theme-color($theme, "inputBorder" !important);
      border: 2px solid theme-color($theme, "buttonDownload");
      padding: 6px 20px;
      box-shadow: none !important;
      transition: background-color 0.2s linear;
      height: 35px;
      align-content: center;
      display: flex!important;
      align-items: center!important;
    }
    &.btn-outline-primary:hover {
      background-color: theme-color($theme, "buttonDownloadHover");
    }

    &.btn-outline-primary:active {
      background-color: #fff !important;
      color: theme-color($theme, "buttonDownload") !important;
      border: 2px solid theme-color($theme, "buttonDownload") !important;
    }

    &.btn-outline-primary[disabled] {
      background-color: theme-color($theme, "buttonDownloadHover");
    }
  }

  .custom-add-button {
    font-weight: 500;
    min-height: 44px;

    > img {
      margin-right: 10px;
      -webkit-filter: drop-shadow(2px 5px 5px #57575740);
      filter: drop-shadow(2px 5px 5px #57575740);
      -webkit-transition: 0.2s -webkit-filter linear;
      -moz-transition: 0.2s -moz-filter linear;
      -moz-transition: 0.2s filter linear;
      -ms-transition: 0.2s -ms-filter linear;
      -o-transition: 0.2s -o-filter linear;
      transition: 0.2s filter linear, 0.2s -webkit-filter linear;
    }

    &.btn-outline-primary {
      color: theme-color($theme, "buttonDownload");
      border-radius: 23px;
      border: none;
      padding: 0px 20px 0px 0px;
      box-shadow: none !important;
      height: 35px;
      align-content: center;    
      display: flex!important;
      align-items: center!important;
    }
    &.btn-outline-primary:hover {
      > img {
        -webkit-filter: drop-shadow(2px 3px 5px #ffffff);
        filter: drop-shadow(2px 3px 5px #ffffff);
      }
      background-color: transparent !important;
    }

    &.btn-outline-primary:active {
      background-color: #fff !important;
      color: theme-color($theme, "buttonDownload") !important;
      border: none !important;
    }
  }

  .custom-remove-button {
    font-weight: 500;
    > img {
      margin-right: 10px;
      -webkit-filter: drop-shadow(2px 5px 5px #88010140);
      filter: drop-shadow(2px 5px 5px #88010140);
      -webkit-transition: 0.2s -webkit-filter linear;
      -moz-transition: 0.2s -moz-filter linear;
      -moz-transition: 0.2s filter linear;
      -ms-transition: 0.2s -ms-filter linear;
      -o-transition: 0.2s -o-filter linear;
      transition: 0.2s filter linear, 0.2s -webkit-filter linear;
    }
    &.btn-outline-primary {
      color: theme-color($theme, "buttonRemove");
      border-radius: 23px;
      border: none;
      padding: 0px 20px 0px 0px;
      box-shadow: none !important;
      height: 35px;
      align-content: center;
      display: flex!important;
      align-items: center!important;
    }
    &.btn-outline-primary:hover {
      > img {
        -webkit-filter: drop-shadow(2px 3px 5px #ffffff);
        filter: drop-shadow(2px 3px 5px #ffffff);
      }
      background-color: transparent !important;
    }

    &.btn-outline-primary:active {
      background-color: #fff !important;
      color: theme-color($theme, "buttonDownload") !important;
      border: none !important;
    }
  }

  ::-webkit-file-upload-button {
    border-radius: theme-color($theme, "inputBorder") !important;
    padding: 6px 18px;
    min-height: 42px;
    background-color: #ffffff;
    border: 2px solid theme-color($theme, "uniksystemColor");
    transition: background-color 0.2s linear;
    &:hover {
      background-color: #f6f6f6;
      cursor: pointer;
    }
  }

  .richtext-control {
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    border: 0px;
    outline: none;
    background-color: transparent;
    color: theme-color($theme, "textColor");
    &.rtx-active {
      font-weight: 500;
      color: theme-color($theme, "buttonDownload");
      border: 0px;
      outline: none;
    }
  }

  .richtext-wrapper select {
    height: 27px;
    color: theme-color($theme, "buttonDownload");
    border: 1px solid #ced4da;
    border-radius: 23px;
    padding: 0px 10px;
    margin-top: 5px;
    margin-right: 5px;
    font-size: 14px;
    font-weight: 500;

    /*
    option[value="placeholder"][disabled] {
      display: none;
    }*/
  }

  .richtext-wrapper select:focus-visible {
    outline: none;
  }

  .richtext-select-control {
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    border: 0px;
    outline: none;
    background-color: transparent;
    font-size: 14px;
    font-weight: 500;
    color: theme-color($theme, "textColor");
  }

  .richtext-wrapper h6 {
    font-size: 1rem !important;
  }

  .close-button_custom {
    display: flex;
    cursor: pointer;
    margin-top: -70px;
    margin-bottom: 40px;
    right: 40px;
    position: absolute;
    top: 120px;
  }

  .close-button_default {
    display: flex;
    cursor: pointer;
    margin-top: -100px;
    margin-bottom: 40px;
    right: 40px;
    position: absolute;
    top: 150px;
  }

  @media (max-width: 991px) {
    .close-button_custom {
      display: flex;
      cursor: pointer;
      margin-top: -70px;
      margin-bottom: 40px;
      right: 0px;
      position: absolute;
      top: 120px;
    }

    .close-button_default {
      display: flex;
      cursor: pointer;
      margin-top: -100px;
      margin-bottom: 40px;
      right: 0px;
      position: absolute;
      top: 150px;
    }
  }
}
