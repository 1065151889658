@import "variables.scss";

.easypay-radio-button {
  height: 21px;
  width: fit-content !important;
  display: flex;
  padding-right: 15px;
  justify-content: space-between;
  align-items: center;
  &[disabled] {
    color: $color-neutral-3;
  }
}

.easypay-radio-button-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  &.column{
    padding-bottom: 10px;
  }
}
.easypay-radio-button-circle {
  width: 20px;
  height: 20px;
  &[disabled] {
    color: $color-neutral-2 !important;
  }
}

.easypay-radio-button-label {
  margin-left: 10px;
  min-width: max-content;
  margin-bottom: 0px;
  color: $color-neutral-6;
  @include weight("semi-bold");
  font-size: 16px;
  &[disabled] {
    color: $color-neutral-3;
  }
}

.easypay-radio-card {
  padding: 20px;
  border: 2px solid $color-neutral-2;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 135px;
  position: relative;
}

.easypay-radio-card-image {
  img {
    max-height: 40px;
    max-width: 80px;
  }
}
.easypay-radio-card-wrapper {
  display: grid;
  grid-template-columns: 33.33% 33.33% 33.33%;
  grid-gap: 8px;
}
.easypay-radio-card-label {
  font-size: 12px;
}

.easypay-radio-card-check {
  position: absolute;
  right: 10px;
  top: 10px;
}
