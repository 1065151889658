@import "liftworldVariables.scss";

.liftworld-input-wrapper-wrapper {
  display: flex;
  align-items: center;
  padding-right: 10px;
}

.liftworld-input-wrapper {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0;
  margin: 0;
  height: 30px;
  width: 100%;
  min-width: 200px;
}
.liftworld-input-filled {
  &:not(:focus-within) {
    border: 2px solid $liftworld-color-neutral-4;
  }
}


.liftworld-input-label {
  padding-left: 7px;
  padding-right: 30px;
  color: $liftworld-color-font-primary;
  align-items: center;
  display: flex;
  @include weight("bold");
  font-size: 16px;
}

.liftworld-input-icon {
  position: relative;
  width: 16px;
  height: 12px;
  right: 40px;
}

.liftworld-input-field {
  background-color: transparent;
  border: none;
  height: 100%;
  width: 100%;
  min-width: 200px;
  align-items: center;
  display: flex;
  justify-content: center;
  font-size: 16px !important;
  border-radius: 23px;
  padding-left: 20px;
  padding-right: 20px;
  &:focus {
    outline: none;
  }
  &.number{
    justify-content: flex-end;
    text-align: right;
  }
}

.liftworld-input-field-wrapper {
  overflow: hidden;
  height: 45px;
  border: 1px solid $liftworld-color-neutral-2;
  outline: 1px solid $liftworld-color-neutral-2;
  background-color: $liftworld-color-neutral-0;
  align-items: center;
  border-radius: 23px;
  flex: 1;
  text-align: left;
  @include weight("regular");
  font-size: 16px !important;
  color: $liftworld-color-neutral-6;
  transition: all 0.2s linear;
  width: 100%;
  &.small{
    max-width: 150px;
  }
  &.disabled {
    background-color: $liftworld-color-neutral-2;
  }
  &.liftworld-error {
    border: 3px solid $liftworld-color-error;
    color: $liftworld-color-error;
  }

  &.liftworld-correct-info {
    border: 3px solid $liftworld-color-font-primary;
  }
}

.liftworld-input-field-date {
  max-width: 130px;
  height: 26px;
  background: $liftworld-color-neutral-0 0% 0% no-repeat padding-box;
  border-radius: 14px;
  opacity: 1;
  padding-left: 20px;
  flex: 1;
  text-align: left;
  @include weight("regular");
  padding-top: 3px;
  font-size: 16px;
  color: $liftworld-color-neutral-6;
  border: none;
  &.right {
    padding-left: 12px;
  }
  &.liftworld-filled {
    border: 2px solid $liftworld-color-neutral-4;
  }

  &.liftworld-correct-info {
    border: 2px solid $liftworld-color-font-primary;
  }
  &.active {
    border: 2px solid $liftworld-color-font-primary;
  }
  &[disabled] {
    background-color: $liftworld-color-neutral-1;
  }

  &:focus {
    outline: none;
    &:not(:read-only) &:not(.liftworld-error) {
      border: 2px solid $liftworld-color-font-primary;
    }
  }

  &.liftworld-error {
    border: 2px solid $liftworld-color-error;
    color: $liftworld-color-error;
  }
}

.liftworld-input-error-message {
  color: $liftworld-color-error;
  padding-left: 20px;
  padding-top: 5px;
  text-align: left;
  @include weight("bold");
  font-size: 14px;
  color: $liftworld-color-error;
  margin-bottom: 0;
}


.liftworld-input-size-380 {
  width: 380px;
  margin-bottom: 30px;
}

.liftworld-input-selected {
  outline: none;
  border: 2px solid $liftworld-color-font-primary;
}
