@import "variables.scss";

.easypay-backoffice-repleg-table-header {
  width: 100%;
  max-width: 1210px;
  padding-left: 40px;
  padding-right: 40px;
  display: flex;
  flex-direction: row;
}

.easypay-backoffice-repleg-table-header-name-wrapper {
  display: flex;
  flex-direction: row;
  @include weight("bold");
  width: 28%;
  cursor: pointer;
}

.easypay-backoffice-repleg-table-header-PEP {
  @include weight("bold");
  width: 10%;
}

.easypay-backoffice-repleg-table-header-role {
  @include weight("bold");
  width: 13%;
}

.easypay-backoffice-repleg-table-header-PDA {
  @include weight("bold");
  width: 16%;
}

.easypay-backoffice-repleg-table-header-prova-vida {
  @include weight("bold");
  width: 21%;
}

.easypay-backoffice-repleg-table-header-mobile {
  @include weight("bold");
  width: 12%;
}

.easypay-backoffice-repleg-table-item-wrapper {
  width: 100%;
  max-width: 1210px;
  padding-left: 40px;
  padding-right: 40px;
  height: 90px;
  align-items: center;
  display: flex;
  flex-direction: row;
}
.easypay-backoffice-repleg-table-item-name-wrapper {
  width: 28.5%;
}
.easypay-backoffice-repleg-table-item-name {
  @include weight("bold");
}

.easypay-backoffice-repleg-table-item-nif {
  color: $color-neutral-4;
}

.easypay-backoffice-repleg-table-item-pep {
  color: $color-neutral-4;
  font-size: 50px;
  width: 9.5%;
}

.easypay-backoffice-repleg-table-item-role {
  color: $color-neutral-4;
  width: 13%;
  &.easypay-backoffice-role-empty {
    font-size: 50px;
    justify-content: center;
    padding-left: 8px;
  }
}

.easypay-backoffice-repleg-table-item-pda{
    width: 16%;
}

.easypay-backoffice-repleg-table-item-prova{
    width: 21%;
}

.easypay-backoffice-repleg-table-item-prova-sub-wrapper{
    width: 110px;
    justify-content: center;
    display: flex;
}

.easypay-backoffice-repleg-table-item-mobile{
    display: flex;
    width : 12%;
    color: $color-neutral-4;
    justify-content: center;
}