@import "liftworldVariables.scss";

.liftworld-proposal-wrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-top: 20px;
}

@media print {

  .liftworld-normal-text-wrapper,
  .liftworld-text-wrapper-proposal,
  .liftworld-table-title-wrapper,
  .liftworld-input-label {
    font-size: 14px;
  }

  .liftworld-text-large {
    font-size: 16px;
  }

  .liftworld-proposal-tables-wrapper {
    padding-top: 6px;
  }

  .liftworld-proposal-wrapper,
  .liftworld-proposal-resume-wrapper {
    width: 97%;
  }

  .liftworld-table-textbox-value-wrapper {
    font-size: 12px;
  }

  .liftworld-table-textbox-value-wrapper.number {
    font-size: 12px;
    width: -webkit-fill-available;
  }
  .liftworld-table-textbox-value-wrapper.bold {
    font-size: 10px;
    width: -webkit-fill-available;
  }

  .liftworld-table-textbox-value-wrapper.end {
    font-size: 12px;
    width: -webkit-fill-available;
  }

  .liftworld-proposal-main-tables-wrapper {
    width: 97%;
  }
}

.liftworld-proposal-hours-wrapper {
  width: 100%;
}

.liftworld-table-title-wrapper {
  width: 100%;
  justify-content: center;
  display: flex;
  color: $liftworld-color-font-primary;
  align-items: center;
  border-radius: 23px;
  font-weight: bold;
  height: 30px;
}


.liftowrld-simulator-table-wrapper {
  padding-top: 30px;
}

.liftworld-propsoal-input-wrapper {
  padding-left: 30px;
  padding-bottom: 15px;
  display: flex;
}

.liftworld-propsoal-input-wrapper-simulator {
  padding-left: 8px;
  padding-bottom: 15px;
  display: flex;
}

.liftworld-proposal-wrapper-padding {
  padding-top: 15px;
  padding-bottom: 15px;
}

.liftworld-proposal-main-tables-wrapper {
  width: 100%;
}

.liftworld-normal-text-wrapper {
  align-items: center;
  min-width: fit-content;
  display: flex;
  height: 30px;
  justify-content: flex-start;
  padding-right: 10px;
  @include weight("bold");
  font-size: 16px;
  width: fit-content
}